<template>
    <single-page :requires-auth="false" :fluid="true">
        <b-container fluid class="px-2">
            <b-row no-gutters class="d-flex">
                <b-col xs="auto" sm="auto" md="7" lg="6" xl="5" class="py-2">
                    <DashboardPanel title="Current Market Prices" :footer="CurrentMarketPricesFooter">
                        <template #toolbar>
                            <DxButton icon="refresh" class="float-right" @click="RefreshDTNSnapshots"/>
                        </template>
                        <template #content>
                            <DTNSnapshotsGrid ref="DTNSnapshotsGridRef"/>
                        </template>
                    </DashboardPanel>
                </b-col>
            </b-row>
        </b-container>
    </single-page>
</template>

<script>
import DTNSnapshotsGrid from "@/components/DTNSnapshotsGrid";
import SinglePage from "@/components/SinglePage";
import DashboardPanel from "@/components/DashboardPanel";
import DxButton from 'devextreme-vue/button'
import moment from "moment/moment";

export default {
    name: "outages-view",

    components: {
        DTNSnapshotsGrid,
        SinglePage,
        DashboardPanel,
        DxButton
    },

    data() {
        return {
            lastRefresh: moment(),
        }
    },

    mounted() {
        this.RefreshDTNSnapshots()
    },

    methods: {
        RefreshDTNSnapshots(e) {
            this.lastRefresh = moment()
            this.DTNSnapshotsGrid.FetchAll()
        },
    },

    computed: {
        DTNSnapshotsGrid() {
            return this.$refs.DTNSnapshotsGridRef
        },

        CurrentMarketPricesFooter() {
            return `Last Refreshed: ${ this.lastRefresh.format('MM/DD/YYYY hh:mm A') }`
        },
    }
}
</script>

<style scoped>

</style>
