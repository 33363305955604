export default [
	{
		text: 'HOME',
		path: '/',
		icon: 'home',
		expanded: false,
		visible: true,
		disabled: false,
		requiredRoles: ['Account Manager', 'Administrator', 'Credit Manager', 'Customer Buyer', 'Customer Driver', 'Prospect', 'Customer Administrator'],
		requiredPermissions: []
	},
	{
		text: 'ADMIN',
		icon: 'user',
		expanded: true,
		visible: true,
		disabled: false,
		requiredRoles: ['Administrator', 'Customer Administrator'],
		requiredPermissions: [],
		
		items: [
			{
				text: 'Server Utilities',
				path: '/admin/server/utilities',
				visible: true,
				disabled: false,
				requiredRoles: ['Administrator'],
				requiredPermissions: []
			},
			{
				text: 'User Management',
				path: '/admin/users/management',
				visible: true,
				disabled: false,
				requiredRoles: ['Administrator'],
				requiredPermissions: []
			},
			{
				text: 'Customer User Management',
				path: "/admin/users/customer/management",
				visible: true,
				disabled: false,
				requiredRoles: ['Administrator', 'Customer Administrator'],
				requiredPermissions: []
			},
			{
				text: 'Mobile Dashboard',
				path: "/admin/mobile/dashboard",
				visible: true,
				disabled: false,
				requiredRoles: ['Administrator'],
				requiredPermissions: []
			},
			{
				text: 'Product Management',
				path: "/admin/products/management",
				visible: true,
				disabled: false,
				requiredRoles: ['Administrator'],
				requiredPermissions: []
			},
			{
				text: 'User Activity',
				path: '/admin/users/activity',
				visible: true,
				disabled: false,
				requiredRoles: ['Administrator'],
				requiredPermissions: []
			},
			{
				text: 'Markets/Products Management',
				path: '/admin/markets-products/management',
				visible: true,
				disabled: false,
				requiredRoles: ['Administrator'],
				requiredPermissions: []
			},
			{
				text: 'User Profile Templates',
				path: '/admin/users/profile/templates',
				visible: true,
				disabled: false,
				requiredRoles: ['Administrator'],
				requiredPermissions: []
			}
		]
	},
	{
		text: 'SERVER',
		icon: 'fas fa-server',
		expanded: true,
		visible: true,
		disabled: false,
		requiredRoles: ['Account Manager', 'Administrator'],
		requiredPermissions: [],
		
		items: [
			{
				text: 'Current Markets',
				path: '/admin/server/markets',
				visible: true,
				disabled: false,
				requiredRoles: ['Account Manager', 'Administrator'],
				requiredPermissions: []
			},
			{
				text: 'Current Outages',
				path: '/admin/server/outages',
				visible: true,
				disabled: false,
				requiredRoles: ['Account Manager', 'Administrator'],
				requiredPermissions: []
			}
		]
	},
	{
		text: 'COMMUNICATIONS',
		icon: 'email',
		expanded: true,
		visible: true,
		disabled: false,
		requiredRoles: ['Account Manager', 'Administrator', 'Credit Manager', 'Customer Buyer', 'Customer Driver', 'Customer Administrator'],
		requiredPermissions: [],
		
		items: [
			{
				text: 'Uploaded Documents',
				path: '/documents/uploaded',
				visible: true,
				disabled: false,
				requiredRoles: ['Account Manager', 'Administrator', 'Credit Manager', 'Customer Buyer', 'Customer Driver', 'Customer Administrator'],
				requiredPermissions: []
			},
			{
				text: 'Messages',
				path: '/messages',
				visible: false,
				disabled: true,
				requiredRoles: ['Account Manager', 'Administrator', 'Credit Manager', 'Customer Buyer', 'Customer Driver', 'Customer Administrator'],
				requiredPermissions: []
			}
		]
	},
	{
		text: 'PRICING',
		icon: 'money',
		expanded: true,
		visible: true,
		disabled: false,
		requiredRoles: ['Account Manager', 'Administrator', 'Customer Buyer', 'Customer Driver', 'Prospect', 'Customer Administrator'],
		requiredPermissions: [],
		
		items: [
			{
				text: 'Current Pricing',
				path: '/customer/pricing/current',
				visible: true,
				disabled: false,
				requiredRoles: ['Account Manager', 'Administrator', 'Customer Buyer', 'Prospect', 'Customer Administrator'],
				requiredPermissions: [],
			},
			{
				text: 'Live Loading',
				path: '/customer/pricing/liveloading',
				visible: true,
				disabled: false,
				requiredRoles: ['Account Manager', 'Administrator', 'Customer Buyer', 'Customer Driver', 'Prospect', 'Customer Administrator'],
				requiredPermissions: [],
			},
			{
				text: 'Pricing History',
				path: '/customer/pricing/history',
				visible: true,
				disabled: false,
				requiredRoles: ['Account Manager', 'Administrator', 'Prospect', 'Customer Administrator', 'Customer Buyer'],
				requiredPermissions: [],
			}
		]
	},
	{
		text: 'CREDIT',
		icon: 'fas fa-credit-card',
		expanded: true,
		visible: true,
		disabled: false,
		requiredRoles: ['Account Manager', 'Administrator'],
		requiredPermissions: [],
		
		items: [
			{
				text: 'Credit Profile',
				path: '/creditprofile',
				visible: true,
				disabled: false,
				requiredRoles: ['Account Manager', 'Administrator'],
				requiredPermissions: []
			}
		]
	},
];
