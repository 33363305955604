/* eslint-disable */
import store from "@/stores/store";
import {API} from "@/API";

const customers = {
	namespaced: true,
	
	state: {
		customers: JSON.parse(localStorage.getItem('customers')) || [],
		customer: JSON.parse(localStorage.getItem('customer')) || null,
	},
	
	mutations: {
		store_customers(state, payload) {
			state.customers = payload.customers
			state.customer = payload.customer
		},
		
		change_customer(state, payload) {
			state.customer = payload
		},
		
		clear(state) {
			state.customers = []
			state.customer = null
		}
	},
	
	actions: {
		GET_CUSTOMERS({commit}) {
			store.dispatch('loading/message', 'Retrieving Customers...')
			
			return new Promise((resolve, reject) => {
				API.get(`/customers`)
					.then(response => {
						const customers = response.data
						const customer = JSON.parse(localStorage.getItem('customer'))
						
						localStorage.setItem('customers', JSON.stringify(customers))
						localStorage.setItem('customer', !customer ? (customers.length === 0 ? null : JSON.stringify(customers[0])) : JSON.stringify(customer))
						
						commit('store_customers', { customers: customers, customer: customer });
						
						resolve(response.data)
					})
			})
		},
		
		CHANGE_CUSTOMER({commit}, customer) {
			localStorage.setItem('customer', JSON.stringify(customer))
			
			commit('change_customer', customer)
		},
		
		CLEAR_CUSTOMERS({commit}) {
			localStorage.removeItem('customers')
			commit('clear')
		}
	},
	
	getters: {
		customers: state => state.customers,
		customer: state => state.customer ? state.customer : state.customers ? state.customers[0] : []
	}
}

export default customers
