/* eslint-disable */
import store from "@/stores/store";
import {API} from "@/API";

const bidtemplates = {
	namespaced: true,
	
	state: {
		bidtemplates: JSON.parse(localStorage.getItem('bidtemplates')) || [],
		bidtemplate: JSON.parse(localStorage.getItem('bidtemplate')) || null,
	},
	
	mutations: {
		store_bidtemplates(state, payload) {
			state.bidtemplates = payload.bidtemplates
			state.bidtemplate = payload.bidtemplate
		},
		
		change_bidtemplate(state, payload) {
			state.bidtemplate = payload
		},
		
		clear_bidtemplate(state) {
			state.bidtemplate = null
		},
		
		clear_bidtemplates(state) {
			state.bidtemplates = []
			state.bidtemplate = null
		}
	},
	
	actions: {
		GET_BIDTEMPLATES({commit}, customer) {
			store.dispatch('loading/message', 'Retrieving Bid Templates...')
			
			return new Promise((resolve, reject) => {
				API.get(`/bidtemplates/${customer.CustomerNo}`)
					.then(response => {
						commit('clear_bidtemplates')
						
						const bidtemplates = response.data
						//const bidtemplate = bidtemplates.length === 0 ? null : bidtemplates[0]
						const localbidtemplate = JSON.parse(localStorage.getItem('bidtemplate'))
						const bidtemplate = localbidtemplate ? localbidtemplate : (bidtemplates.length === 0 ? null : bidtemplates[0])
						
						localStorage.setItem('bidtemplates', JSON.stringify(bidtemplates))
						localStorage.setItem('bidtemplate', JSON.stringify(bidtemplate))
						
						commit('store_bidtemplates', { bidtemplates: bidtemplates, bidtemplate: bidtemplate });
						
						resolve(response.data)
					})
			})
		},
		
		CHANGE_BIDTEMPLATE({commit}, bidtemplate) {
			if(bidtemplate)
				localStorage.setItem('bidtemplate', JSON.stringify(bidtemplate))
			else
				localStorage.setItem('bidtemplate', null)
			
			commit('change_bidtemplate', bidtemplate)
		},
		
		CLEAR_BIDTEMPLATE({commit}) {
			localStorage.removeItem('bidtemplate')
			commit('clear_bidtemplate')
		},
		
		CLEAR_BIDTEMPLATES({commit}) {
			localStorage.removeItem('bidtemplates')
			commit('clear_bidtemplates')
		}
	},
	
	getters: {
		bidtemplates: state => state.bidtemplates,
		bidtemplate: state => state.bidtemplate //? state.bidtemplate : state.bidtemplates ? state.bidtemplates[0] : []
	}
}

export default bidtemplates
