<template>
    <single-page :requires-auth="true">
        <b-container fluid>
            <b-row no-gutters class="p-2 d-flex">
                <b-col xs="auto" sm="auto" md="auto" lg="8" xl="5" class="px-0 w-100">
                    <b-card-group deck>
                        <b-card no-body bg-variant="light" :header="`Customer Credit: ${this.Customer.CustomerName}`" class="shadow-sm font-weight-bold" style="max-width: 625px">
                            <b-card-text v-if="creditprofile">
                                <DxForm
                                    id="form"
                                    :form-data="creditprofile"
                                    :col-count="2"
                                    :col-count-by-screen="{ lg: 2, md: 2, sm: 2, xs: 1 }"
                                    :screen-by-width="getSizeQualifier"
                                    label-location="left"
                                    class="p-2"
                                >
                                    <DxGroupItem
                                        :col-count="2"
                                        :col-span="2"
                                    >
                                        <DxSimpleItem
                                            data-field="lastCreditLimit"
                                            :label="{ text: 'Credit Limit' }"
                                            :editor-options="currencyEditorOptions"
                                            :col-span="1"
                                        />
                                        <DxEmptyItem/>
                                    </DxGroupItem>

                                    <DxGroupItem
                                        caption="A/R Amounts"
                                    >
                                        <DxSimpleItem
                                            data-field="inTermsInvAmount"
                                            :label="{ text: 'In Terms'}"
                                            :editor-options="currencyEditorOptions"
                                        />
                                        <DxSimpleItem
                                            data-field="inGraceInvAmount"
                                            :label="{ text: 'In Grace Period'}"
                                            :editor-options="currencyEditorOptions"
                                        />
                                        <DxSimpleItem
                                            data-field="overTermsInvAmount"
                                            :label="{ text: 'Over Terms + Grace'}"
                                            :editor-options="currencyEditorOptions"
                                        />
                                        <DxSimpleItem>
                                            <DxLabel text=" " :show-colon="false" />
                                            <template #default>
                                                <div class="total-line float-right" style="width: 150px">

                                                </div>
                                            </template>
                                        </DxSimpleItem>
                                        <DxSimpleItem
                                            data-field="totalARAmount"
                                            :label="{ text: 'Total A/R Amount'}"
                                            :editor-options="totalsEditorOptions"
                                        />
                                    </DxGroupItem>

                                    <DxGroupItem
                                        caption="Terms"
                                    >
                                        <DxSimpleItem
                                            data-field="termsDescription"
                                            :label="{ visible: false }"
                                            :editor-options="{ readOnly: true }"
                                        />
                                        <DxSimpleItem
                                            data-field="termDays"
                                            :label="{ text: 'Term Days'}"
                                            :editor-options="numberEditorOptions"
                                        />
                                        <DxSimpleItem
                                            data-field="graceDays"
                                            :label="{ text: 'Grace Days'}"
                                            :editor-options="numberEditorOptions"
                                        />
                                        <DxSimpleItem
                                            data-field="avgDaysToPay"
                                            :label="{ text: 'Average Days To Pay'}"
                                            :editor-options="numberEditorOptions"
                                        />
                                    </DxGroupItem>

                                    <DxGroupItem
                                        caption="Pending Billing Amounts"
                                    >
                                        <DxSimpleItem
                                            data-field="totalInvoicePendingAmount"
                                            :label="{ text: 'Invoices'}"
                                            :editor-options="currencyEditorOptions"
                                        />
                                        <DxSimpleItem
                                            data-field="totalFDSOPendingAmount"
                                            :label="{ text: 'Sales Orders'}"
                                            :editor-options="currencyEditorOptions"
                                        />
                                        <DxSimpleItem
                                            data-field="totalMMPendingAmount"
                                            :label="{ text: 'MM BOL\'s'}"
                                            :editor-options="currencyEditorOptions"
                                        />
                                        <DxSimpleItem
                                            data-field="bolNotUploadAAmount"
                                            :label="{ text: 'BOL\'s Waiting'}"
                                            :editor-options="currencyEditorOptions"
                                        />
                                        <DxSimpleItem>
                                            <DxLabel text=" " :show-colon="false" />
                                            <template #default>
                                                <div class="total-line float-right" style="width: 150px">

                                                </div>
                                            </template>
                                        </DxSimpleItem>
                                        <DxSimpleItem
                                            data-field="totalPendingAmount"
                                            :label="{ text: 'Total Pending Amount'}"
                                            :editor-options="totalsEditorOptions"
                                        />
                                    </DxGroupItem>

                                    <DxGroupItem
                                        caption="Loads/Releases"
                                    >
                                        <DxSimpleItem
                                            data-field="calculatedAvailableLoads"
                                            :label="{ text: 'Calculated Available Loads'}"
                                            :editor-options="numberEditorOptions"
                                        />
                                        <DxSimpleItem
                                            data-field="additionalApprovedLoads"
                                            :label="{ text: 'Additional Approved Loads (+)'}"
                                            :editor-options="numberEditorOptions"
                                        />
                                        <DxSimpleItem
                                            data-field="releasesIssued"
                                            :label="{ text: 'Issued Releases #\'s'}"
                                            :editor-options="numberEditorOptions"
                                        />
                                        <DxSimpleItem>
                                            <DxLabel text=" " :show-colon="false" />
                                            <template #default>
                                                <div class="total-line float-right" style="width: 75px">

                                                </div>
                                            </template>
                                        </DxSimpleItem>
                                        <DxSimpleItem
                                            data-field="totalAvailableLoads"
                                            :label="{ text: 'Total Available Loads'}"
                                            :editor-options="numberTotalEditorOptions"
                                        />
                                    </DxGroupItem>

                                    <DxGroupItem
                                        caption="Totals"
                                    >
                                        <DxSimpleItem
                                            data-field="subTotalAvailableAmount"
                                            :label="{ text: 'Subtotal Available Amount'}"
                                            :editor-options="currencyEditorOptions"
                                        />
                                        <DxSimpleItem
                                            data-field="releasesIssuedAmount"
                                            :label="{ text: 'Release Issued Amount'}"
                                            :editor-options="currencyEditorOptions"
                                        />
                                        <DxSimpleItem
                                            data-field="totalCreditUsedAmount"
                                            :label="{ text: 'Total Credit Used Amount'}"
                                            :editor-options="currencyEditorOptions"
                                        />
                                        <DxSimpleItem>
                                            <DxLabel text=" " :show-colon="false" />
                                            <template #default>
                                                <div class="total-line float-right" style="width: 150px">

                                                </div>
                                            </template>
                                        </DxSimpleItem>
                                        <DxSimpleItem
                                            data-field="availableCreditAmount"
                                            :label="{ text: 'Available Credit Amount'}"
                                            :editor-options="totalsEditorOptions"
                                        />
                                    </DxGroupItem>
                                    <DxGroupItem>
                                        <DxButtonItem>
                                            <DxButtonOptions
                                                    text="Request Additional Load(s)"
                                                    type="success"
                                                    class="align-bottom"
                                                    :on-click="(e) => { this.showRequestLoadsPopup = true }"
                                            />
                                        </DxButtonItem>
                                    </DxGroupItem>
                                </DxForm>
                            </b-card-text>
                            <b-card-text v-else class="text-center p-5">
                                <span class="h5 font-weight-bold">No Credit Profile</span>
                            </b-card-text>
                        </b-card>
                    </b-card-group>
                </b-col>
            </b-row>
        </b-container>

        <DxPopup
            :visible="showRequestLoadsPopup"
            :drag-enabled="false"
            :close-on-outside-click="false"
            :show-close-button="false"
            :show-title="true"
            :min-width="350"
            :max-width="350"
            :animation="{
                    show: { type: 'pop', duration: 0 },
                    hide: { type: 'pop', duration: 0 }
            }"
            shading-color="rgba(0,0,0,0.4)"
            height="auto"
            container=".dx-viewport"
            title="Request Additional Loads"
        >
            <DxToolbarItem widget="dxButton" toolbar="bottom" location="after" :options="{ type: 'success', text: 'Accept', onClick: RequestAdditionalLoads }"/>
            <DxToolbarItem widget="dxButton" toolbar="bottom" location="after" :options="{ type: 'default', text: 'Cancel', onClick: (e) => { this.showRequestLoadsPopup = false } }"/>

            <template #content>
                <DxForm
                        :form-data="additionalLoads"
                        :col-count="1"
                        :col-count-by-screen="{ lg: 1, md: 1, sm: 1, xs: 1 }"
                        :screen-by-width="getSizeQualifier"
                        label-location="left"
                        class="p-0"
                >
                    <DxSimpleItem
                            data-field="loads"
                            :col-span="1"
                    >
                        <DxLabel text="Loads" />
                        <template #default>
                            <DxNumberBox
                                    :min="1"
                                    :max="10"
                                    :step="1"
                                    :input-attr="{ class: 'text-center' }"
                                    :value.sync="additionalLoads.loads"
                                    :width="100"
                            >
                                <DxNumberBoxButton
                                        :options="minusButton"
                                        name="prevValue"
                                        location="before"
                                />
                                <DxNumberBoxButton
                                        :options="plusButton"
                                        name="nextValue"
                                        location="after"
                                />
                            </DxNumberBox>
                        </template>
                    </DxSimpleItem>
                    <DxSimpleItem
                            data-field="comment"
                            editor-type="dxTextArea"
                            :value.sync="additionalLoads.comment"
                            :editor-options="{ height: 100 }"
                            :label="{ text: 'Comment' }"
                    />
                </DxForm>
            </template>
        </DxPopup>
    </single-page>
</template>

<script>
import {API} from "@/API";

import SinglePage from "@/components/SinglePage";
import { DxNumberBox, DxButton as DxNumberBoxButton } from 'devextreme-vue/number-box';
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import { DxForm, DxSimpleItem, DxGroupItem, DxEmptyItem , DxLabel, DxButtonItem, DxButtonOptions } from 'devextreme-vue/form';
import 'devextreme-vue/text-area';

export default {
    name: "credit-profile-view",

    components: {
        SinglePage,
        DxForm,
        DxSimpleItem,
        DxGroupItem,
        DxEmptyItem,
        DxLabel,
        DxButtonItem,
        DxButtonOptions,
        DxPopup,
        DxToolbarItem,
        DxNumberBox,
        DxNumberBoxButton
    },

    data() {
        return {
            creditprofile: null,

            minusButton: {
                icon: 'fas fa-chevron-left',
                stylingMode: 'text',

                onClick: (e) => {
                    if(this.additionalLoads.loads > 1)
                        this.additionalLoads.loads -= 1
                },
            },

            plusButton: {
                icon: 'fas fa-chevron-right',
                stylingMode: 'text',

                onClick: (e) => {
                    if(this.additionalLoads.loads < 10)
                        this.additionalLoads.loads += 1
                },
            },

            currencyEditorOptions: {
                readOnly: true,
                format: '$ #,##0.00',
                width: 150,
                elementAttr: {
                    class: 'float-right'
                },
                inputAttr: {
                    class: 'text-right'
                }
            },

            totalsEditorOptions: {
                readOnly: true,
                format: '$ #,##0.00',
                width: 150,
                elementAttr: {
                    class: 'float-right'
                },
                inputAttr: {
                    class: 'text-right shade'
                }
            },

            numberEditorOptions: {
                readOnly: true,
                width: 75,
                elementAttr: {
                    class: 'float-right'
                },
                inputAttr: {
                    class: 'text-right'
                }
            },

            numberTotalEditorOptions: {
                readOnly: true,
                width: 75,
                elementAttr: {
                    class: 'float-right'
                },
                inputAttr: {
                    class: 'text-right shade'
                }
            },

            requestButtonOptions: {

            },

            showRequestLoadsPopup: false,

            additionalLoads: {
                userEmail: "",
                loads: 1,
                comment: ""
            }
        }
    },

    beforeRouteEnter(to, from, next) {
        next(vm => {
            let roles = to.meta.requiredRoles.join("|")
            let permissions = to.meta.requiredPermissions.join("|")

            if (vm.$gates.hasAnyRole(roles) || vm.$gates.hasAnyPermission(permissions))
                next()
            else
                next('/home')
        })
    },

    beforeDestroy() {
        this.$root.$off('customer-changed')
    },

    async mounted() {
        await this.Fetch()

        this.$root.$on('customer-changed', () => {
            this.Fetch()
        })
    },

    methods: {
        Fetch() {
            if (this.Authenticated) {
                this.$store.dispatch('loading/message', "Retrieving Credit Profile...")

                API.get(`/creditprofiles/${this.Customer.CustomerNo}`)
                        .then(response => {
                            this.creditprofile = response.data
                        })
                        .catch(error => {
                            this.$toast.open({
                                message: error.response.data.message,
                                type: 'error',
                            });
                        })
            } else {
                this.$router.push('/login')
            }
        },

        RequestAdditionalLoads(e) {
            if (this.Authenticated) {
                this.$store.dispatch('loading/message', "Requesting Additional Loads...")

                this.additionalLoads.userEmail = this.User.Email

                API.post(`/requests/credit/additional/${this.Customer.CustomerNo}`, this.additionalLoads)
                    .then(response => {
                        this.$toast.open({
                            message: 'Load Request Sent...',
                        });
                    })
                    .catch(error => {
                        this.$toast.open({
                            message: error.response.data.message,
                            type: 'error',
                        });
                    })
                    .finally(() => {
                        this.showRequestLoadsPopup = false
                    })
            } else {
                this.$router.push('/login')
            }
        },

        getSizeQualifier(width) {
            if (width < 768)  return "xs";
            if (width < 992)  return "sm";
            if (width < 1200) return "md";
            return "lg";
        }
    },

    computed: {
        User() {
            return this.$store.getters['user/user']
        },

        Authenticated() {
            return this.$store.getters["user/authenticated"]
        },

        Customer() {
            return this.$store.getters['customers/customer']
        }
    }
}
</script>

<style scoped>
    ::v-deep .dx-texteditor.dx-state-readonly {
        border-style: solid !important;
        border-color: rgba(0, 0, 0, 0.500);
    }

    ::v-deep .total-line {
        border-top: 1px solid black;
        border-radius: 0;
        background-color: transparent;
    }

    ::v-deep .dx-layout-manager .dx-field-item:not(.dx-last-col) {
        padding-right: 0 !important;
    }

    ::v-deep .shade {
        background-color: rgb(218, 237, 250) !important;
    }

    ::v-deep .dx-layout-manager .dx-label-h-align.dx-flex-layout:not(.dx-field-item-label-align) {
        align-items: center;
    }

    ::v-deep label {
        display: inline-block;
        margin-bottom: 0;
    }
</style>
