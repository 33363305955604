<template>
    <single-page :requires-auth="true">
        <b-container fluid class="p-2">
            <b-row class="d-flex">
                <b-col sm="12" md="8" lg="12" xl="12">
                    <b-card-group deck>
                        <b-card bg-variant="light" header="User Market Alert History" class="shadow-sm font-weight-bold" style="font-size: 18px;">
                            <b-card-text>
                                <UserMarketAlertHistoryGrid/>
                            </b-card-text>
                        </b-card>
                    </b-card-group>
                </b-col>
            </b-row>
        </b-container>
    </single-page>
</template>

<script>
import UserMarketAlertHistoryGrid from "@/components/UserMarketAlertHistoryGrid";
import SinglePage from "@/components/SinglePage";

export default {
    name: "user-market-alert-history",

    components: {
        SinglePage,
        UserMarketAlertHistoryGrid
    }
}
</script>

<style scoped>

</style>
