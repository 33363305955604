<template>
    <div class="row">
        <div class='box' :style="{ 'background-color': data.Hex }"/> {{ data.Name }}
    </div>
</template>

<script>
export default {
    name: "product-color-cell",

    props: {
        data: Object,
    }
}
</script>

<style scoped>
.row {
    display : flex;
    align-items : center;
    margin: 2px;
}
.box {
    height: 20px;
    width: 20px;
    border: 1px solid black;
    margin-right : 5px;
}
</style>
