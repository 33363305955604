/* eslint-disable */
import store from "@/stores/store";

const impersonate = {
	namespaced: true,

	state: {
		user: JSON.parse(localStorage.getItem('impersonate.user')) || null,
	},

	mutations: {
		impersonate(state, payload) {
			state.user = payload
		},
		
		unpersonate(state) {
			state.user = null
			
			localStorage.removeItem('impersonate.user')
		}
	},

	actions: {
		impersonate({commit}, user) {
			store.dispatch('loading/message', 'Impersonating User...')
			
			return new Promise((resolve, reject) => {
				localStorage.setItem('impersonate.user', JSON.stringify(user))
				
				commit('impersonate', user)
				
				resolve(user)
			})
		},
		
		unpersonate({commit}) {
			return new Promise((resolve, reject) => {
				store.dispatch('loading/message', 'Un-Impersonating User...')
				
				commit('unpersonate')
				
				resolve()
			})
		},
	},

	getters: {
		impersonating: state => !!state.user,
		user: state => state.user,
	}
};

export default impersonate;
