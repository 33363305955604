<template>
    <div :v-if="requiresAuth && Authenticated">
        <h3 class="font-weight-bold my-0">{{ name }}</h3>
        <b-container fluid>
            <b-row cols-sm="auto" cols-md="auto" cols-lg="auto" cols-xl="auto">
                <b-col class="p-0">
                    <div>
                        <slot class="flex-shrink-0"/>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
export default {
    name: "single-page",
    props: {
        requiresAuth: {type: Boolean, default: () => false},
        name: {type: String, default: () => ""},
    },

    computed: {
        Authenticated() {
            return this.$store.getters['user/authenticated']
        }
    }
}
</script>

<style scoped>

</style>
