<template>
    <div class="vh-75 w-100">
        <slot/>
    </div>
</template>

<script>

export default {
    components: {
    }
};
</script>

<style lang="scss">

</style>
