<template>
    <single-page :requires-auth="true">
        <b-container fluid>
            <b-row class="p-2 d-flex">
                <b-col xs="auto" sm="auto" md="4" lg="4" xl="4" class="px-0">
                    <b-card-group deck>
                        <b-card bg-variant="light" header="Markets/Products Management" class="text-center shadow-sm font-weight-bold">
                            <b-card-text>
                                <div class="px-2 py-2">
                                    <DxDataGrid
                                            ref="MarketsProductsGridRef"
                                            no-data-text="No Market/Product Records..."
                                            :data-source.sync="markets_products"
                                            :show-borders="true"
                                            :show-row-lines="true"
                                            :column-auto-width="true"
                                            :two-way-binding-enabled="false"
                                            :column-hiding-enabled="false"
                                            :focused-row-enabled="true"
                                            key-expr="ID"
                                            width="auto"
                                            :height="650"
                                            class="p-0"
                                            @focused-row-changed="onFocusedRowChanged"
                                    >
                                        <DxHeaderFilter :visible="true"/>
                                        <DxSorting show-sort-indexes />
                                        <DxScrolling mode="infinite"/>
                                        <DxGroupPanel :visible="true"/>
                                        <DxGrouping :auto-expand-all="false"/>

                                        <DxToolbar>
                                            <DxItem location="before" template="toggleTemplate"/>
                                            <DxItem location="center" template="filter-combinations"/>
                                            <DxItem location="after" template="clearFiltersTemplate"/>
                                            <DxItem location="after" template="refreshTemplate"/>
                                        </DxToolbar>

                                        <DxColumn
                                                cell-template="active-cell-template"
                                                :header-filter="{height: 'auto', allowSearch: true, position: { my: 'center', at: 'center' } }"
                                                :allow-header-filtering="false"
                                                :allow-sorting="false"
                                        />

                                        <DxColumn
                                                data-field="MarketID"
                                                caption="Market ID"
                                                :allow-header-filtering="false"
                                                :allow-sorting="false"
                                        />
                                        <DxColumn
                                                data-field="Market.Name"
                                                caption="Market"
                                                :sort-index="0"
                                                sort-order="asc"
                                        />
                                        <DxColumn
                                                data-field="ProductID"
                                                caption="Product ID"
                                                :allow-header-filtering="false"
                                                :allow-sorting="false"
                                        />
                                        <DxColumn
                                                data-field="Product.Name"
                                                caption="Ticker"
                                                :sort-index="1"
                                                sort-order="asc"
                                        />

                                        <template #active-cell-template="{ data }">
                                            <div class="text-center mx-1">
                                                <status-indicator :status="data.data.Active ? 'positive': 'negative'" />
                                            </div>
                                        </template>

                                        <template #toggleTemplate>
                                            <DxButton icon="redo" hint="Enable/Disable Selected Configuration" :disabled="!selected || !markets_products.length" @click="Toggle"/>
                                        </template>

                                        <template #filter-combinations>
                                            <DxRadioGroup
                                                :items="combinations"
                                                :value="selectedFilter"
                                                value-expr="id"
                                                display-expr="text"
                                                layout="horizontal"
                                                @valueChanged="filterCombinations"
                                            />
                                        </template>
                                        <template #clearFiltersTemplate>
                                            <DxButton icon="fas fa-eraser" hint="Clear All Filters" @click="ClearFilters"/>
                                        </template>
                                        <template #refreshTemplate>
                                            <DxButton icon="refresh" hint="Refresh Market Product Configurations" @click="Refresh"/>
                                        </template>
                                    </DxDataGrid>
                                </div>
                            </b-card-text>
                        </b-card>
                    </b-card-group>
                </b-col>
            </b-row>
        </b-container>
    </single-page>
</template>

<script>
import SinglePage from "@/components/SinglePage";
import {
    DxDataGrid,
    DxColumn,
    DxHeaderFilter,
    DxSorting,
    DxGrouping,
    DxGroupPanel,
    DxScrolling,
    DxToolbar,
    DxItem,
} from 'devextreme-vue/data-grid';

//import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import { DxButton } from 'devextreme-vue/button';
import DxRadioGroup from 'devextreme-vue/radio-group';

import {confirm} from "devextreme/ui/dialog";

import {API} from "@/API";

import { StatusIndicator } from 'vue-status-indicator';

const combinations = [
    { id: -1, text: 'All' },
    { id: 1, text: 'Active' },
    { id: 0, text: 'Inactive' }
]

export default {
    name: "market-products-admin-view",

    components: {
        SinglePage,
        DxDataGrid,
        DxColumn,
        DxHeaderFilter,
        DxSorting,
        DxGrouping,
        DxGroupPanel,
        DxScrolling,
        DxToolbar,
        DxItem,
        DxButton,
        StatusIndicator,
        DxRadioGroup,
    },

    data() {
        return {
            markets_products: [],
            selected: {},
            combinations,
            selectedFilter: -1
        }
    },

    async mounted() {
        await this.Fetch()
    },

    methods: {
        async Fetch() {
            if (this.Authenticated) {
                API.get('/admin/markets-products')
                        .then(response => {
                            this.markets_products = response.data
                        })
                        .catch(error => {
                            this.$toast.open({
                                message: error.response.data.message,
                                type: 'error',
                            });
                        })
            } else {
                this.$router.push('/login')
            }
        },

        async Refresh(e) {
            await this.Fetch()
        },

        ClearFilters(e) {
            this.selectedFilter = -1
            this.MarketsProductsGrid.clearFilter()
            this.MarketsProductsGrid.columnOption("Market.Name", "filterValues", []);
            this.MarketsProductsGrid.columnOption("Product.Name", "filterValues", []);
        },

        Toggle(e) {
            let result = confirm("Are you sure?", `${ this.selected.Active ? 'Disable' : 'Enable' } Combination`);

            result.then((dialogResult) => {
                if (dialogResult) {
                    this.$store.dispatch('loading/message', "Toggling Market/Product Combination...")

                    API.put(`/admin/markets-products/${this.selected.ID}`)
                            .then(response => {
                                this.markets_products = response.data.data
                            })
                            .catch(error => {
                                this.$toast.open({
                                    message: error.response.data.message,
                                    type: 'error',
                                });
                            })
                }
            })
        },

        onFocusedRowChanged(e) {
            if(e.row.data) {
                this.selected = e.row.data
            }
        },

        filterCombinations(e) {
            this.selectedFilter = e.value

            switch (this.selectedFilter) {
                case -1:
                    this.MarketsProductsGrid.clearFilter();
                    break;
                case 1:
                    this.MarketsProductsGrid.filter(['Active', '=', true]);
                    break
                case 0:
                    this.MarketsProductsGrid.filter(['Active', '=', false]);
                    break;
            }
        }
    },

    computed: {
        User() {
            return this.$store.getters['user/user']
        },

        Authenticated() {
            return this.$store.getters["user/authenticated"];
        },

        MarketsProductsGrid() {
            return this.$refs.MarketsProductsGridRef.instance
        }
    }
}
</script>

<style scoped>
    ::v-deep .dx-toolbar {
        background-color: #f8f9fa !important;
    }

    ::v-deep .dx-datagrid-header-panel {
        background-color: #f8f9fa !important;
    }
</style>
