<template>
    <b-container fluid class="pt-2">
        <b-row>
            <b-col offset-lg="3">
                <b-row>
                    <b-col class="pl-2">
                        <DxButton
                                text="Back"
                                icon="chevronleft"
                                :height="35"
                                :width="85"
                                @click="() => { this.$router.push('home') }"
                        />
                    </b-col>
                </b-row>

                <b-row class="mt-2">
                    <b-col sm="12" md="10" lg="6" xl="4" class="border rounded m-2">
                        <b-row>
                            <b-col class="py-2">
                                <DxCheckBox
                                        text="Show All Markets"
                                        :value.sync="options.showAllMarkets"
                                />
                            </b-col>

                        </b-row>
                        <b-row>
                            <b-col>
                                <DxChart
                                    ref="TodayChartRef"
                                    :data-source="TodayProductSnapshots"
                                    :animation="{enabled: false}"
                                >
                                    <DxCommonSeriesSettings
                                            type="line"
                                            argument-field="UpdatedOn"
                                            value-field="Spot"
                                    />
                                    <DxSeriesTemplate
                                            :label="{format: '0#.0000'}"
                                            name-field="MarketName"
                                    />
                                    <DxArgumentAxis
                                            :value-margins-enabled="false"
                                            discrete-axis-division-mode="crossLabels"
                                            argument-type="datetime"
                                            aggregation-interval="hour"
                                            :label="{format: 'hh:mm a', displayMode: 'rotate', rotationAngle: -45}"
                                    >
                                        <DxTick :visible="true"/>
                                        <DxTickInterval :hours=".5"/>
                                        <DxMinorTick :visible="true"/>
                                        <DxGrid :visible="true"/>
                                        <DxMinorTickInterval :hours="1"/>
                                    </DxArgumentAxis>
                                    <DxValueAxis
                                            :auto-breaks-enabled="true"
                                            :max-auto-break-count="1"
                                            :label="{format: '0#.0000'}"
                                    />
                                    <DxLegend
                                            vertical-alignment="bottom"
                                            horizontal-alignment="center"
                                            item-text-position="bottom"
                                    />
                                    <DxTitle text="Today">
                                        <DxSubtitle :text="Snapshot && Snapshot.Name"/>
                                    </DxTitle>
                                    <DxTooltip :z-index="10000" :enabled="true" format="0#.0000"/>
                                </DxChart>
                            </b-col>
                        </b-row>
                    </b-col>

                    <b-col sm="12" md="10" lg="6" xl="4" class="border rounded m-2">
                        <b-row>
                            <b-col class="p-2 float-right">
                                <DxRadioGroup
                                        :data-source="daysFilterData"
                                        display-expr="Text"
                                        value-expr="ID"
                                        :value="options.historicalDaysToShow"
                                        layout="horizontal"
                                        @value-changed="onValueChanged"
                                        class="float-right"
                                />
                                <span class="float-right pr-2">Filter: </span>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <DxChart
                                        ref="HistoryChartRef"
                                        :data-source="HistoricalProductSnapshots"
                                        :animation="{enabled: false}"
                                >
                                    <DxCommonSeriesSettings
                                            type="line"
                                            argument-field="UpdatedOn"
                                            value-field="Spot"
                                    />
                                    <DxSeriesTemplate
                                            :label="{format: '0#.0000'}"
                                            name-field="MarketName"
                                    />
                                    <DxArgumentAxis
                                            :tick-interval="options.historicalDaysToShow"
                                            :value-margins-enabled="false"
                                            discrete-axis-division-mode="crossLabels"
                                            argument-type="datetime"
                                            aggregation-interval="day"
                                            :label="{format: 'MM/dd'}"
                                    >
                                        <DxTick :visible="true"/>
                                        <DxTickInterval :days="1"/>
                                        <DxMinorTick :visible="true"/>
                                        <DxGrid :visible="true"/>
                                        <DxMinorTickInterval :days="1"/>
                                    </DxArgumentAxis>
                                    <DxValueAxis
                                            :value-margins-enabled="false"
                                            :auto-breaks-enabled="true"
                                            :max-auto-break-count="1"
                                            :label="{format: '0#.0000'}"
                                    />
                                    <DxLegend
                                            vertical-alignment="bottom"
                                            horizontal-alignment="center"
                                            item-text-position="bottom"
                                    />
                                    <DxTitle text="History">
                                        <DxSubtitle :text="Snapshot && Snapshot.Name"/>
                                    </DxTitle>
                                    <DxTooltip :z-index="10000" :enabled="true" format="0#.0000"/>
                                </DxChart>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
/* eslint-disable */

import {
    DxChart,
    DxSeries,
    DxArgumentAxis,
    DxCommonSeriesSettings,
    DxSeriesTemplate,
    DxTickInterval,
    DxValueAxis,
    DxMinorTick,
    DxGrid,
    DxMargin,
    DxLegend,
    DxTitle,
    DxSubtitle,
    DxTooltip,
    DxSize,
    DxTick,
    DxMinorTickInterval
} from 'devextreme-vue/chart';

import {DxButton} from 'devextreme-vue/button'

import {DxCheckBox} from 'devextreme-vue/check-box';

import DxRadioGroup from 'devextreme-vue/radio-group';

import moment from "moment";
import {API} from "@/API";

const daysFilterData = [
    {
        ID: 5,
        Text: '5 Days'
    },
    {
        ID: 7,
        Text: '7 Days'
    },
    {
        ID: 10,
        Text: '10 Days'
    }
]

export default {
    name: "dtn-snapshot-chart",

    components: {
        DxChart,
        DxSeries,
        DxArgumentAxis,
        DxCommonSeriesSettings,
        DxSeriesTemplate,
        DxTickInterval,
        DxValueAxis,
        DxMinorTick,
        DxGrid,
        DxMargin,
        DxLegend,
        DxTitle,
        DxSubtitle,
        DxTooltip,
        DxSize,
        DxTick,
        DxMinorTickInterval,
        DxRadioGroup,
        DxCheckBox,
        DxButton
    },

    data() {
        return {
            daysFilterData,

            productSnapshots: [],
            latestProductSnapshots: [],

            options: {
                showAllMarkets: false,
                historicalDaysToShow: daysFilterData[0].ID
            },
            height: window.innerHeight * 0.45,
            width: window.innerWidth
        }
    },

    async beforeMount() {
        if (this.Snapshot) {
            /*this.FetchLatestProductSnapshots()
            this.FetchProductSnapshots()*/
            await this.Fetch()
        } else {
            await this.$router.push('/home')
        }
    },

    methods: {
        async Fetch() {
            await Promise.all([this.FetchProductSnapshots(), this.FetchLatestProductSnapshots()])
        },

        async FetchProductSnapshots() {
            if (this.Authenticated) {
               await API.get(`/dtn/snapshots/products/${this.Snapshot.ProductID}`)
                        .then(response => {
                            this.productSnapshots = response.data
                        })
                        .catch(error => {
                            this.$toast.open({
                                message: error.response.data.message,
                                type: 'error',
                            });
                        })
            } else {
                this.$router.push('/login')
            }
        },

        async FetchLatestProductSnapshots() {
            if (this.Authenticated) {
                await API.get(`/dtn/snapshots/products/latest/${this.Snapshot.ProductID}`)
                        .then(response => {
                            this.latestProductSnapshots = response.data
                        })
                        .catch(error => {
                            this.$toast.open({
                                message: error.response.data.message,
                                type: 'error',
                            });
                        })
            } else {
                this.$router.push('/login')
            }
        },

        onValueChanged(e) {
            this.options.historicalDaysToShow = e.value
        }
    },

    computed: {
        User() {
            return this.$store.getters['user/user']
        },

        Profile() {
            return this.$store.getters['user/profile']
        },

        Authenticated() {
            return this.$store.getters["user/authenticated"];
        },

        TodayProductSnapshots() {
            let ds = this.latestProductSnapshots;

            if (!this.options.showAllMarkets) {
                ds = ds.filter(product => {
                    return product.MarketID === this.Snapshot.MarketID
                })
            }

            return ds
        },

        HistoricalProductSnapshots() {
            let ds = this.productSnapshots;
            let daysBefore = moment().subtract(this.options.historicalDaysToShow, 'days')

            if (this.options.showAllMarkets) {
                ds = ds.filter(product => {
                    return this.Profile.MarketPreferences.includes(product.MarketProductID)
                })
            } else {
                ds = ds.filter(product => {
                    return product.MarketID === this.Snapshot.MarketID
                })
            }

            return ds.filter(product => {
                let m = moment(product.UpdatedOn);

                return m.diff(daysBefore, 'days') >= 0 &&
                        m.diff(daysBefore, 'days') <= this.options.historicalDaysToShow
            })
        },

        Snapshot() {
            return this.$route.params.snapshot
        },

        TodayChart() {
            return this.$refs.TodayChartRef
        }
    }
}
</script>

<style scoped>

</style>
