<template>
    <single-page :requires-auth="true">
        <b-container fluid>
            <b-row class="p-2 d-flex">
                <b-col xs="12" sm="12" md="4" lg="4" xl="3">
                    <DashboardPanel title="Android">
                        <template #toolbar>
                            <DxButton icon="repeat" class="float-left" hint="Generate new Android Codes" @click="GenerateAndroidCodes"/>
                            <DxButton icon="upload" class="float-left" hint="Upload a new Android Build" :visible="IsAdministrator" @click="(e) => { showAndroidAppUpload = true }"/>
                            <DxButton icon="refresh" class="float-right" hint="Refresh Android Data" @click="FetchAndroidStats"/>
                        </template>
                        <template #content>
                            <div class="mini-stat clearfix bg-android rounded m-2">
                                <span class="mini-stat-icon"><i class="fas fa-user"></i></span>
                                <div class="mini-stat-info">
                                    <span>{{ android.Used }}</span>
                                    Used Codes
                                </div>

                                <span class="mini-stat-icon"><i class="far fa-user"></i></span>
                                <div class="mini-stat-info">
                                    <span>{{ android.Free }}</span>
                                    Free Codes
                                </div>
                                <span class="mini-stat-icon"><i class="fas fa-barcode"></i></span>
                                <div class="mini-stat-info">
                                    <span>{{ android.Total }}</span>
                                    Total Codes
                                </div>
                            </div>
                        </template>
                    </DashboardPanel>
                </b-col>

                <b-col xs="12" sm="auto" md="auto" lg="auto" xl="auto" class="px-0"/>

                <b-col xs="12" sm="12" md="4" lg="4" xl="3">
                    <DashboardPanel title="Apple">
                        <template #toolbar>
                            <DxButton icon="paste" class="float-left" hint="Copy/Paste new Apple Codes" @click="(e) => { showUpload = true }"/>
                            <DxButton icon="refresh" class="float-right" hint="Refresh Apple Data" @click="FetchAppleStats"/>
                        </template>
                        <template #content>
                            <div class="mini-stat clearfix bg-apple rounded m-2">
                                <span class="mini-stat-icon"><i class="fas fa-user"></i></span>
                                <div class="mini-stat-info">
                                    <span>{{ apple.Used }}</span>
                                    Used Codes
                                </div>

                                <span class="mini-stat-icon"><i class="far fa-user"></i></span>
                                <div class="mini-stat-info">
                                    <span>{{ apple.Free }}</span>
                                    Free Codes
                                </div>
                                <span class="mini-stat-icon"><i class="fas fa-barcode"></i></span>
                                <div class="mini-stat-info">
                                    <span>{{ apple.Total }}</span>
                                    Total Codes
                                </div>
                            </div>
                        </template>
                    </DashboardPanel>
                </b-col>
            </b-row>
        </b-container>

        <DxPopup
            :visible="showUpload"
            :drag-enabled="false"
            :close-on-outside-click="false"
            :show-close-button="false"
            :show-title="true"
            :animation="{
                show: { type: 'pop', duration: 100 },
                hide: { type: 'pop', duration: 0 }
            }"
            shading-color="rgba(0,0,0,0.4)"
            height="auto"
            width="350"
            container=".dx-viewport"
            title="Upload New Apple Codes"
            @shown="(e) => { this.UploadAppleCodesForm.getEditor('Codes').focus() }"
        >
            <DxToolbarItem widget="dxButton" toolbar="bottom" location="after" :options="{ text: 'Upload', onClick: UploadAppleCodes }"/>
            <DxToolbarItem widget="dxButton" toolbar="bottom" location="after" :options="{ text: 'Close', onClick: (e) => { this.showUpload = false; this.newCodes.Codes = null } }"/>

            <template #content class="py-0">
                <DxForm
                        ref="UploadAppleCodesFormRef"
                        :form-data="newCodes"
                >
                    <DxSimpleItem
                            editor-type="dxTextArea"
                            data-field="Codes"
                            :label="{ visible: false }"
                            :editor-options="{ height: 250 }"
                            :required="true"
                    >
                        <DxRequiredRule/>
                    </DxSimpleItem>
                </DxForm>
            </template>
        </DxPopup>

        <DxPopup
                :visible="showAndroidAppUpload"
                :drag-enabled="false"
                :close-on-outside-click="false"
                :show-close-button="false"
                :show-title="true"
                :animation="{
                show: { type: 'pop', duration: 100 },
                hide: { type: 'pop', duration: 0 }
            }"
                shading-color="rgba(0,0,0,0.4)"
                height="350"
                width="350"
                container=".dx-viewport"
                title="Upload New Android Build"
        >
            <DxToolbarItem widget="dxButton" toolbar="bottom" location="center" :options="{ text: 'Close', onClick: (e) => { this.showAndroidAppUpload = false } }"/>

            <template #content class="py-0">
                <div class="widget-container flex-box">
                    <div
                        id="dropzone-external"
                        class="flex-box"
                        :class="[isDropZoneActive ? 'dx-theme-accent-as-border-color dropzone-active' : 'dx-theme-border-color']"
                    >
                        <img
                            id="dropzone-image"
                            :src="fileSource"
                            v-if="fileSource"
                            alt=""
                        >
                        <div
                                id="dropzone-text"
                                class="flex-box"
                                v-if="textVisible"
                        >
                            <span>Drag & Drop the desired file</span>
                            <span>…or click to browse for a file instead.</span>
                        </div>
                        <DxProgressBar
                                id="upload-progress"
                                :min="0"
                                :max="100"
                                width="30%"
                                :show-status="false"
                                :visible="progressVisible"
                                :value="progressValue"
                        />
                    </div>
                    <DxFileUploader
                            name="file"
                            dialog-trigger="#dropzone-external"
                            drop-zone="#dropzone-external"
                            :multiple="false"
                            :allowed-file-extensions="allowedFileExtensions"
                            upload-mode="instantly"
                            :upload-url="UploadUrl"
                            :upload-headers="UploadHeaders"
                            :visible="false"
                            @drop-zone-enter="onDropZoneEnter"
                            @drop-zone-leave="onDropZoneLeave"
                            @uploaded="onUploaded"
                            @progress="onProgress"
                            @upload-started="onUploadStarted"
                    />
                </div>
            </template>
        </DxPopup>
    </single-page>
</template>

<script>
/* eslint-disable */
import { DxProgressBar } from 'devextreme-vue/progress-bar';
import { DxFileUploader } from 'devextreme-vue/file-uploader';
import DxValidator, {DxRequiredRule} from 'devextreme-vue/validator'
import {
    DxDataGrid,
    DxColumn,
    DxHeaderFilter,
    DxSorting,
    DxScrolling,
    DxToolbar,
    DxItem,
    DxPaging,
    DxPager,
    DxSelection
} from 'devextreme-vue/data-grid';

import {
    DxForm,
    DxSimpleItem,
    DxEmptyItem,
    DxLabel,
    DxButtonItem,
} from 'devextreme-vue/form';

import {DxTextArea} from "devextreme-vue/text-area";
import DxSelectBox from 'devextreme-vue/select-box';
import { DxTextBox } from "devextreme-vue/text-box";

import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import { DxButton } from 'devextreme-vue/button';

import { confirm } from "devextreme/ui/dialog";

import { API } from "@/API";
import SinglePage from "@/components/SinglePage";
import DashboardPanel from "@/components/DashboardPanel.vue";

export default {
    name: "mobile-dashboard-view",

    components: {
        DashboardPanel,
        SinglePage,
        DxDataGrid,
        DxColumn,
        DxHeaderFilter,
        DxSorting,
        DxScrolling,
        DxPaging,
        DxPager,
        DxToolbar,
        DxItem,
        DxButton,
        DxPopup,
        DxToolbarItem,
        DxSelection,
        DxTextBox,
        DxTextArea,
        DxForm,
        DxSimpleItem,
        DxEmptyItem,
        DxLabel,
        DxButtonItem,
        DxRequiredRule,
        DxFileUploader,
        DxProgressBar
    },

    async beforeMount() {
        await this.Fetch()
    },

    data() {
        return {
            android: null,
            apple: null,
            showUpload: false,
            newCodes: {
                Codes: null
            },
            showAndroidAppUpload: false,
            isDropZoneActive: false,
            fileSource: '',
            textVisible: true,
            progressVisible: false,
            progressValue: 0,
            allowedFileExtensions: ['.apk']
        }
    },

    methods: {
        async Fetch() {
            await this.FetchAndroidStats();
            await this.FetchAppleStats();
        },

        async FetchAndroidStats() {
            await this.$store.dispatch('loading/message', "Retrieving Android Stats...")

            if (this.Authenticated) {
                API.get('/admin/functions/android/stats')
                    .then(response => {
                        this.android = response.data
                    })
                    .catch(error => {
                        this.$toast.open({
                            message: error.response.data.message,
                            type: 'error',
                        });
                    })
            } else {
                await this.$router.push('/login')
            }
        },

        async FetchAppleStats() {
            await this.$store.dispatch('loading/message', "Retrieving Apple Stats...")

            if (this.Authenticated) {
                API.get('/admin/functions/apple/stats')
                        .then(response => {
                            this.apple = response.data
                        })
                        .catch(error => {
                            this.$toast.open({
                                message: error.response.data.message,
                                type: 'error',
                            });
                        })
            } else {
                await this.$router.push('/login')
            }
        },

        async GenerateAndroidCodes() {
            let result = confirm("Are you want to generate more Android codes?", `Confirm Generation`);

            result.then(async (dialogResult) => {
                if (dialogResult) {
                    await this.$store.dispatch('loading/message', "Generating Android Codes...")

                    if (this.Authenticated) {
                        API.post('/admin/functions/android/codes/generate')
                                .then(response => {
                                    this.FetchAndroidStats()

                                    this.$toast.open({
                                        message: 'New Android Codes Generated...',
                                        type: 'success',
                                    });
                                })
                                .catch(error => {
                                    this.$toast.open({
                                        message: error.response.data.message,
                                        type: 'error',
                                    });
                                })
                    } else {
                        await this.$router.push('/login')
                    }
                }
            })
        },

        async UploadAppleCodes() {
            const result = this.UploadAppleCodesForm.validate();

            if (result.isValid) {
                await this.$store.dispatch('loading/message', "Generating Android Codes...")

                if (this.Authenticated) {
                    API.post('/admin/functions/apple/codes/upload', this.newCodes)
                            .then(response => {
                                this.FetchAppleStats()

                                this.newCodes.Codes = null
                                this.showUpload = false

                                this.$toast.open({
                                    message: 'New Apple Codes Uploaded...',
                                    type: 'success',
                                });
                            })
                            .catch(error => {
                                this.newCodes.Codes = null
                                this.showUpload = false

                                this.$toast.open({
                                    message: error.response.data.message,
                                    type: 'error',
                                });
                            })
                } else {
                    await this.$router.push('/login')
                }
            }
        },

        onDropZoneEnter(e) {
            if (e.dropZoneElement.id === 'dropzone-external') {
                this.isDropZoneActive = true;
            }
        },

        onDropZoneLeave(e) {
            if (e.dropZoneElement.id === 'dropzone-external') {
                this.isDropZoneActive = false;
            }
        },

        onUploaded({ file }) {
            const fileReader = new FileReader();

            fileReader.onload = () => {
                this.isDropZoneActive = false;
                this.fileSource = fileReader.result;
            };

            fileReader.readAsDataURL(file);

            this.textVisible = false;
            this.progressVisible = false;
            this.progressValue = 0;
        },

        onProgress(e) {
            this.progressValue = (e.bytesLoaded / e.bytesTotal) * 100;
        },

        onUploadStarted() {
            this.fileSource = '';
            this.progressVisible = true;
        }
    },

    computed: {
        User() {
            return this.$store.getters['user/user']
        },

        Authenticated() {
            return this.$store.getters["user/authenticated"];
        },

        UploadAppleCodesForm() {
            return this.$refs.UploadAppleCodesFormRef.instance
        },

        IsAdministrator() {
            return this.$gates.hasRole('Super Administrator')
        },

        UploadUrl() {
            return new URL("/api/admin/functions/android/app/upload", process.env.VUE_APP_API_URL)
        },

        UploadHeaders() {
            return {Authorization: `Bearer ${this.$store.getters['user/token']}`}
        }
    }
}
</script>

<style scoped>
.rounded {
    -webkit-border-radius: 3px !important;
    -moz-border-radius: 3px !important;
    border-radius: 3px !important;
}

.mini-stat {
    padding: 15px;
    margin-bottom: 20px;
}

.mini-stat-icon {
    width: 60px;
    height: 60px;
    display: inline-block;
    line-height: 60px;
    text-align: center;
    font-size: 30px;
    background: none repeat scroll 0% 0% #EEE;
    border-radius: 100%;
    float: left;
    margin-right: 10px;
    color: #FFF;
}

.mini-stat-info {
    font-size: 12px;
    padding-top: 2px;
}

span, p {
    color: white;
}

.mini-stat-info span {
    display: block;
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 5px;
    margin-top: 7px;
}

.bg-android {
    background-color: #4f983b !important;
    border: 1px solid #4f983b;
    color: white;
}

.bg-apple {
    background-color: #3b6698 !important;
    border: 1px solid #284260;
    color: white;
}

.fa-user {
    color: black !important;
}

.fa-tablet-alt {
    color: black !important;
}

.fa-barcode {
    color: black !important;
}


#dropzone-external {
    width: 350px;
    height: 350px;
    background-color: rgba(183, 183, 183, 0.1);
    border-width: 2px;
    border-style: dashed;
    padding: 10px;
}

#dropzone-external > * {
    pointer-events: none;
}

#dropzone-external.dropzone-active {
    border-style: solid;
}

.widget-container > span {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 16px;
}

#dropzone-image {
    max-width: 100%;
    max-height: 100%;
}

#dropzone-text > span {
    font-weight: 100;
    opacity: 0.5;
}

#upload-progress {
    display: flex;
    margin-top: 10px;
}

.flex-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
</style>
