<template>
    <single-page :requires-auth="true">
        <b-container fluid class="p-2">
            <b-row class="d-flex">
                <b-col sm="12" md="10" lg="8" xl="6">
                    <b-card-group deck>
                        <b-card bg-variant="light" header="User Profile" class="shadow-sm font-weight-bold" style="font-size: 18px;">
                            <b-card-text>
                                <DxForm
                                        :col-count="6"
                                        :form-data.sync="settings"
                                        label-location="top"
                                        validation-group="userprofilevalidationgroup"
                                        :show-validation-summary="true"
                                        :align-item-labels-in-all-groups="true"
                                        class="p-2"
                                >
                                    <DxGroupItem
                                            caption="System Information"
                                            :col-span="2"
                                    >
                                        <DxSimpleItem
                                                data-field="User.FirstName"
                                        />
                                        <DxSimpleItem
                                                data-field="User.LastName"
                                        />
                                    </DxGroupItem>

                                    <DxGroupItem
                                            caption="Contact Information"
                                            :col-span="2"
                                    >
                                        <DxSimpleItem
                                                data-field="User.Email"
                                        >
                                            <DxEmailRule message="Email is invalid"/>
                                        </DxSimpleItem>
                                        <DxSimpleItem
                                                data-field="User.Phone"
                                                :label="{ text: 'Phone'}"
                                                :editor-options="{maxLength: 11, mask: 'Y (X00)-000-0000', maskRules: { 'Y': /[1]/, 'X': /[1-9]/ }, value: !settings.User.Phone ? '1' : settings.User.Phone }"
                                        />
                                        <DxSimpleItem
                                                data-field="Profile.MessagePreferences.PushNotifications"
                                                editor-type="dxCheckBox"
                                                :visible="$gates.hasAnyRole('Account Manager|Administrator|Super Administrator')"
                                                :label="{ visible: false }"
                                                :editor-options="{ text: 'Receive Support Messages via Push Notifications' }"
                                        />
                                    </DxGroupItem>

                                    <DxGroupItem
                                            caption="Devices"
                                            :col-span="2"
                                    >
                                        <DxSimpleItem
                                                :show-colon="false"
                                                :label="{ visible: false }"
                                        >
                                            <template #default>
                                                <div class="devices p-1 font-large border rounded">
                                                    <DxToolbar class="p-2 mb-2 bg-white border rounded">
                                                        <DxItem location="before" template="addDeviceTemplate"/>
                                                        <DxItem location="before" template="editDeviceTemplate"/>
                                                        <DxItem location="before" template="deleteDeviceTemplate"/>
                                                        <DxItem location="after" template="refreshTemplate"/>

                                                        <template #addDeviceTemplate>
                                                            <DxButton icon="add" :disabled="DeviceCount" @click="onAddDeviceClick"/>
                                                        </template>
                                                        <template #editDeviceTemplate>
                                                            <DxButton ref="EditDeviceButtonReference" icon="edit" :disabled="!selectedDevice" @click="onEditDeviceClick"/>
                                                        </template>
                                                        <template #deleteDeviceTemplate>
                                                            <DxButton ref="DeleteDeviceButtonReference" icon="remove" :disabled="!selectedDevice" @click="onDeleteDeviceClick"/>
                                                        </template>
                                                        <template #refreshTemplate>
                                                            <DxButton icon="refresh" @click="onDeviceRefreshClick"/>
                                                        </template>
                                                    </DxToolbar>
                                                    <DxList
                                                        ref="DeviceListReference"
                                                        :data-source.sync="devices"
                                                        :select-by-click="true"
                                                        @item-click="onDeviceClick"
                                                        selection-mode="single"
                                                        no-data-text="No Devices..."
                                                        :display-expr="(device) => `${device.Name} (${device.Platform} ${device.Type})`"
                                                        value-expr="ID"
                                                        key-expr="ID"
                                                        class="m-auto"
                                                        item-template="list-item">
                                                    >
                                                        <template #list-item="{ data }">
                                                            <div>
                                                               {{ data.Name }}
                                                                <div class="float-right">
                                                                    <i style="padding: 4px;" :class="data.Platform === 'android' ? 'fab fa-android' : 'fab fa-apple'"/>
                                                                    <i style="padding: 4px;" :class="data.Type === 'smartphone' ? 'fas fa-mobile-alt' : 'fas fa-tablet-alt'"/>
                                                                </div>
                                                            </div>
                                                        </template>
                                                    </DxList>
                                                </div>
                                            </template>
                                        </DxSimpleItem>
                                    </DxGroupItem>

                                    <DxGroupItem
                                            caption="Preferences"
                                            :col-span="6"
                                    >
                                        <DxSimpleItem>
                                            <template #default>
                                                <b-tabs :value="CanUseMarkets ? 0 : 1">
                                                    <b-tab title="Markets" :title-item-class="CanUseMarkets ? '' : 'd-none'">
                                                        <b-container fluid class="border p-2">
                                                            <b-row no-gutters class="d-flex">
                                                                <b-col xs="auto" sm="auto" md="auto" lg="auto" xl="auto">
                                                                    <DxDataGrid
                                                                            ref="MarketPreferencesGridRef"
                                                                            no-data-text="No Customer Market Preferences..."
                                                                            key-expr="ID"
                                                                            class="p-2 border"
                                                                            style="background-color: #fff"
                                                                            width="100%"
                                                                            :data-source="markets"
                                                                            :selection="{ mode: 'multiple' }"
                                                                            :hover-state-enabled="true"
                                                                            :show-borders="true"
                                                                            :show-row-lines="true"
                                                                            :column-auto-width="true"
                                                                            :repaint-changes-only="false"
                                                                            :two-way-binding-enabled="false"
                                                                            :column-hiding-enabled="false"
                                                                            :selected-row-keys.sync="selectedMarkets"
                                                                            :cache-enabled="false"
                                                                            :load-panel="{ enabled: true, maxWidth: 500, minWidth: 250, indicatorSrc: 'rolling.svg', shadingColor: 'rgba(0,0,0,0.4)', text: 'Loading Market Preferences...' }"
                                                                    >
                                                                        <DxPaging :page-size="10"/>
                                                                        <DxPager :show-info="true"/>
                                                                        <DxSelection mode="multiple" show-check-boxes-mode="always"/>
                                                                        <DxHeaderFilter :visible="true"/>
                                                                        <DxFilterRow :visible="false"/>

                                                                        <DxColumn
                                                                                data-field="MarketID"
                                                                                caption="Market ID"
                                                                                :allow-header-filtering="false"
                                                                                :allow-sorting="false"
                                                                                :visible="false"
                                                                        />
                                                                        <DxColumn
                                                                                data-field="Market.Name"
                                                                                caption="Market"
                                                                                sort-order="asc"
                                                                                :sort-index="0"
                                                                                :width="150"
                                                                        />
                                                                        <DxColumn
                                                                                data-field="ProductID"
                                                                                caption="Product ID"
                                                                                :allow-header-filtering="false"
                                                                                :allow-sorting="false"
                                                                                :visible="false"
                                                                        />
                                                                        <DxColumn
                                                                                data-field="Product.Name"
                                                                                caption="Ticker"
                                                                                sort-order="asc"
                                                                                :sort-index="1"
                                                                        />
                                                                    </DxDataGrid>
                                                                </b-col>
                                                            </b-row>
                                                        </b-container>
                                                    </b-tab>
                                                    <b-tab title="Outages">
                                                        <b-container fluid class="border p-2">
                                                            <b-row no-gutters class="d-flex">
                                                                <b-col xs="auto" sm="auto" md="auto" lg="auto" xl="auto">
                                                                    <DxDataGrid
                                                                            id="gridContainer"
                                                                            ref="OutagesPreferencesGridRef"
                                                                            no-data-text="No Customer Outage Associations..."
                                                                            key-expr="TerminalNo"
                                                                            class="p-2 border"
                                                                            style="background-color: #fff"
                                                                            width="100%"
                                                                            :data-source="terminals"
                                                                            :selection="{ mode: 'multiple' }"
                                                                            :hover-state-enabled="true"
                                                                            :show-borders="true"
                                                                            :show-row-lines="true"
                                                                            :column-auto-width="true"
                                                                            :repaint-changes-only="false"
                                                                            :two-way-binding-enabled="false"
                                                                            :column-hiding-enabled="false"
                                                                            :selected-row-keys.sync="selectedTerminals"
                                                                            :cache-enabled="false"
                                                                    >
                                                                        <DxPaging :page-size="10"/>
                                                                        <DxPager :show-info="true"/>
                                                                        <DxSelection mode="multiple" show-check-boxes-mode="always"/>
                                                                        <DxHeaderFilter :visible="true"/>
                                                                        <DxFilterRow :visible="true"/>

                                                                        <DxColumn
                                                                                data-field="TerminalNo"
                                                                                sort-order="asc"
                                                                                :allow-header-filter="false"
                                                                                :sorting-method="SortingMethod"
                                                                        />
                                                                        <DxColumn
                                                                                caption="Name"
                                                                                data-field="Name"
                                                                                :allow-header-filter="false"
                                                                                :allow-filtering="true"
                                                                        />
                                                                        <DxColumn
                                                                                data-field="City"
                                                                        />
                                                                        <DxColumn
                                                                                data-field="State"
                                                                                :width="85"
                                                                        />
                                                                    </DxDataGrid>
                                                                </b-col>
                                                            </b-row>
                                                        </b-container>
                                                    </b-tab>
                                                </b-tabs>
                                            </template>
                                        </DxSimpleItem>
<!--                                        <DxTabbedItem>
                                            <DxTabPanelOptions
                                                :defer-rendering="false"
                                                :on-title-click="onPreferencesTabItemClicked"
                                                :selected-index="1"
                                            />

                                            <DxTab
                                                title="Markets"
                                                :col-count="1"
                                                :visible="CanUseMarkets"
                                            >
                                                <DxSimpleItem
                                                    :col-span="1"
                                                >
                                                    <template #default>
                                                        <DxDataGrid
                                                                ref="MarketPreferencesGridRef"
                                                                no-data-text="No Customer Market Preferences..."
                                                                key-expr="ID"
                                                                class="p-2 border"
                                                                style="background-color: #fff"
                                                                width="100%"
                                                                :data-source="markets"
                                                                :selection="{ mode: 'multiple' }"
                                                                :hover-state-enabled="true"
                                                                :show-borders="true"
                                                                :show-row-lines="true"
                                                                :column-auto-width="true"
                                                                :repaint-changes-only="false"
                                                                :two-way-binding-enabled="false"
                                                                :column-hiding-enabled="false"
                                                                :selected-row-keys.sync="selectedMarkets"
                                                                :cache-enabled="false"
                                                                :load-panel="{ enabled: true, maxWidth: 500, minWidth: 250, indicatorSrc: 'rolling.svg', shadingColor: 'rgba(0,0,0,0.4)', text: 'Loading Market Preferences...' }"
                                                        >
                                                            <DxPaging :page-size="10"/>
                                                            <DxPager :show-info="true"/>
                                                            <DxSelection mode="multiple" show-check-boxes-mode="always"/>
                                                            <DxHeaderFilter :visible="true"/>
                                                            <DxFilterRow :visible="false"/>

                                                            <DxColumn
                                                                    data-field="MarketID"
                                                                    caption="Market ID"
                                                                    :allow-header-filtering="false"
                                                                    :allow-sorting="false"
                                                                    :visible="false"
                                                            />
                                                            <DxColumn
                                                                    data-field="Market.Name"
                                                                    caption="Market"
                                                                    sort-order="asc"
                                                                    :sort-index="0"
                                                                    :width="150"
                                                            />
                                                            <DxColumn
                                                                    data-field="ProductID"
                                                                    caption="Product ID"
                                                                    :allow-header-filtering="false"
                                                                    :allow-sorting="false"
                                                                    :visible="false"
                                                            />
                                                            <DxColumn
                                                                    data-field="Product.Name"
                                                                    caption="Ticker"
                                                                    sort-order="asc"
                                                                    :sort-index="1"
                                                            />
                                                        </DxDataGrid>
                                                    </template>
                                                </DxSimpleItem>
                                            </DxTab>

                                            <DxTab
                                                title="Outages"
                                                :col-count="4"
                                                :visible="true"
                                            >
                                                <DxSimpleItem
                                                    :col-span="4"
                                                >
                                                    <template #default>
                                                        <DxDataGrid
                                                            id="gridContainer"
                                                            ref="OutagesPreferencesGridRef"
                                                            no-data-text="No Customer Outage Associations..."
                                                            key-expr="TerminalNo"
                                                            class="p-2 border"
                                                            style="background-color: #fff"
                                                            width="100%"
                                                            :data-source="terminals"
                                                            :selection="{ mode: 'multiple' }"
                                                            :hover-state-enabled="true"
                                                            :show-borders="true"
                                                            :show-row-lines="true"
                                                            :column-auto-width="true"
                                                            :repaint-changes-only="false"
                                                            :two-way-binding-enabled="false"
                                                            :column-hiding-enabled="false"
                                                            :selected-row-keys.sync="selectedTerminals"
                                                            :cache-enabled="false"
                                                        >
                                                            <DxPaging :page-size="10"/>
                                                            <DxPager :show-info="true"/>
                                                            <DxSelection mode="multiple" show-check-boxes-mode="always"/>
                                                            <DxHeaderFilter :visible="true"/>
                                                            <DxFilterRow :visible="true"/>

                                                            <DxColumn
                                                                data-field="TerminalNo"
                                                                sort-order="asc"
                                                                :allow-header-filter="false"
                                                                :sorting-method="SortingMethod"
                                                            />
                                                            <DxColumn
                                                                caption="Name"
                                                                data-field="Name"
                                                                :allow-header-filter="false"
                                                                :allow-filtering="true"
                                                            />
                                                            <DxColumn
                                                                data-field="City"
                                                            />
                                                            <DxColumn
                                                                data-field="State"
                                                                :width="85"
                                                            />
                                                        </DxDataGrid>
                                                    </template>
                                                </DxSimpleItem>
                                            </DxTab>
                                        </DxTabbedItem>-->

                                    </DxGroupItem>

                                    <DxButtonItem
                                            :button-options="{text: 'Save', icon: 'save', type: 'success', useSubmitBehavior: false, onClick: Save}"
                                            :col-span="6"
                                            horizontal-alignment="right"
                                    />
                                </DxForm>
                            </b-card-text>
                        </b-card>
                    </b-card-group>
                </b-col>
            </b-row>
        </b-container>

<!-- Add New Device Popup -->
        <DxPopup
            :visible="showAddNewDevicePopup"
            :drag-enabled="false"
            :close-on-outside-click="false"
            :show-close-button="false"
            :show-title="true"
            :animation="{
                    show: { type: 'pop', duration: 100 },
                    hide: { type: 'pop', duration: 0 }
                }"
            shading-color="rgba(0,0,0,0.4)"
            height="auto"
            width="350"
            container=".dx-viewport"
            title="New Device"
        >
            <DxToolbarItem widget="dxButton" toolbar="bottom" location="after" :disabled="!(addDevice)" :options="{ text: 'Save', onClick: AddDevice }"/>
            <DxToolbarItem widget="dxButton" toolbar="bottom" location="after" :options="{ text: 'Close', onClick: () => {
                this.showAddNewDevicePopup = false;
                this.selectedDevice = null;
                this.addDevice = null ;
                this.DeviceList.unselectAll()
            } }"/>

            <template #content class="py-0">
                <DxForm
                        ref="NewDeviceFormReference"
                        :form-data="addDevice"
                >
                    <DxSimpleItem
                        data-field="Name"
                        caption="Name"
                        :required="true"
                    >
                        <DxRequiredRule/>
                    </DxSimpleItem>
                    <DxSimpleItem data-field="Type"  :required="true">
                        <DxLabel text="Type" />
                        <template #default>
                            <DxSelectBox :data-source="deviceTypes" value-expr="ID" display-expr="Name" :value.sync="addDevice.Type" :width="100"/>
                        </template>
                        <DxRequiredRule/>
                    </DxSimpleItem>
                    <DxSimpleItem data-field="Platform" :required="true">
                        <DxLabel text="Platform" />
                        <template #default>
                            <DxSelectBox :data-source="devicePlatforms" value-expr="ID" display-expr="Name" :value.sync="addDevice.Platform" :width="100"/>
                        </template>
                        <DxRequiredRule/>
                    </DxSimpleItem>
                    <DxSimpleItem
                            data-field="Comment"
                            caption="Comment"
                            editor-type="dxTextArea"
                            :label="{ location: 'top'}"
                    />
                </DxForm>
            </template>
        </DxPopup>

<!-- Edit Device Popup -->
        <DxPopup
            :visible="showEditDevicePopup"
            :drag-enabled="false"
            :close-on-outside-click="false"
            :show-close-button="false"
            :show-title="true"
            :animation="{
                    show: { type: 'pop', duration: 100 },
                    hide: { type: 'pop', duration: 0 }
                }"
            shading-color="rgba(0,0,0,0.4)"
            height="auto"
            width="350"
            container=".dx-viewport"
            title="Edit Device"
        >
            <DxToolbarItem widget="dxButton" toolbar="bottom" location="after" :disabled="!(editDevice)" :options="{ text: 'Save', onClick: EditDevice }"/>
            <DxToolbarItem widget="dxButton" toolbar="bottom" location="after" :options="{ text: 'Close', onClick: () => {
                this.showEditDevicePopup = false;
                this.selectedDevice = null;
                this.editDevice = null;
                this.DeviceList.unselectAll()
            } }"/>

            <template #content class="py-0">
                <DxForm
                    ref="EditDeviceFormReference"
                    :form-data="editDevice"
            >
                <DxSimpleItem
                    data-field="Name"
                    caption="Name"
                    :required="true"
                >
                    <DxRequiredRule/>
                </DxSimpleItem>
                <DxSimpleItem data-field="Type"  :required="true">
                    <DxLabel text="Type" />
                    <template #default>
                        <DxSelectBox :data-source="deviceTypes" value-expr="ID" display-expr="Name" :disabled="true" :value.sync="editDevice.Type" :width="100"/>
                    </template>
                </DxSimpleItem>
                <DxSimpleItem data-field="Platform" :required="true">
                    <DxLabel text="Platform" />
                    <template #default>
                        <DxSelectBox :data-source="devicePlatforms" value-expr="ID" display-expr="Name" :disabled="true" :value.sync="editDevice.Platform" :width="100"/>
                    </template>
                </DxSimpleItem>
                <DxSimpleItem
                    data-field="Comment"
                    caption="Comment"
                    editor-type="dxTextArea"
                    :label="{ location: 'top'}"
                />
            </DxForm>
            </template>
        </DxPopup>
    </single-page>
</template>

<script>
/* eslint-disable */

import {DxPopup, DxToolbarItem} from 'devextreme-vue/popup';
import SinglePage from "@/components/SinglePage";
import { DxSelectBox } from 'devextreme-vue/select-box';
import {DxButton} from 'devextreme-vue/button'
import DxValidator, {DxRequiredRule} from 'devextreme-vue/validator'
import {
    DxForm,
    DxSimpleItem,
    DxEmptyItem,
    DxLabel,
    DxGroupItem,
    DxButtonItem,
    DxEmailRule,
    DxTabbedItem,
    DxTabPanelOptions,
    DxTab,
} from 'devextreme-vue/form';

import {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxPager,
    DxHeaderFilter,
    DxSorting,
    DxFilterRow,
    DxSelection,
} from 'devextreme-vue/data-grid';

import DxToolbar, {DxItem} from 'devextreme-vue/toolbar';
import DxList from 'devextreme-vue/list';

import {API} from "../API";
import {confirm} from "devextreme/ui/dialog";

const DEVICETYPES = [{ID: 'smartphone', Name: 'Smartphone'}, {ID: 'tablet', Name: 'Tablet'}]
const PLATFORMS = [{ID: 'android', Name: 'Android'}, {ID: 'apple', Name: 'Apple'}]

export default {
    name: 'profile-view',

    components: {
        SinglePage,
        DxForm,
        DxSimpleItem,
        DxLabel,
        DxGroupItem,
        DxEmptyItem,
        DxButtonItem,
        DxEmailRule,
        DxList,
        DxToolbar,
        DxItem,
        DxTabbedItem,
        DxTabPanelOptions,
        DxTab,
        DxDataGrid,
        DxColumn,
        DxPaging,
        DxPager,
        DxHeaderFilter,
        DxSorting,
        DxFilterRow,
        DxSelection,
        DxSelectBox,
        DxButton,
        DxPopup,
        DxToolbarItem,
        DxRequiredRule
    },

    data() {
        return {
            deviceTypes: DEVICETYPES,
            devicePlatforms: PLATFORMS,
            devices: [],
            markets: [],
            products: [],
            terminals: [],
            settings: {
                User: {
                    FirstName: null,
                    LastName: null,
                    Phone: null,
                    Email: null
                }
            },
            selectedMarkets: [],
            selectedTerminals: [],
            selectedDevice: null,
            editDevice: null,
            addDevice: null,
            showAddNewDevicePopup: false,
            showEditDevicePopup: false,
        }
    },

    async mounted() {
        this.settings = {
            User: this.Impersonating() ? this.ImpersonatedUser() : this.User,
            Profile: this.Impersonating() ? this.ImpersonatedUser().Profile : this.Profile,
            Devices: this.Devices
        }

        this.$root.$on('customer-changed', async (customer) => {
            await this.FetchCustomerTerminals()
        })

        this.$root.$on('bid-template-changed', async (bidtemplate) => {
            await this.FetchCustomerTerminals()
        })

        this.$root.$on('user-impersonated', this.Fetch)
        this.$root.$on('user-unpersonated', this.Fetch)

        this.selectedMarkets = this.settings.Profile.MarketPreferences
        this.selectedTerminals = this.SelectedTerminals

        await this.Fetch()
    },

    beforeDestroy() {
        this.$root.$off('customer-changed')
        this.$root.$off('bid-template-changed')

        this.$root.$off('user-impersonated')
        this.$root.$off('user-unpersonated')
    },

    methods: {
        async Fetch() {
            if (this.Authenticated) {
                this.settings = {
                    User: this.Impersonating() ? this.ImpersonatedUser() : this.User,
                    Profile: this.Impersonating() ? this.ImpersonatedUser().Profile : this.Profile
                }

                await this.FetchMarkets()
                await this.FetchCustomerTerminals()
                await this.FetchDevices()
            } else {
                await this.$router.push('/login')
            }
        },

        async FetchMarkets() {
            this.$store.dispatch('loading/message', "Retrieving Markets...")

            API.get('/dtn/markets')
                .then(response => {
                    this.markets = response.data
                })
                .catch(error => {
                    this.$toast.open({
                        message: error.response.data.message,
                        type: 'error',
                    });
                })
        },

        async FetchCustomerTerminals() {
            if (this.Authenticated) {
                this.$store.dispatch('loading/message', "Retrieving Customer Terminals...")

                const bidtemplate = this.$store.state.bidtemplates.bidtemplate

                if(bidtemplate)
                    API.get(`/customers/${bidtemplate.CustomerNo}/${bidtemplate.TemplateID}/terminals`)
                        .then(response => {
                            this.terminals = response.data
                        })
                        .catch(error => {
                            this.$toast.open({
                                message: error.response.data.message,
                                type: 'error',
                            });
                        })
                else {
                    this.reports = []
                    this.selectedReport = null
                }
            } else {
                this.$router.push('/login')
            }
        },

        async FetchDevices() {
            API.get(`/devices`)
                    .then(response => {
                        this.devices = response.data
                    })
                    .catch(error => {
                        this.devices = error.response.data
                        this.$toast.open({
                            message: error.response.data.message,
                            type: 'error',
                        });
                    })
        },

        async Save(e) {
            this.settings.Profile.MarketPreferences = this.MarketPreferencesGrid.getSelectedRowKeys()

            this.SaveOutagePreferences()

            this.$store.dispatch('user/update_profile', this.settings).then(() => {
                this.$toast.open({
                    message: 'Profile Updated!',
                    type: 'success',
                });

                this.$toast.open({
                    message: 'Market Preference Changes will take effect after 12:00 AM CST',
                    type: 'warning',
                });
            })
        },

        SaveOutagePreferences() {
            let outage = this.settings.Profile.Outages.find((item) => {
                return item.CustomerNo === this.Customer.CustomerNo
            })

            if(outage) {
                outage.Terminals = this.selectedTerminals
            } else {
                this.settings.Profile.Outages.push({
                    CustomerNo: this.Customer.CustomerNo,
                    Terminals: this.selectedTerminals
                })
            }

            this.MarketPreferencesGrid.clearFilter()
            this.OutagesPreferencesGrid.clearFilter()

            this.OutagesPreferencesGrid.refresh()
            this.OutagesPreferencesGrid.pageIndex(0)
        },

        SortingMethod(value1, value2) {
            var isSelected1 = this.OutagesPreferencesGrid.isRowSelected(value1),
                isSelected2 = this.OutagesPreferencesGrid.isRowSelected(value2)

            if(isSelected1 && !isSelected2) return -1
            if(!isSelected1 && isSelected2) return 1

            return 0
        },

        onPreferencesTabItemClicked(e) {
            switch (e.itemIndex) {
                case 0:
                    //this.MarketPreferencesGrid.refresh()
                    break;
                case 1:
                    this.OutagesPreferencesGrid.refresh()
                    break;
            }
        },

        async onDeviceRefreshClick(e) {
            await this.FetchDevices()
        },

        onAddDeviceClick(e) {
            this.addDevice = {
                Name: 'New Device Name',
                Type: DEVICETYPES[0].ID,
                Platform: PLATFORMS[1].ID,
            }

            this.showAddNewDevicePopup = true
        },

        AddDevice() {
            const result = this.NewDeviceForm.validate();

            if (result.isValid) {
                this.$store.dispatch('loading/message', "Adding New Device...")

                API.post(`/devices`, this.addDevice)
                        .then(response => {
                            this.devices = response.data.data
                            this.selectedDevice = null
                            this.addDevice = null
                            this.showAddNewDevicePopup = false

                            this.$toast.open({
                                message: 'Device Saved...',
                                type: 'success',
                            });
                        })
                        .catch(error => {
                            this.devices = error.response.data.data
                            this.selectedDevice = null
                            this.addDevice = null
                            this.showAddNewDevicePopup = false

                            this.$toast.open({
                                message: error.response.data.message,
                                type: 'error',
                            });
                        })
            }
        },

        onEditDeviceClick(e) {
            this.editDevice = { ...this.selectedDevice }
            this.showEditDevicePopup = true
        },

        EditDevice(e) {
            const result = this.EditDeviceForm.validate();

            if (result.isValid) {
                this.$store.dispatch('loading/message', "Saving Device...")

                API.put(`/devices`, this.editDevice)
                        .then(response => {
                            this.devices = response.data.data
                            this.selectedDevice = null
                            this.editDevice = null
                            this.showEditDevicePopup = false

                            this.$toast.open({
                                message: 'Device Saved...',
                                type: 'success',
                            });
                        })
                        .catch(error => {
                            this.devices = error.response.data.data
                            this.selectedDevice = null
                            this.editDevice = null
                            this.showEditDevicePopup = false

                            this.$toast.open({
                                message: error.response.data.message,
                                type: 'error',
                            });
                        })
            }
        },

        onDeleteDeviceClick(e) {
            let result = confirm("Are you sure?", `Confirm Deletion`);

            result.then((dialogResult) => {
                if (dialogResult) {
                    this.$store.dispatch('loading/message', "Removing Device...")

                    API.delete(`/devices/${this.selectedDevice.ID}`)
                            .then(response => {
                                this.devices = response.data.data
                                this.selectedDevice = null

                                this.$toast.open({
                                    message: 'Device Removed...',
                                    type: 'success',
                                });
                            })
                            .catch(error => {
                                this.$toast.open({
                                    message: error.response.data.message,
                                    type: 'error',
                                });
                            })
                }
            })
        },

        onDeviceClick(e) {
            this.selectedDevice = {...e.itemData}
        },

        Impersonating() {
            return this.$store.getters['impersonate/impersonating'];
        },

        ImpersonatedUser() {
            return this.$store.getters['impersonate/user'];
        },
    },

    computed: {
        User() {
            return this.$store.getters['user/user']
        },

        Authenticated() {
            return this.$store.getters["user/authenticated"]
        },

        Customer() {
            return this.$store.getters['customers/customer']
        },

        BidTemplate() {
            return this.$store.getters['bidtemplates/bidtemplate']
        },

        Profile() {
            return this.$store.getters['user/profile']
        },

        MarketPreferencesGrid() {
            return this.$refs.MarketPreferencesGridRef.instance
        },

        SelectedTerminals() {
            const customer = this.settings.Profile.Outages.find((item) => {
                return item.CustomerNo === this.Customer.CustomerNo
            })

            return customer ? customer.Terminals : []
        },

        OutagesPreferencesGrid() {
            return this.$refs.OutagesPreferencesGridRef.instance
        },

        CanUseMarkets() {
            return this.$gates.hasAnyRole('Super Administrator|Administrator|Account Manager')
        },

        DeviceCount() {
            return this.devices.length === 3
        },

        NewDeviceForm() {
            return this.$refs.NewDeviceFormReference.instance
        },

        EditDeviceForm() {
            return this.$refs.EditDeviceFormReference.instance
        },

        DeviceList() {
            return this.$refs.DeviceListReference.instance
        },

        EditDeviceButton() {
            return this.$refs.EditDeviceButtonReference.instance
        },

        DeleteDeviceButton() {
            return this.$refs.DeleteDeviceButtonReference.instance
        },

        Test() {
            return !this.selectedDevice
        }
    }
}
</script>

<style scoped>
    ::v-deep label {
        margin-bottom: 0.05rem !important;
    }

    ::v-deep .dx-command-select {
        width: 45px!important;
        min-width: 45px!important;
    }

    ::v-deep .dx-tab.dx-tab-selected {
        background-color: $FuelMastersBlue;
        color: #333;
    }

    ::v-deep .devices {
        background-color: #f5f5f5;
    }

    ::v-deep .dx-list-item-content {
        font-size: 1rem;
    }

    ::v-deep .dx-list-item {
        border: 1px solid #dee2e6 !important;
        border-radius: 4px;
        background-color: white;
    }

    ::v-deep .dx-toolbar {
        background-color: transparent;
    }

    ::v-deep .dx-empty-message {
        padding-top: 10px;
        text-align: center;
    }
</style>
