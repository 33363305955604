<template>
    <div class="side-nav-outer-toolbar">
        <header-toolbar
            class="layout-header"
            :menu-toggle-enabled="true"
            :toggle-menu-func="toggleMenu"
            :title="title"
        />
        <dx-drawer
            class="layout-body"
            position="before"
            template="menu"
            :opened.sync="menuOpened"
            :opened-state-mode="drawerOptions.menuMode"
            :reveal-mode="drawerOptions.menuRevealMode"
            :min-size="drawerOptions.minMenuSize"
            :shading="drawerOptions.shaderEnabled"
            :close-on-outside-click="drawerOptions.closeOnOutsideClick"
        >
            <dx-scroll-view ref="scrollViewRef" class="with-footer">
                <slot/>
                <slot name="footer"/>
            </dx-scroll-view>
            <!-- eslint-disable vue/no-unused-vars -->
            <side-nav-menu
                slot="menu"
                slot-scope="_"
                :selected-item="$route.path"
                :compact-mode="!menuOpened"/>
                @click="handleSideBarClick"
            />
            <!-- eslint-enable -->
        </dx-drawer>
    </div>
</template>

<script>
import DxDrawer from "devextreme-vue/drawer";
import DxScrollView from "devextreme-vue/scroll-view";

import HeaderToolbar from "../components/HeaderToolbar";
import SideNavMenu from "../components/side-nav-menu";

export default {
    props: {
        title: String,
        isXSmall: Boolean,
        isLarge: Boolean
    },
    methods: {
        toggleMenu(e) {
            const pointerEvent = e.event;
            pointerEvent.stopPropagation();
            if (this.menuOpened) {
                this.menuTemporaryOpened = false;
            }
            this.menuOpened = !this.menuOpened;
        },
        handleSideBarClick() {
            if (this.menuOpened === false) this.menuTemporaryOpened = true;
            this.menuOpened = true;
        }
    },
    data() {
        return {
            menuOpened: this.isLarge,
            menuTemporaryOpened: false,
        };
    },
    computed: {
        drawerOptions() {
            const shaderEnabled = !this.isLarge;
            return {
                menuMode: this.isLarge ? "shrink" : "overlap",
                menuRevealMode: this.isXSmall ? "slide" : "expand",
                minMenuSize: 0,//this.isXSmall ? 0 : 60,
                menuOpened: this.isLarge,
                closeOnOutsideClick: shaderEnabled,
                shaderEnabled
            };
        },
        headerMenuTogglerEnabled() {
            return this.isXSmall;
        },
        scrollView() {
            return this.$refs["scrollViewRef"].instance;
        }
    },
    watch: {
        isLarge() {
            if (!this.menuTemporaryOpened) {
                this.menuOpened = this.isLarge;
            }
        },
        $route() {
            if (this.menuTemporaryOpened || !this.isLarge) {
                this.menuOpened = false;
                this.menuTemporaryOpened = false;
            }
            this.scrollView.scrollTo(0);
        }
    },
    components: {
        DxDrawer,
        DxScrollView,
        HeaderToolbar,
        SideNavMenu
    }
};
</script>

<style lang="scss">
@import "../fm-styles";
.side-nav-outer-toolbar {
    flex-direction: column;
    display: flex;
    height: 100%;
    width: 100%;
}

.layout-header {
    z-index: 1501;
    background-color: $FuelMastersBlue;
}

.layout-body {
    flex: 1;
    min-height: 0;
}

.content {
    flex-grow: 1;
}
</style>
