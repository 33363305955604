<template>
    <div>
        <header class="header-component py-0">
            <div class="flex-shrink-0 bg-light d-lg-none d-none d-lg-block d-xl-block py-0">
                <router-link to="home">
                    <img class="center-block flex-shrink-0 d-lg-none d-none d-lg-block d-xl-block py-0" style="height: 4rem; width: 25rem" src="SmartRacks.svg"/>
                </router-link>
            </div>
            <dx-toolbar class="header-toolbar">
                <dx-item
                        :visible="menuToggleEnabled"
                        location="before"
                        css-class="menu-button"
                >
                    <!-- eslint-disable vue/no-unused-vars -->
                    <dx-button
                            id="MenuButton"
                            icon="menu"
                            styling-mode="text"
                            @click="toggleMenuFunc"
                            slot-scope="_"
                    />
                    <!-- eslint-enable -->
                </dx-item>

                <dx-item
                        location="after"
                        locate-in-menu="auto"
                        menu-item-template="menuUserItem"
                >
                    <!-- eslint-disable vue/no-unused-vars -->
                    <div slot-scope="_">
                        <!-- eslint-enable -->
                        <dx-button v-if="Authenticated"
                                   class="user-button border-light"
                                   :text="UserInitials"
                                   :hover-state-enabled="false"
                                   :active-state-enabled="false"
                                   :focus-state-enabled="false"
                                   type="success"
                                   styling-mode="contained"
                                   :element-attr="{ class: 'avatar' }"
                        >
                            {{ UserInitials }}
                            <user-panel :menu-items.sync="userMenuItems" menu-mode="context"/>
                        </dx-button>
                        <router-link v-else :to="{name: 'login'}">
                            <dx-button
                                    :hover-state-enabled="false"
                                    :active-state-enabled="false"
                                    styling-mode="text"
                                    class="font-weight-bolder text-white"
                                    text="Login"
                            />
                        </router-link>
                    </div>
                </dx-item>
                <!-- eslint-disable vue/no-unused-vars -->
                <user-panel
                        :menu-items="userMenuItems"
                        menu-mode="list"
                        slot-scope="_"
                        slot="menuUserItem"
                />
                <!-- eslint-enable -->
            </dx-toolbar>

            <!--Change Customer Popup-->
            <DxPopup
                    :visible="showChangeCustomer"
                    :drag-enabled="false"
                    :close-on-outside-click="false"
                    :show-close-button="false"
                    :show-title="true"
                    :min-width="350"
                    :max-width="500"
                    :animation="{
                        show: { type: 'pop', duration: 100 },
                        hide: { type: 'pop', duration: 0 }
                    }"
                    shading-color="rgba(0,0,0,0.4)"
                    height="auto"
                    container=".dx-viewport"
                    title="Change Customer"
            >
                <DxToolbarItem widget="dxButton" toolbar="bottom" location="after" :options="changeCustomerButtonOptions"/>
                <DxToolbarItem widget="dxButton" toolbar="bottom" location="after" :options="closeChangeCustomerButtonOptions"/>

                <template #content>
                    <DxDropDownBox
                            ref="CustomerDropDownBoxRef"
                            :value="selectedCustomer.CustomerNo"
                            :data-source="customers"
                            :display-expr="customerDropDownBoxDisplayExpr"
                            value-expr="CustomerNo"
                            placeholder="Select Customer..."
                    >
                        <DxDropDownOptions width="100%"/>
                        <template #content="{ data }">
                            <DxDataGrid
                                    ref="CustomersDataGridRef"
                                    :data-source="customers"
                                    :hover-state-enabled="true"
                                    :selected-row-keys="[selectedCustomer]"
                                    :show-borders="true"
                                    :show-row-lines="true"
                                    :column-auto-width="true"
                                    key-expr="CustomerNo"
                                    height="100%"
                                    @selection-changed="onCustomerSelectionChanged($event)"
                            >
                                <DxColumn
                                        data-field="CustomerNo"
                                />
                                <DxColumn
                                        data-field="CustomerName"
                                />
                                <DxColumn
                                        alignment="center"
                                        data-field="Active"
                                        data-type="boolean"
                                        :width="75"
                                >
                                    <DxLookup
                                            :data-source="[ { text: 'Yes', value: true }, { text: 'No', value: false } ]"
                                            value-expr="value"
                                            display-expr="text"
                                    />
                                </DxColumn>
                                <DxSelection mode="single"/>
                                <DxPaging :enabled="true" :page-size="15"/>
                                <DxFilterRow :visible="true"/>
                                <DxScrolling mode="virtual"/>
                            </DxDataGrid>
                        </template>
                    </DxDropDownBox>
                </template>
            </DxPopup>

            <!--Change Bid Template Popup-->
            <DxPopup
                    :visible="showChangeBidTemplate"
                    :drag-enabled="false"
                    :close-on-outside-click="false"
                    :show-close-button="false"
                    :show-title="true"
                    :min-width="350"
                    :max-width="350"
                    :animation="{
                        show: { type: 'pop', duration: 100 },
                        hide: { type: 'pop', duration: 0 }
                    }"
                    shading-color="rgba(0,0,0,0.4)"
                    height="auto"
                    container=".dx-viewport"
                    title="Change Bid Template"
            >
                <DxToolbarItem widget="dxButton" toolbar="bottom" location="after" :options="changeBidTemplateButtonOptions"/>
                <DxToolbarItem widget="dxButton" toolbar="bottom" location="after" :options="closeChangeBidTemplateButtonOptions"/>

                <template #content>
                    <DxDropDownBox
                            ref="BidTemplateDropDownBoxRef"
                            :value="selectedBidTemplate && selectedBidTemplate.TemplateID"
                            :data-source="bidtemplates"
                            display-expr="Filename"
                            value-expr="TemplateID"
                            placeholder="Select Bid Template..."
                    >
                        <DxDropDownOptions width="100%"/>
                        <template #content="{ data }">
                            <DxDataGrid
                                    ref="BidTemplatesDataGridRef"
                                    :data-source="bidtemplates"
                                    :hover-state-enabled="true"
                                    :selected-row-keys="[selectedBidTemplate]"
                                    :show-borders="true"
                                    :show-row-lines="true"
                                    :column-auto-width="true"
                                    key-expr="TemplateID"
                                    height="100%"
                                    @selection-changed="onBidTemplateSelectionChanged($event)"
                            >
                                <DxSelection mode="single"/>
                                <DxPaging :enabled="true" :page-size="15"/>
                                <DxFilterRow :visible="true"/>
                                <DxScrolling mode="virtual"/>

                                <DxColumn
                                    data-field="Filename"
                                    caption="Template"
                                    sort-order="asc"
                                    :sort-index="0"
                                />
                            </DxDataGrid>
                        </template>
                    </DxDropDownBox>
                </template>
            </DxPopup>

            <!--Impersonate User Popup-->
            <DxPopup
                    :visible="showImpersonateUser"
                    :drag-enabled="false"
                    :close-on-outside-click="false"
                    :show-close-button="false"
                    :show-title="false"
                    :min-width="450"
                    :max-width="365"
                    :animation="{
                        show: { type: 'pop', duration: 100 },
                        hide: { type: 'pop', duration: 0 }
                    }"
                    shading-color="rgba(0,0,0,0.4)"
                    height="auto"
                    container=".dx-viewport"

            >
                <DxToolbarItem widget="dxButton" toolbar="bottom" location="before"
                               :options="{ text: 'Start Impersonation', disabled: this.Impersonating() || !this.selectedUser, onClick: impersonateUserClick }"/>
                <DxToolbarItem widget="dxButton" toolbar="bottom" location="center"
                               :options="{ text: 'Stop Impersonation', disabled: !this.Impersonating(), onClick: unpersonateUserClick }"/>
                <DxToolbarItem widget="dxButton" toolbar="bottom" location="after" :options="closeChangeUserButtonOptions"/>

                <template #content>
                    <div class="p-2">
                    <b-container class="p-0">
                        <b-row>
                            <b-col cols="10" class="px-0">
                                <DxDropDownBox
                                        ref="UserDropDownBoxRef"
                                        :value="selectedUser"
                                        :defer-rendering="false"
                                        :data-source="users"
                                        :display-expr="userDropDownBoxDisplayExpr"
                                        :drop-down-options="{ minWidth: 600 }"
                                        value-expr="ID"
                                        key-expr="ID"
                                        placeholder="Select User..."
                                >
                                    <DxDropDownOptions width="auto"/>
                                    <template #content="{ data }">
                                        <DxDataGrid
                                                ref="UsersDataGridRef"
                                                :data-source="users"
                                                :hover-state-enabled="true"
                                                :selected-row-keys="[selectedUser]"
                                                :show-borders="true"
                                                :show-row-lines="true"
                                                :column-auto-width="true"
                                                key-expr="ID"
                                                height="100%"
                                                @selection-changed="onUserSelectionChanged($event)"
                                        >
                                            <DxSelection mode="single"/>
                                            <DxPaging :enabled="true" :page-size="15"/>
                                            <DxFilterRow :visible="true"/>
                                            <DxScrolling mode="virtual"/>

                                            <DxColumn data-field="LastName" :sort-index="0" sort-order="asc"/>
                                            <DxColumn data-field="FirstName"/>
                                            <DxColumn data-field="Login" :calculate-display-value="(data) => { return data.Email ? data.Email : data.Phone }"/>
                                        </DxDataGrid>
                                    </template>
                                </DxDropDownBox>
                            </b-col>
                            <b-col class="px-0 text-right">
                                <DxButton
                                        type="normal"
                                        icon="refresh"
                                        @click="FetchImpersonationsUsers"
                                />
                            </b-col>
                        </b-row>
                    </b-container>
                    </div>
                </template>
            </DxPopup>
        </header>
        <header>
            <div class="px-2 py-1 d-flex flex-lg-row flex-sm-column flex-md-row justify-content-between align-middle" :class="[ Impersonating() ? 'impersonating' : 'unimpersonated' ]">
                <div class="fw-light">
                    <b>Company</b>: <span class="text-white text-uppercase font-weight-bold">{{ Customer.CustomerName }}</span>
                </div>
                <div v-if="Impersonating()" class="fw-light">
                    <b>Impersonating</b>: <span class="text-white text-uppercase font-weight-bold">{{ ImpersonatedUser().FullName }}</span>
                </div>
                <div v-if="BidTemplate" class="fw-light">
                    <b>Bid Template</b>: <span class="text-white text-uppercase font-weight-bold">{{ BidTemplate.Filename }}</span>
                </div>
                <div v-else class="fw-light">
                    <b>Bid Template</b>: <span class="text-white text-uppercase font-weight-bold">None</span>
                </div>
            </div>
        </header>
        <header>
            <div v-if="LockedDown" class="p-1 bg-danger text-center text-white font-weight-bold text-uppercase">{{ $store.getters['lockdown/message'] }}</div>
        </header>
    </div>
</template>

<script>
/* eslint-disable */

import DxButton from "devextreme-vue/button";
import DxToolbar, {DxItem} from "devextreme-vue/toolbar";
import {DxPopup, DxToolbarItem} from 'devextreme-vue/popup';
import {DxDropDownBox, DxDropDownOptions} from 'devextreme-vue/drop-down-box';
import {DxColumn, DxDataGrid, DxFilterRow, DxPaging, DxScrolling, DxSelection, DxLookup} from 'devextreme-vue/data-grid';

import UserPanel from "./user-panel";
import Avatar from 'vue-avatar'

import {API} from "@/API";
import strings from '@/utils/string-helpers'

export default {
    props: {
        menuToggleEnabled: Boolean,
        toggleMenuFunc: Function,
        logOutFunc: Function
    },

    components: {
        DxButton,
        DxToolbar,
        DxItem,
        UserPanel,
        Avatar,
        DxPopup,
        DxToolbarItem,
        DxDropDownBox,
        DxDropDownOptions,
        DxDataGrid,
        DxSelection,
        DxPaging,
        DxFilterRow,
        DxScrolling,
        DxColumn,
        DxLookup
    },

    data() {
        return {
            userMenuItems: [
                {
                    text: "Show Profile",
                    icon: "user",
                    onClick: this.onProfileClick
                },
                {
                    text: "Market Alert History",
                    icon: "far fa-clock",
                    visible: this.HasImpersonationRole() || this.Impersonating(),
                    onClick: this.onMarketHistoryClick
                },
                {
                    text: "Impersonate User",
                    icon: "fas fa-user-secret",
                    visible: this.HasImpersonationRole() || this.Impersonating(),
                    onClick: this.onChangeUserClick
                },
                {
                    text: () => { return `Change Customer (${this.Customers.length})` },
                    icon: "far fa-building",
                    disabled: () => { return this.Customers.length === 0},
                    onClick: this.onChangeCustomerClick
                },
                {
                    text: () => { return `Change Bid Template (${this.BidTemplates.length})` },
                    icon: "file",
                    disabled: () => { return this.BidTemplates.length === 0},
                    onClick: this.onChangeBidTemplateClick
                },
                {
                    text: "Change Password",
                    icon: "key",
                    onClick: this.onChangePasswordClick
                },
                {
                    text: "Logout User",
                    icon: "runner",
                    onClick: this.onLogoutClick
                }
            ],

            showChangeCustomer: false,
            showChangeBidTemplate: false,
            showImpersonateUser: false,

            selectedCustomer: null,
            selectedBidTemplate: null,
            selectedUser: null,

            customers: [],
            bidtemplates: [],
            users: [],

            changeCustomerButtonOptions: {
                text: 'Change',

                onClick: async () => {
                    this.CustomersDataGrid.clearFilter("row")

                    await this.$store.dispatch('customers/CHANGE_CUSTOMER', this.selectedCustomer).then(async () => {
                        this.$toast.open(`Customer changed to ${this.selectedCustomer.CustomerNo} - ${this.selectedCustomer.CustomerName}...`);
                        this.showChangeCustomer = false

                        this.$root.$emit('customer-changed', this.selectedCustomer)

                        await this.$store.dispatch('bidtemplates/GET_BIDTEMPLATES', this.selectedCustomer).then((bts) => {
                            this.bidtemplates = bts
                            this.selectedBidTemplate = bts.length ? bts[0] : null
                            this.$root.$emit('bid-template-changed', this.selectedBidTemplate)
                        })
                    })
                }
            },

            closeChangeCustomerButtonOptions: {
                text: 'Close',

                onClick: () => {
                    this.CustomersDataGrid.clearFilter("row")
                    this.showChangeCustomer = false;
                }
            },

            changeBidTemplateButtonOptions: {
                text: 'Change',

                onClick: async () => {
                    await this.$store.dispatch('bidtemplates/CHANGE_BIDTEMPLATE', this.selectedBidTemplate).then(() => {
                        this.$toast.open(`Bid Template changed to ${this.BidTemplate.Filename}...`);
                        this.$root.$emit('bid-template-changed', this.selectedBidTemplate)
                        this.showChangeBidTemplate = false
                    })
                }
            },

            closeChangeBidTemplateButtonOptions: {
                text: 'Close',

                onClick: () => {
                    this.showChangeBidTemplate = false;
                }
            },

            closeChangeUserButtonOptions: {
                text: 'Close',

                onClick: () => {
                    this.showImpersonateUser = false;
                }
            }
        };
    },

    async mounted() {
        this.customers = this.Customers
        this.bidtemplates = this.BidTemplates
        this.selectedCustomer = this.Customer
        this.selectedBidTemplate = this.BidTemplate
    },

    methods: {
        FetchImpersonationsUsers() {
            if(this.Customer && this.BidTemplate)
                API.get(`/admin/users/impersonations/${this.Customer.CustomerNo}/${this.BidTemplate.TemplateID}`)
                        .then(response => {
                            this.users = response.data
                        })
                        .catch(error => {
                            this.$toast.open(error.response.data.message)
                        })
        },

        onProfileClick() {
            this.$router.push('/profile')
        },

        onMarketHistoryClick() {
            this.$router.push('/alerts/markets/history')
        },

        onChangeCustomerClick() {
            this.showChangeCustomer = true
        },

        onChangeBidTemplateClick() {
            if(this.BidTemplate)
                this.showChangeBidTemplate = true
            else
                this.$toast.warning(`No Bid Templates for ${this.selectedCustomer.CustomerNo} - ${this.selectedCustomer.CustomerName}...`);
        },

        onLogoutClick() {
            this.$store.dispatch('impersonate/unpersonate').then(() => {
                this.$store.dispatch('user/logout').then(() => {
                    this.$gates.setPermissions([])
                    this.$gates.setRoles([])
                    this.$root.$emit('user-logout')
                    this.$router.push('/login')
                })
            })
        },

        onChangePasswordClick() {
            this.$router.push('/user/password/change')
        },

        onCustomerSelectionChanged({selectedRowsData}) {
            if(selectedRowsData.length) {
                this.selectedCustomer = selectedRowsData[0]

                this.CustomerDropDownBox.close()
            }
        },

        onBidTemplateSelectionChanged({selectedRowsData}) {
            if(selectedRowsData.length) {
                this.selectedBidTemplate = selectedRowsData[0]

                this.BidTemplateDropDownBox.close()
            }
        },

        onUserSelectionChanged({selectedRowKeys}) {
            if(selectedRowKeys)
                this.selectedUser = selectedRowKeys[0]
            this.UserDropDownBox.close()
        },

        userDropDownBoxDisplayExpr(item) {
            return item && `${item.FullName}`;
        },

        customerDropDownBoxDisplayExpr(item) {
            return item && `${item.CustomerNo} - ${item.CustomerName}`;
        },

        onChangeUserClick() {
            this.FetchImpersonationsUsers()
            this.showImpersonateUser = true
        },

        impersonateUserClick() {
            const user = this.UsersDataGrid.getSelectedRowsData()[0]

            this.$store.dispatch('impersonate/impersonate', user).then(() => {
                this.$store.dispatch('customers/GET_CUSTOMERS').then(() =>{
                    this.$toast.open(`User changed to ${strings.namify([user.FirstName, user.LastName])}...`);

                    this.customers = this.Customers
                    this.selectedCustomer =  this.customers.length ? this.customers[0] : null
                    this.$store.dispatch('customers/CHANGE_CUSTOMER', this.selectedCustomer).then(() => {
                        this.$toast.open(`Customer changed to ${this.selectedCustomer.CustomerNo} - ${this.selectedCustomer.CustomerName}...`);
                        this.showChangeCustomer = false

                        this.$root.$emit('customer-changed', this.selectedCustomer)

                        this.$store.dispatch('bidtemplates/GET_BIDTEMPLATES', this.selectedCustomer).then((bts) => {
                            this.bidtemplates = bts
                            this.selectedBidTemplate = bts.length ? bts[0] : null
                            this.$root.$emit('bid-template-changed', this.selectedBidTemplate)
                            this.$toast.open(`Bid Template changed to ${this.BidTemplate.Filename}...`);
                        })
                    })

                    this.$root.$emit('user-impersonated', user)
                    this.showImpersonateUser = false

                    this.$router.push('/home')
                })
            })
        },

        unpersonateUserClick() {
            const user = this.ImpersonatedUser

            this.$store.dispatch('impersonate/unpersonate').then(() => {
                this.$store.dispatch('customers/GET_CUSTOMERS').then(() =>{
                    this.selectedCustomer = this.Customer
                    this.customers = this.Customers
                    this.$toast.open(`No longer impersonating ${strings.namify([user.FirstName, user.LastName])}...`);
                    this.$root.$emit('user-unpersonated', user)
                    this.showImpersonateUser = false
                    this.selectedUser = null

                    this.$router.push('/home')
                })
            })
        },

        ImpersonatedUser() {
            return this.$store.getters['impersonate/user']
        },

        Impersonating() {
            return this.$store.getters['impersonate/impersonating']
        },

        HasImpersonationRole() {
            return this.$store.getters['user/roles'].some(r => 'Super Administrator|Administrator|Account Manager'.split('|').includes(r))
        }
    },

    computed: {
        Authenticated() {
            return this.$store.getters['user/authenticated']
        },

        User() {
            return this.$store.getters['user/user']
        },

        UserInitials() {
            return this.Impersonating() ? this.$store.getters['impersonate/user'].Initials : this.$store.getters['user/initials']
        },

        Customer() {
            return this.$store.getters['customers/customer']
        },

        Customers() {
            return this.$store.getters['customers/customers']
        },

        BidTemplate() {
            return this.$store.getters['bidtemplates/bidtemplate']
        },

        BidTemplates() {
            return this.$store.getters['bidtemplates/bidtemplates']
        },

        LockedDown() {
            return this.$store.getters['lockdown/islockeddown']
        },

        CustomerDropDownBox() {
            return this.$refs.CustomerDropDownBoxRef.instance
        },

        BidTemplateDropDownBox() {
            return this.$refs.BidTemplateDropDownBoxRef.instance
        },

        CustomersDataGrid() {
            return this.$refs.CustomersDataGridRef.instance
        },

        BidTemplatesDataGrid() {
            return this.$refs.BidTemplatesDataGridRef.instance
        },

        UserDropDownBox() {
            return this.$refs.UserDropDownBoxRef.instance
        },

        UsersDataGrid() {
            return this.$refs.UsersDataGridRef.instance
        }
    },
};
</script>

<style lang="scss" scoped>
@import "../themes/generated/variables.base.scss";
@import "../dx-styles.scss";
@import "../fm-styles";
.center-block {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

a:link {
    text-decoration: none;
    color: white;
}

a:visited {
    text-decoration: none;
    color: white;
}

a:hover {
    text-decoration: none;
    color: white;
}

a:active {
    text-decoration: none;
    color: white;
}

.header-component {
    flex: 0 0 auto;
    z-index: 3;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

    .dx-toolbar .dx-toolbar-item.menu-button > .dx-toolbar-item-content .dx-icon {
        color: $FuelMastersWhite !important;
    }
}

.dx-toolbar.header-toolbar .dx-toolbar-items-container .dx-toolbar-after {
    padding: 0 40px;

    .screen-x-small & {
        padding: 0 20px;
    }
}

.dx-toolbar .dx-toolbar-item.dx-toolbar-button.menu-button {
    width: $side-panel-min-width;
    text-align: center;
    padding: 0;
    color: white !important;
}

.header-title .dx-item-content {
    padding: 0;
    margin: 0;
}

::v-deep .dx-toolbar .dx-toolbar-item {
    max-width: 600px !important;
}

.dx-theme-generic {
    .dx-toolbar {
        padding: 15px;
        background-color: $FuelMastersBlue;
        color: $FuelMastersWhite !important;
        text-transform: uppercase;
    }

    .user-button > .dx-button-content {
        padding: 0px !important;
    }
}

::v-deep .dx-button-has-text .dx-button-content {
    display: inline-flex;
    align-items: center;
    vertical-align: center;
    padding: 6px !important;
}

::v-deep #MenuButton .dx-icon {
    color: white !important;
}

::v-deep #MenuButton {
    background-color: $FuelMastersAccentRed;
    border-color: white;
}

::v-deep #MenuButton.dx-state-hover {
    background-color: lighten($FuelMastersAccentRed, 10);
    border-color: white !important;
}

::v-deep #MenuButton.dx-state-active {
    background-color: lighten($FuelMastersAccentRed, 5);
    border-color: black !important;
}

::v-deep .avatar {
    background-color: #AC2822;
    border-radius: 50%;
    width: 30px !important;
    height: 30px !important;
}

::v-deep .impersonating {
    background-color: #FF7164FF;
}

::v-deep .unimpersonated {
    background-color: #69B0AEFF
}
</style>
