import Vue from "vue";
import Router from "vue-router";

import HomeView from './views/HomeView';
import Error from "./views/ErrorView";

import defaultLayout from "./layouts/side-nav-outer-toolbar";
import simpleLayout from "./layouts/single-card";
import fullLayout from "./layouts/simple-container";
import titlebarLayout from "./layouts/title-bar-layout";

import store from "@/stores/store";
import CustomerPricingCurrentView from "./views/Pricing/CustomerPricingCurrentView";
import CustomerPricingLiveLoadingView from "./views/Pricing/CustomerPricingLiveLoadingView";
import CustomerPricingHistoryView from "./views/Pricing/CustomerPricingHistoryView";
import ProfileView from "./views/ProfileView";
import UserManagementView from "@/views/Admin/UserManagementView";
import UserActivityView from "@/views/Admin/UserActivityView";
import CreditProfileView from "@/views/Credit/CreditProfileView";
import MarketProductsAdminView from "@/views/Admin/MarketProductsAdminView";
import UserProfileTemplatesView from "@/views/Admin/UserProfileTemplatesView";
import CustomerAdministratorView from "@/views/Admin/CustomerAdministratorView";
import UserMarketAlertHistoryView from "@/views/UserMarketAlertHistoryView";
import MessagesView from "@/views/Communications/MessagesView";
import UploadedDocumentsView from "@/views/Communications/UploadedDocumentsView";
import ProductColorsView from "@/views/Admin/ProductColorsView.vue";
import OutagesView from "@/views/Server/OutagesView.vue";
import MarketsView from "@/views/Server/MarketsView.vue";
import MobileDashboardView from "@/views/Admin/MobileDashboardView.vue";
import ServerUtilitiesView from "@/views/Admin/ServerUtilitiesView.vue";

Vue.use(Router);

const router = new Router({
	history: true,
	routes: [
		{
			path: "*",
			redirect: "/"
		},
		{
			path: "/",
			meta: {
				requiresAuth: true,
				requiredRoles: ['Account Manager', 'Administrator', 'Credit Manager', 'Customer Buyer', 'Customer Administrator'],
				requiredPermissions: []
			},
			components: {
				layout: defaultLayout,
				content: HomeView
			}
		},
		{
			path: "/home",
			meta: {
				requiresAuth: true,
				requiredRoles: ['Account Manager', 'Administrator', 'Credit Manager', 'Customer Buyer', 'Customer Administrator'],
				requiredPermissions: []
			},
			components: {
				layout: defaultLayout,
				content: HomeView
			}
		},
		
// COMMUNICATIONS
		{
			path: "/documents/uploaded",
			name: 'uploaded-documents',
			meta: {
				requiresAuth: true,
				requiredRoles: ['Account Manager', 'Administrator', 'Credit Manager', 'Customer Buyer', 'Customer Administrator'],
				requiredPermissions: []
			},
			components: {
				layout: defaultLayout,
				content: UploadedDocumentsView
			},
		},
		{
			path: "/messages",
			name: 'support-messages',
			meta: {
				requiresAuth: true,
				requiredRoles: ['Administrator'],
				requiredPermissions: []
			},
			components: {
				layout: defaultLayout,
				content: MessagesView
			},
		},

// ADMIN
		{
			path: "/admin/server/utilities",
			name: 'server-utilities',
			meta: {
				requiresAuth: true,
				requiredRoles: ['Administrator'],
				requiredPermissions: []
			},
			components: {
				layout: defaultLayout,
				content: ServerUtilitiesView
			},
		},
		{
			path: "/admin/users/management",
			name: 'user-management',
			meta: {
				requiresAuth: true,
				requiredRoles: ['Administrator'],
				requiredPermissions: []
			},
			components: {
				layout: defaultLayout,
				content: UserManagementView
			},
		},
		{
			path: "/admin/users/customer/management",
			name: 'customer-user-management',
			meta: {
				requiresAuth: true,
				requiredRoles: ['Administrator', 'Customer Administrator'],
				requiredPermissions: []
			},
			components: {
				layout: defaultLayout,
				content: CustomerAdministratorView
			},
		},
		{
			path: "/admin/mobile/dashboard",
			name: 'mobile-dashboard',
			meta: {
				requiresAuth: true,
				requiredRoles: ['Administrator'],
				requiredPermissions: []
			},
			components: {
				layout: defaultLayout,
				content: MobileDashboardView
			},
		},
		{
			path: "/admin/users/activity",
			name: 'user-activity',
			meta: {
				requiresAuth: true,
				requiredRoles: ['Administrator'],
				requiredPermissions: []
			},
			components: {
				layout: defaultLayout,
				content: UserActivityView
			},
		},
		{
			path: "/admin/markets-products/management",
			name: 'markets-products-admin',
			meta: {
				requiresAuth: true,
				requiredRoles: ['Administrator'],
				requiredPermissions: []
			},
			components: {
				layout: defaultLayout,
				content: MarketProductsAdminView
			},
		},
		{
			path: "/admin/products/management",
			name: 'products-admin',
			meta: {
				requiresAuth: true,
				requiredRoles: ['Administrator'],
				requiredPermissions: []
			},
			components: {
				layout: defaultLayout,
				content: ProductColorsView
			},
		},
		{
			path: "/admin/users/profile/templates",
			name: 'user-profile-templates',
			meta: {
				requiresAuth: true,
				requiredRoles: ['Administrator'],
				requiredPermissions: []
			},
			components: {
				layout: defaultLayout,
				content: UserProfileTemplatesView
			},
		},
		
// SERVER
		{
			path: "/admin/server/markets",
			name: 'current-markets',
			meta: {
				requiresAuth: true,
				requiredRoles: ['Account Manager', 'Administrator'],
				requiredPermissions: []
			},
			components: {
				layout: defaultLayout,
				content: MarketsView
			},
		},
		{
			path: "/admin/server/outages",
			name: 'current-outages',
			meta: {
				requiresAuth: true,
				requiredRoles: ['Account Manager', 'Administrator'],
				requiredPermissions: []
			},
			components: {
				layout: defaultLayout,
				content: OutagesView
			},
		},
		
// CREDIT
		{
			path: "/creditprofile",
			name: 'credit-profile',
			meta: {
				requiresAuth: true,
				requiredRoles: ['Account Manager', 'Administrator', 'Credit Manager', 'Customer Buyer'],
				requiredPermissions: []
			},
			components: {
				layout: defaultLayout,
				content: CreditProfileView
			}
		},
		
// PRICING
		{
			path: "/customer/pricing/current",
			name: 'customer-pricing-current',
			meta: {
				requiresAuth: true,
				requiredRoles: ['Account Manager', 'Administrator', 'Customer Buyer', 'Prospect', 'Customer Administrator'],
				requiredPermissions: []
			},
			components: {
				layout: defaultLayout,
				content: CustomerPricingCurrentView
			}
		},
		{
			path: "/customer/pricing/liveloading",
			name: 'customer-pricing-liveloading',
			meta: {
				requiresAuth: true,
				requiredRoles: ['Account Manager', 'Administrator', 'Customer Driver', 'Customer Buyer', 'Prospect', 'Customer Administrator'],
				requiredPermissions: []
			},
			components: {
				layout: defaultLayout,
				content: CustomerPricingLiveLoadingView
			}
		},
		{
			path: "/customer/pricing/history",
			name: 'customer-pricing-history',
			meta: {
				requiresAuth: true,
				requiredRoles: ['Account Manager', 'Administrator', 'Customer Buyer', 'Prospect', 'Customer Administrator'],
				requiredPermissions: []
			},
			components: {
				layout: defaultLayout,
				content: CustomerPricingHistoryView
			}
		},
		
// USER MENU
		{
			path: "/profile",
			name: "profile",
			meta: {
				requiresAuth: true,
				requiredRoles: ['Administrator', 'Account Manager', 'Credit Manager', 'Customer Buyer', 'Customer Driver', 'Prospect', 'Customer Administrator'],
				requiredPermissions: []
			},
			components: {
				layout: defaultLayout,
				content: ProfileView
			}
		},
		{
			path: "/alerts/markets/history",
			name: "user-market-alert-history",
			meta: {
				requiresAuth: true,
				requiredRoles: ['Administrator', 'Account Manager', 'Credit Manager', 'Customer Buyer', 'Customer Driver', 'Prospect', 'Customer Administrator'],
				requiredPermissions: []
			},
			components: {
				layout: defaultLayout,
				content: UserMarketAlertHistoryView
			}
		},
		
// HOME WIDGETS
		{
			path: "/dtn/chart",
			name: "dtn-chart",
			meta: {
				requiresAuth: true,
				requiredRoles: ['Administrator', 'User'],
				requiredPermissions: []
			},
			components: {
				layout: fullLayout,
				content: () =>
					import(/* webpackChunkName: "dtn-chart" */ "./components/DTNSnapshotChart")
			}
		},
		
// AUTH
		{
			path: "/login",
			name: "login",
			meta: {
				requiresAuth: false,
				requiredRoles: [],
				requiredPermissions: []
			},
			props: (route) => ({
				product: route.params
			}),
			
			components: {
				layout: simpleLayout,
				content: () =>
					import(/* webpackChunkName: "login" */ "./views/Auth/LoginView")
			}
		},
		{
			path: "/user/password/change",
			name: "change-user-password",
			meta: {
				requiresAuth: true,
				requiredRoles: ['Administrator', 'User'],
				requiredPermissions: []
			},
			components: {
				layout: simpleLayout,
				content: () =>
					import(/* webpackChunkName: "changepassword" */ "./views/Auth/ChangePasswordView")
			},
			props: {
				layout: {
					title: "Change Password"
				}
			}
		},
		{
			path: "/user/password/forgot",
			name: "forgot-password",
			meta: {
				requiresAuth: false,
			},
			components: {
				layout: simpleLayout,
				content: () =>
					import(/* webpackChunkName: "forgotpassword" */ "./views/Auth/ForgotPasswordView")
			},
			props: {
				layout: {
					title: "Forgot Password"
				}
			}
		},

// ENROLLMENT
		{
			path: "/enroll/:token",
			name: "enroll",
			meta: {
				requiresAuth: false,
				requiredRoles: [],
				requiredPermissions: []
			},
			components: {
				layout: simpleLayout,
				content: () => import(/* webpackChunkName: "enroll" */ "./views/Auth/EnrollView")
			},
			props: true
		},
		
		{
			path: "/enroll-success",
			name: "enroll-success",
			meta: {
				requiresAuth: false,
				requiredRoles: [],
				requiredPermissions: []
			},
			components: {
				layout: simpleLayout,
				content: () => import(/* webpackChunkName: "enroll-success" */ "./views/Auth/EnrollSuccessfulView.vue")
			},
			props: true
		},
		
// REQUESTS
		{
			path: "/requests/credit/:customer_no/:csrf_token",
			name: "credit-request",
			meta: {
				requiresAuth: false,
				requiredRoles: [],
				requiredPermissions: []
			},
			components: {
				layout: simpleLayout,
				content: () => import(/* webpackChunkName: "enroll" */ "./views/Requests/CreditRequestView")
			},
			props: true
		},
		
// MISC
		{
			path: "/error",
			name: "error",
			meta: {
				requiresAuth: false,
				requiredRoles: [],
				requiredPermissions: []
			},
			
			components: {
				layout: titlebarLayout,
				content: () =>
					import(/* webpackChunkName: "error" */ "./views/ErrorView")
			}
		},
	]
});

router.beforeResolve((to, from, next) => {
	const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
	const authenticated = store.getters['user/authenticated']
	
	if (requiresAuth && !authenticated) {
		next('/login')
	} else if (to.path === '/login' && authenticated) {
		next('/home')
	} else {
		next();
	}
});

router.beforeEach((to, from, next) => {
	const authenticated = store.getters['user/authenticated']
	
	if (authenticated)
		store.dispatch('lockdown/CHECK')
	next()
})

export default router;
