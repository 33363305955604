<template>
    <div>
        <DxDataGrid
                id="gridContainer"
                ref="UserManagementGridRef"
                no-data-text="No User Records..."
                key-expr="ID"
                class="p-2"
                style="font-size: .75rem;"
                width="100%"
                :data-source="users"
                :selection="{ mode: 'single' }"
                :hover-state-enabled="true"
                :show-borders="true"
                :show-row-lines="true"
                :column-auto-width="true"
                :repaint-changes-only="false"
                :two-way-binding-enabled="false"
                :column-hiding-enabled="false"
                @selection-changed="onSelectionChanged"
                @cell-hover-changed="onCellHoverChanged"
        >
            <DxHeaderFilter :visible="true"/>
            <DxFilterRow :visible="true"/>
            <DxSorting show-sort-indexes/>
            <DxPaging :page-size="10"/>
            <DxPager :show-info="true"/>

            <DxToolbar>
                <DxItem location="before" template="refreshTemplate"/>
                <DxItem location="after" template="exportTemplate"/>
            </DxToolbar>

            <DxColumn
                data-field="FullName"
                caption="Name"
                :sort-index="0"
                sort-order="asc"
                :allow-header-filtering="false"
                css-class="vertical-align-cell"
            />
            <DxColumn
                data-field="Email"
                caption="E-Mail"
                :allow-header-filtering="false"
                css-class="vertical-align-cell"
            />
            <DxColumn
                data-field="EnrolledUser.Enrolled"
                caption="Enroll Date"
                data-type="date"
                :width="100"
                :allow-header-filtering="false"
                :allow-filtering="false"
                :calculate-display-value="CalculateEnrolledDateCellValue"
            />
            <DxColumn
                data-field="EnrolledUser.Created"
                caption="Invite Date"
                data-type="date"
                :width="100"
                :allow-header-filtering="false"
                :allow-filtering="false"
                :calculate-display-value="CalculateInviteDateCellValue"
            />
            <DxColumn
                    data-field="LastAuthentication"
                    data-type="datetime"
                    :width="150"
                    :allow-header-filtering="false"
                    :allow-filtering="false"
                    :calculate-display-value="CalculateLastAuthenticationCellValue"
            />
            <DxColumn
                data-field="Customer.CustomerName"
                caption="Company(s)"
                width="40%"
                :allow-header-filtering="true"
                :calculate-display-value="CalculateBidTemplateContactsDisplayValue"
                :calculate-cell-value="CalculateCustomersCellValue"
                :calculate-filter-expression="(filterValue, selectedFilterOperation) => { return [this.CalculateCustomersCellValue, 'startswith', filterValue] }"
                :header-filter="{height: 'auto', allowSearch: true, position: { my: 'center', at: 'center'} }"
                :lookup="{dataSource: Customers, keyExpr: 'CustomerNo', valueExpr: 'CustomerNo', displayExpr: 'CustomerName'}"
            />
            <DxColumn
                data-field="Roles"
                width="35%"
                :allow-header-filtering="false"
                :allow-filtering="false"
                cell-template="role-selector"
                css-class="vertical-align-cell test"
            />
            <DxColumn
                cell-template="row-operations"
                :width="100"
            />

            <template #role-selector="{ data }">
                <DxTagBox
                        :items="roles"
                        :value="data.data.Roles"
                        :show-selection-controls="true"
                        @value-changed="Save"
                />
            </template>

            <template #row-operations="{ data }">
                <div class="text-center">
                    <DxButton
                            type="danger"
                            icon="fas fa-trash"
                            styling-mode="contained"
                            hint="Delete this user"
                            class="m-1"
                            @click="DeleteUser(data.data)"
                    />
                    <DxButton
                            :type="data.data.IsActive ? 'success' : 'danger'"
                            :icon="data.data.IsActive ? 'fas fa-lock-open' : 'fas fa-lock'"
                            :hint="data.data.IsActive ? 'Lock this user' : 'Unlock this user'"
                            styling-mode="contained"
                            class="m-1"
                            @click="ToggleUser(data.data)"
                    />
                </div>
            </template>

            <template #refreshTemplate>
                <DxButton icon="refresh" hint="Refresh the User List." @click="Fetch"/>
            </template>

            <template #exportTemplate>
                <DxButton icon="far fa-file-excel" type="success" hint="Export the user list" @click="Export"/>
            </template>
        </DxDataGrid>
    </div>
</template>

<script>
import {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxPager,
    DxHeaderFilter,
    DxSorting,
    DxFilterRow,
    DxToolbar,
    DxItem,
} from 'devextreme-vue/data-grid';
import { DxButton } from "devextreme-vue/button";
import {DxTagBox} from 'devextreme-vue/tag-box'
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';

import {API} from "@/API";

import roles from "../../roles"
import moment from "moment/moment";
import {confirm} from "devextreme/ui/dialog";
import saveAs from 'file-saver';

export default {
    name: "user-management-grid",

    components: {
        DxDataGrid,
        DxPaging,
        DxPager,
        DxHeaderFilter,
        DxSorting,
        DxColumn,
        DxFilterRow,
        DxTagBox,
        DxButton,
        DxToolbar,
        DxItem,
    },

    data() {
        return {
            users: [],
            selectedUser: null,
            roles,
        }
    },

    mounted() {
        this.Fetch()
    },

    methods: {
        Fetch() {
            if (this.Authenticated) {
                API.get('/admin/users')
                        .then(response => {
                            this.users = response.data
                            this.UserManagementGrid.repaint()
                            this.UserManagementGrid.updateDimensions()
                        })
                        .catch(error => {
                            this.$toast.open({
                                message: error.response.data.message,
                                type: 'error',
                            });
                        })
            } else {
                this.$router.push('/login')
            }
        },

        Save(e) {
            if (this.Authenticated) {
                if (this.selectedUser) {
                    API.put(`/admin/users/${this.selectedUser.ID}`, {roles: e.value})
                            .then(response => {

                            })
                            .catch(error => {
                                this.$toast.open({
                                    message: error.response.data.message,
                                    type: 'error',
                                });
                            })
                }
            } else {
                this.$router.push('/login')
            }
        },

        onSelectionChanged({selectedRowsData}) {
            this.selectedUser = selectedRowsData[0];
        },

        onCellHoverChanged(e) {
            if (e.rowType === 'data') {
                this.UserManagementGrid.selectRowsByIndexes([e.rowIndex])
            }
        },

        CalculateEnrolledDateCellValue(rowData) {
            return rowData.EnrolledUser ? moment(rowData.EnrolledUser.Enrolled).format('MM/DD/YYYY') : 'Not Enrolled'
        },

        CalculateInviteDateCellValue(rowData) {
            return rowData.EnrolledUser ? moment(rowData.EnrolledUser.Created).format('MM/DD/YYYY') : 'No Invite Date'
        },

        CalculateBidTemplateContactsDisplayValue: (rowData) => { return rowData.Customers.length ? rowData.Customers.map(c => c.CustomerName).join(', ') : 'None' },

        CalculateCustomersCellValue: (rowData) => { return rowData.Customers.length ? rowData.Customers.map(c => c.CustomerNo).join(' / ') : null},

        CalculateLastAuthenticationCellValue(rowData) {
            return !rowData.LastAuthentication ? 'Not Logged In' : moment(rowData.LastAuthentication).format('MM/DD/YYYY hh:mm A')
        },

        DeleteUser(e) {
            let result = confirm("Are you sure?", `Confirm`);

            result.then(async (dialogResult) => {
                if (dialogResult) {
                    await this.$store.dispatch('loading/message', "Deleting User...")

                    API.post(`/admin/users/delete/${e.ID}`)
                            .then(response => {
                                this.$toast.open({
                                    message: 'User Deleted...',
                                    type: 'success',
                                });

                                this.Fetch()
                            })
                }
            })
        },

        ToggleUser(e) {
            let result = confirm("Are you sure?", `Confirm`);

            result.then(async (dialogResult) => {
                if (dialogResult) {
                    await this.$store.dispatch('loading/message', "Toggling User...")

                    API.post(`/admin/users/toggle/${e.ID}`)
                            .then(response => {
                                this.$toast.open({
                                    message: 'User Status Changed...',
                                    type: 'success',
                                });

                                this.Fetch()
                            })
                }
            })
        },

        Export(e) {
            const workbook = new Workbook();
            const worksheet = workbook.addWorksheet('Sheet 1');

            exportDataGrid({
                component: this.UserManagementGrid,
                worksheet: worksheet
            }).then(function() {
                workbook.xlsx.writeBuffer()
                        .then(function(buffer) {
                            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
                        });
            });
        }
    },

    computed: {
        Authenticated() {
            return this.$store.getters["user/authenticated"];
        },

        UserManagementGrid() {
            return this.$refs.UserManagementGridRef.instance
        },

        Customers() {
            return this.$store.getters["customers/customers"];
        }
    }
}
</script>

<style scoped>
::v-deep .dx-datagrid-content .dx-datagrid-table .dx-row > td, .dx-datagrid-content .dx-datagrid-table .dx-row > tr > td {
    vertical-align: middle !important;

}

::v-deep .dx-tag-content {
    font-size: small !important;
}

::v-deep .test {
    padding: 0.20rem !important;
}

::v-deep .dx-toolbar {
    background-color: #f8f9fa !important;
}

::v-deep .dx-datagrid-header-panel {
    background-color: #f8f9fa !important;
}
</style>
