<template>
    <single-page :requires-auth="true">
        <b-container fluid class="p-2">
            <b-row class="d-flex">
                <b-col sm="12" md="8" lg="12" xl="12">
                    <b-card-group deck>
                        <b-card bg-variant="light" header="Uploaded Documents" class="shadow-sm font-weight-bold" style="font-size: 18px;">
                            <b-card-text>
                                <UploadedDocumentsGrid/>
                            </b-card-text>
                        </b-card>
                    </b-card-group>
                </b-col>
            </b-row>
        </b-container>
    </single-page>
</template>

<script>
import SinglePage from "@/components/SinglePage";
import UploadedDocumentsGrid from "@/components/Communications/UploadedDocumentsGrid";

export default {
    name: "user-document-listing-view",

    components: {
        UploadedDocumentsGrid,
        SinglePage,
    }
}
</script>

<style scoped>

</style>
