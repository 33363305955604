<template>
    <single-page :requires-auth="true">
        <b-container fluid>
            <b-row class="p-2 d-flex">
                <b-col xs="12" sm="12" md="12" lg="12" xl="12" class="px-0">
                    <b-card-group deck>
                        <b-card bg-variant="light" :header="Title" class="text-center shadow-sm font-weight-bold">
                            <b-card-text>
                                <DxDataGrid
                                        id="gridContainer"
                                        ref="CustomerUserManagementGridRef"
                                        no-data-text="No User Records..."
                                        key-expr="User.ID"
                                        class="p-2"
                                        style="font-size: .75rem;"
                                        width="100%"
                                        :data-source="users"
                                        :selection="{ mode: 'single' }"
                                        :hover-state-enabled="false"
                                        :show-borders="true"
                                        :show-row-lines="true"
                                        :two-way-binding-enabled="false"
                                        :column-hiding-enabled="false"
                                        :column-auto-width="true"
                                        @selection-changed="onSelectionChanged"
                                        @context-menu-preparing="onContextMenuPreparing">
                                >
                                    <DxHeaderFilter :visible="true"/>
                                    <DxFilterRow :visible="true"/>
                                    <DxSorting show-sort-indexes/>
                                    <DxPaging :page-size="25"/>
                                    <DxPager :show-info="true"/>

                                    <DxToolbar>
                                        <DxItem location="before" template="addTemplate"/>
                                        <DxItem location="before" template="removeTemplate"/>
                                        <DxItem location="before" template="userTypesTemplate"/>
                                        <DxItem location="center" template="syncTemplate"/>
                                        <DxItem location="after" template="refreshTemplate"/>
                                    </DxToolbar>

                                    <template #addTemplate>
                                        <DxButton icon="add" hint="Add New User" @click="ShowAddUser"/>
                                    </template>
                                    <template #removeTemplate>
                                        <DxButton icon="minus" hint="Remove a User" :disabled="!selectedUser" @click="DeleteUser"/>
                                    </template>
                                    <template #userTypesTemplate>
                                        <div>
                                            <div class="d-inline-block">Filter:&nbsp;</div>
                                            <DxSelectBox
                                                    :data-source="UserTypes"
                                                    :value="0"
                                                    :hoverStateEnabled="false"
                                                    :focusStateEnabled="false"
                                                    :activeStateEnabled="false"
                                                    placeholder="Select Filter..."
                                                    display-expr="Text"
                                                    value-expr="ID"
                                                    class="d-inline-block"
                                                    @value-changed="onUsersGridFilterValueChanged"
                                            />
                                        </div>
                                    </template>
                                    <template #syncTemplate>
                                        <DxButton icon="fa fa-solid fa-database" hint="Sync with MAS (Super Administrator Only)" :visible="IsSuperAdministrator" @click="SyncToMas"/>
                                    </template>
                                    <template #refreshTemplate>
                                        <DxButton icon="refresh" hint="Refresh User List" @click="Refresh"/>
                                    </template>

                                    <DxColumn
                                            cell-template="active-cell-template"
                                            :header-filter="{height: 'auto', allowSearch: true, position: { my: 'center', at: 'center' } }"
                                            :allow-header-filtering="false"
                                            :allow-sorting="false"
                                            :width="35"
                                    />
                                    <DxColumn
                                            data-field="User.Email"
                                            caption="E-Mail Address"
                                            :allow-header-filtering="false"
                                    />
                                    <DxColumn
                                            data-field="User.Phone"
                                            caption="Phone"
                                            :width="120"
                                            :calculate-display-value="FormatPhone"
                                            :allow-header-filtering="false"
                                    />
                                    <DxColumn
                                            data-field="User.FirstName"
                                            caption="First Name"
                                            :allow-header-filtering="false"
                                    />
                                    <DxColumn
                                            data-field="User.LastName"
                                            caption="Last Name"
                                            :allow-header-filtering="false"
                                    />
                                    <DxColumn
                                            data-field="User.EnrolledUser.Created"
                                            caption="Invite Date"
                                            data-type="date"
                                            format="MM/dd/yyyy"
                                            :width="100"
                                    />
                                    <DxColumn
                                            data-field="User.EnrolledUser.Enrolled"
                                            caption="Enroll Date"
                                            data-type="date"
                                            :width="100"
                                            :calculate-display-value="CalculateEnrolledDateCellValue"
                                    />
                                    <DxColumn
                                            data-field="LastAuthentication"
                                            data-type="datetime"
                                            :width="150"
                                            :calculate-display-value="CalculateLastAuthenticationCellValue"
                                    />
                                    <DxColumn
                                            data-field="BidTemplateContacts.BidTemplate.Filename"
                                            caption="Bid Template"
                                            :allow-header-filtering="true"
                                            :allow-filtering="false"
                                            :calculate-display-value="CalculateBidTemplateContactsDisplayValue"
                                            :calculate-cell-value="CalculateBidTemplateContactsCellValue"
                                            :header-filter="{height: 'auto', allowSearch: true, position: { my: 'center', at: 'center'} }"
                                            :lookup="{dataSource: bidtemplates, displayExpr: 'Filename', valueExpr: 'TemplateID'}"
                                    />
                                    <DxColumn
                                            data-field="Role"
                                            caption="Role(s)"
                                            :width="225"
                                            :allow-header-filtering="true"
                                            :allow-filtering="false"
                                            :calculate-display-value="CalculateRolesCellValue"
                                            :calculate-cell-value="CalculateRolesCellValue"
                                            :header-filter="{height: 'auto', allowSearch: true, position: { my: 'center', at: 'center'} }"
                                            :lookup="{dataSource: roles}"
                                    />

                                    <DxColumn data-field="BidTemplateContact.IsUploaded"
                                              caption="Uploaded"
                                              data-type="boolean"
                                              false-text="No"
                                              true-text="Yes"
                                              alignment="center"
                                              :allow-header-filtering="true"
                                              :allow-filtering="false"
                                              :allow-editing="false"
                                              :show-editor-always="false"
                                              :customize-text="(cellInfo) => { return cellInfo.value ? 'Yes' : 'No' }"
                                              :visible="false"
                                    />

                                    <template #active-cell-template="{ data }">
                                        <div class="text-center mx-1">
                                            <status-indicator :status="data.data.User.IsActive ? 'positive' : 'negative'" />
                                        </div>
                                    </template>
                                </DxDataGrid>
                            </b-card-text>
                        </b-card>
                    </b-card-group>
                </b-col>
            </b-row>
        </b-container>

        <!-- Add New User Popup -->
        <DxPopup
                :visible="showNewUserPopup"
                :drag-enabled="false"
                :close-on-outside-click="false"
                :show-close-button="false"
                :show-title="true"
                :animation="{
                    show: { type: 'pop', duration: 100 },
                    hide: { type: 'pop', duration: 0 }
                }"
                shading-color="rgba(0,0,0,0.4)"
                height="auto"
                width="400"
                container=".dx-viewport"
                title="New User"
                @shown="onAddUserShown"
        >
            <DxToolbarItem widget="dxButton" toolbar="bottom" location="after" :disabled="!(newUser)" :options="{ text: 'Save', onClick: AddNewUser }"/>
            <DxToolbarItem widget="dxButton" toolbar="bottom" location="after" :options="{ text: 'Close', onClick: () => { this.showNewUserPopup = false } }"/>

            <template #content class="py-0">
                <DxForm
                        id="newuserform"
                        ref="NewUserFormRef"
                        :form-data.sync="newUser"
                        :col-count-by-screen="{ lg: 1, md: 1, sm: 1, xs: 1 }"
                        :screen-by-width="getSizeQualifier"
                        label-location="left"
                        width="auto"
                        height="auto"
                >
                    <DxSimpleItem
                            data-field="Email"
                            caption="E-Mail Address"
                    />

                    <DxSimpleItem
                            data-field="Phone"
                            caption="Telephone No"
                            :editor-options="{ maxLength: 11, mask: 'Y (X00)-000-0000', mode: 'tel', maskRules: { 'Y': /[1]/, 'X': /[1-9]/ }, value: '1', showClearButton: true }"
                    />

                    <DxSimpleItem data-field="FirstName"/>

                    <DxSimpleItem data-field="LastName"/>

                    <DxSimpleItem data-field="UserProfileTemplate">
                        <DxLabel text="Profile Template" />
                        <template #default>
                            <DxSelectBox :items="userprofiletemplates" :value.sync="newUser.UserProfileTemplate"/>
                        </template>
                    </DxSimpleItem>

                    <DxSimpleItem data-field="BidTemplates">
                        <DxLabel text="Bid Template" />
                        <template #default>
                            <DxTagBox
                                :data-source="bidtemplates"
                                :value.sync="newUser.BidTemplates"
                                :show-selection-controls="true"
                                value-expr="TemplateID"
                                display-expr="Filename"
                                apply-value-mode="useButtons"
                            />
                        </template>
                    </DxSimpleItem>

                    <DxSimpleItem data-field="Role">
                        <DxLabel text="Roles" />
                        <template #default>
                            <DxTagBox
                                    :items="roles"
                                    :value.sync="newUser.Role"
                                    :show-selection-controls="true"
                                    apply-value-mode="useButtons"
                            />
                        </template>
                    </DxSimpleItem>
                </DxForm>
            </template>
        </DxPopup>

        <!-- Update Phone Number Popup -->
        <DxPopup
            :visible="showUpdatePhonePopup"
            :drag-enabled="false"
            :close-on-outside-click="false"
            :show-close-button="false"
            :show-title="true"
            :animation="{
                show: { type: 'pop', duration: 100 },
                hide: { type: 'pop', duration: 0 }
            }"
            shading-color="rgba(0,0,0,0.4)"
            height="auto"
            width="225"
            container=".dx-viewport"
            title="Update Phone"
            @shown="(e) => {
                this.editValues.Phone = !this.selectedUser.User ? null : this.selectedUser.User.Phone
                this.UpdatePhoneForm.getEditor('Phone').focus()
            }"
        >
            <DxToolbarItem widget="dxButton" toolbar="bottom" location="after" :options="{ text: 'Save', onClick: UpdatePhone }"/>
            <DxToolbarItem widget="dxButton" toolbar="bottom" location="after" :options="{ text: 'Close', onClick: () => { this.showUpdatePhonePopup = false } }"/>

            <template #content class="py-0">
                <DxForm
                        id="updatephoneform"
                        ref="UpdatePhoneFormRef"
                        :form-data.sync="editValues"
                        :col-count-by-screen="{ lg: 1, md: 1, sm: 1, xs: 1 }"
                        :screen-by-width="getSizeQualifier"
                        label-location="left"
                        width="auto"
                        height="auto"
                >
                    <DxSimpleItem
                            data-field="Phone"
                            caption="Phone"
                            :editor-options="{maxLength: 11, mask: 'Y (X00)-000-0000', maskRules: { 'Y': /[1]/, 'X': /[1-9]/ }, value: !editValues.Phone ? '1' : editValues.Phone }"
                            @content-ready="(e) => { e.component.focus() }"
                    />
                </DxForm>
            </template>
        </DxPopup>

        <!-- Update Name Popup -->
        <DxPopup
                :visible="showUpdateNamePopup"
                :drag-enabled="false"
                :close-on-outside-click="false"
                :show-close-button="false"
                :show-title="true"
                :animation="{
                show: { type: 'pop', duration: 100 },
                hide: { type: 'pop', duration: 0 }
            }"
                shading-color="rgba(0,0,0,0.4)"
                height="auto"
                width="300"
                container=".dx-viewport"
                title="Update Name"
                @shown="(e) => {
                    this.editValues.FirstName = !this.selectedUser.User ? null : this.selectedUser.User.FirstName
                    this.editValues.LastName = !this.selectedUser.User ? null : this.selectedUser.User.LastName
                    this.UpdateNameForm.getEditor('FirstName').focus()
                }"
        >
            <DxToolbarItem widget="dxButton" toolbar="bottom" location="after" :options="{ text: 'Save', onClick: UpdateName }"/>
            <DxToolbarItem widget="dxButton" toolbar="bottom" location="after" :options="{ text: 'Close', onClick: () => { this.showUpdateNamePopup = false } }"/>

            <template #content class="py-0">
                <DxForm
                        id="updatenameform"
                        ref="UpdateNameFormRef"
                        :form-data.sync="editValues"
                        :col-count-by-screen="{ lg: 1, md: 1, sm: 1, xs: 1 }"
                        :screen-by-width="getSizeQualifier"
                        label-location="left"
                        width="auto"
                        height="auto"
                >
                    <DxSimpleItem
                        data-field="FirstName"
                        caption="First Name"
                    />
                    <DxSimpleItem
                        data-field="LastName"
                        caption="Last Name"
                    />
                </DxForm>
            </template>
        </DxPopup>

        <!-- Update Email Popup -->
        <DxPopup
                :visible="showUpdateEmailPopup"
                :drag-enabled="false"
                :close-on-outside-click="false"
                :show-close-button="false"
                :show-title="true"
                :animation="{
                show: { type: 'pop', duration: 100 },
                hide: { type: 'pop', duration: 0 }
            }"
                shading-color="rgba(0,0,0,0.4)"
                height="auto"
                width="400"
                container=".dx-viewport"
                title="Update Email"
                @shown="(e) => {
                    this.editValues.Email = !this.selectedUser.User ? null : this.selectedUser.User.Email
                    this.UpdateEmailForm.getEditor('Email').focus()
                }"
        >
            <DxToolbarItem widget="dxButton" toolbar="bottom" location="after" :options="{ text: 'Save', onClick: UpdateEmail }"/>
            <DxToolbarItem widget="dxButton" toolbar="bottom" location="after" :options="{ text: 'Close', onClick: () => { this.showUpdateEmailPopup = false } }"/>

            <template #content class="py-0">
                <DxForm
                        id="updateemailform"
                        ref="UpdateEmailFormRef"
                        :form-data.sync="editValues"
                        :col-count-by-screen="{ lg: 1, md: 1, sm: 1, xs: 1 }"
                        :screen-by-width="getSizeQualifier"
                        label-location="left"
                        width="auto"
                        height="auto"
                >
                    <DxSimpleItem
                            data-field="Email"
                            caption="E-Mail Address"
                    />
                </DxForm>
            </template>
        </DxPopup>

        <!-- Add Bid Template Popup -->
        <DxPopup
                :visible="showAddBidTemplatePopup"
                :drag-enabled="false"
                :close-on-outside-click="false"
                :show-close-button="false"
                :show-title="true"
                :animation="{
                    show: { type: 'pop', duration: 100 },
                    hide: { type: 'pop', duration: 0 }
                }"
                shading-color="rgba(0,0,0,0.4)"
                height="auto"
                width="500"
                container=".dx-viewport"
                title="Add Bid Template"
                @shown="(e) => {
                    this.editValues.BidTemplates = !this.selectedUser.User ? [] : this.selectedUser.User.BidTemplateContacts.map(btc => btc.TemplateID)
                }"
        >
            <DxToolbarItem widget="dxButton" toolbar="bottom" location="after" :options="{ text: 'Save', onClick: AddBidTemplate }"/>
            <DxToolbarItem widget="dxButton" toolbar="bottom" location="after" :options="{ text: 'Close', onClick: () => { this.showAddBidTemplatePopup = false } }"/>

            <template #content class="py-0">
                <DxForm
                        id="addbidtemplateform"
                        ref="AddBidTemplateFormRef"
                        :form-data="editValues"
                        :col-count-by-screen="{ lg: 1, md: 1, sm: 1, xs: 1 }"
                        :screen-by-width="getSizeQualifier"
                        label-location="left"
                        width="auto"
                        height="auto"
                >
                    <DxSimpleItem data-field="BidTemplates">
                        <DxLabel text="Bid Template(s)" />
                        <template #default>
                            <DxTagBox
                                :data-source="bidtemplates"
                                :value.sync="editValues.BidTemplates"
                                :show-selection-controls="true"
                                :hide-selected-items="true"
                                value-expr="TemplateID"
                                display-expr="Filename"
                                apply-value-mode="useButtons"
                                tag-template="tagTemplate"
                                @initialized="(e) => { e.component.registerKeyHandler('backspace',function() { return true; }) }"
                                @key-down="(e) => {
                                    e.event.stopPropagation();
                                    e.event.preventDefault();
                                }"
                            >
                                <template #tagTemplate="{ data }">
                                    <div class="dx-tag-content dx-tag-content-custom">
                                        <b>{{ data.Filename }}</b>
                                    </div>
                                </template>
                            </DxTagBox>
                        </template>
                    </DxSimpleItem>
                </DxForm>
            </template>
        </DxPopup>

        <!-- Remove Bid Template Popup -->
        <DxPopup
                :visible="showRemoveBidTemplatePopup"
                :drag-enabled="false"
                :close-on-outside-click="false"
                :show-close-button="false"
                :show-title="true"
                :animation="{
                    show: { type: 'pop', duration: 100 },
                    hide: { type: 'pop', duration: 0 }
                }"
                shading-color="rgba(0,0,0,0.4)"
                height="auto"
                width="500"
                container=".dx-viewport"
                title="Remove Bid Template"
                @shown="(e) => {
                    this.editValues.BidTemplates = !this.selectedUser.User ? [] : this.selectedUser.User.BidTemplateContacts.map(btc => btc.TemplateID)
                }"
        >
            <DxToolbarItem widget="dxButton" toolbar="bottom" location="after" :options="{ text: 'Save', onClick: () => { this.editValues.BidTemplates.length !== 0 ? this.RemoveBidTemplate() : this.DeleteUser() } }"/>
            <DxToolbarItem widget="dxButton" toolbar="bottom" location="after" :options="{ text: 'Close', onClick: () => { this.showRemoveBidTemplatePopup = false } }"/>

            <template #content class="py-0">
                <DxForm
                        id="removebidtemplateform"
                        ref="RemoveBidTemplateFormRef"
                        :form-data="editValues"
                        :col-count-by-screen="{ lg: 1, md: 1, sm: 1, xs: 1 }"
                        :screen-by-width="getSizeQualifier"
                        label-location="left"
                        width="auto"
                        height="auto"
                >
                    <DxSimpleItem data-field="BidTemplates">
                        <DxLabel text="Bid Template(s)" />
                        <template #default>
                            <DxTagBox
                                    :data-source="bidtemplates"
                                    :value.sync="editValues.BidTemplates"
                                    :show-selection-controls="true"
                                    :hide-selected-items="true"
                                    value-expr="TemplateID"
                                    display-expr="Filename"
                                    apply-value-mode="useButtons"
                                    tag-template="tagTemplate"
                                    @initialized="(e) => { e.component.registerKeyHandler('backspace',function() { return true; }) }"
                                    @key-down="(e) => {
                                        e.event.stopPropagation();
                                        e.event.preventDefault();
                                    }"
                            >
                                <template #tagTemplate="{ data }">
                                    <div class="dx-tag-content">
                                        <b>{{ data.Filename }}</b>
                                        <div class="dx-tag-remove-button"></div>
                                    </div>
                                </template>
                            </DxTagBox>
                        </template>
                    </DxSimpleItem>
                </DxForm>
            </template>
        </DxPopup>

        <!-- Update Roles Popup -->
        <DxPopup
                :visible="showUpdateRolesPopup"
                :drag-enabled="false"
                :close-on-outside-click="false"
                :show-close-button="false"
                :show-title="true"
                :animation="{
                show: { type: 'pop', duration: 100 },
                hide: { type: 'pop', duration: 0 }
            }"
                shading-color="rgba(0,0,0,0.4)"
                height="auto"
                width="500"
                container=".dx-viewport"
                title="Update Roles"
                @shown="(e) => { this.editValues.Role = this.selectedUser.Role.length ? this.selectedUser.Role : [] }"
        >
            <DxToolbarItem widget="dxButton" toolbar="bottom" location="after" :options="{ text: 'Save', onClick: UpdateRoles }"/>
            <DxToolbarItem widget="dxButton" toolbar="bottom" location="after" :options="{ text: 'Close', onClick: () => { this.showUpdateRolesPopup = false } }"/>

            <template #content class="py-0">
                <DxForm
                        id="updaterolesform"
                        ref="UpdateRolesFormRef"
                        :form-data.sync="editValues"
                        :col-count-by-screen="{ lg: 1, md: 1, sm: 1, xs: 1 }"
                        :screen-by-width="getSizeQualifier"
                        label-location="left"
                        width="auto"
                        height="auto"
                >
                    <DxSimpleItem data-field="Role">
                        <DxLabel text="Roles" />
                        <template #default>
                            <DxTagBox
                                    :items="roles"
                                    :value.sync="editValues.Role"
                                    :show-selection-controls="true"
                                    apply-value-mode="useButtons"
                            />
                        </template>
                    </DxSimpleItem>
                </DxForm>
            </template>
        </DxPopup>
    </single-page>
</template>

<script>
/* eslint-disable */

import { DxSelectBox } from "devextreme-vue/select-box";
import { DxTagBox } from "devextreme-vue/tag-box";
import { DxButton } from 'devextreme-vue/button'
import { DxForm, DxSimpleItem, DxLabel } from 'devextreme-vue/form';
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import { DxDataGrid, DxColumn, DxPaging, DxPager, DxHeaderFilter, DxSorting, DxFilterRow, DxToolbar, DxItem } from 'devextreme-vue/data-grid';

import { StatusIndicator } from 'vue-status-indicator';
import { API } from "@/API";
import SinglePage from "@/components/SinglePage";

import stringHelpers from "@/utils/string-helpers";
import { confirm } from "devextreme/ui/dialog";

import moment from "moment";

export default {
    name: "customer-administrator-view",

    components: {
        DxDataGrid,
        DxPaging,
        DxPager,
        DxHeaderFilter,
        DxSorting,
        DxColumn,
        DxFilterRow,
        DxToolbar,
        DxItem,
        DxButton,
        DxPopup,
        DxToolbarItem,
        DxForm,
        DxSimpleItem,
        DxLabel,
        StatusIndicator,
        DxTagBox,
        DxSelectBox,
        SinglePage
    },

    data() {
        const UserTypes = [
            { ID: 0, Text: 'All' },
            { ID: 1, Text: 'Not Enrolled' },
            { ID: 2, Text: 'Never Logged In' },
            { ID: 3, Text: 'Active Users' },
            { ID: 4, Text: 'Inactive Users' }
        ]

        return {
            roles: ['Customer Buyer', 'Customer Driver', 'Customer Administrator'],
            users: [],
            selectedUser: null,
            selectedUserCopy: null,
            userprofiletemplates: [],
            bidtemplates: [],
            newUser: {},
            editUser: {},
            showNewUserPopup: false,
            showEditUserPopup: false,
            showUpdatePhonePopup: false,
            showUpdateEmailPopup: false,
            showUpdateNamePopup: false,
            showAddBidTemplatePopup: false,
            showRemoveBidTemplatePopup: false,
            showUpdateRolesPopup: false,
            editValues: {
                FirstName: null,
                LastName: null,
                Email: null,
                Phone: null,
                BidTemplates: [],
                Role: [],
            },
            UserTypes
        }
    },

    beforeRouteEnter(to, from, next) {
        next(vm => {
            let roles = to.meta.requiredRoles.join("|")
            let permissions = to.meta.requiredPermissions.join("|")

            if (vm.$gates.hasAnyRole(roles) || vm.$gates.hasAnyPermission(permissions))
                next()
            else
                next('/home')
        })
    },

    beforeDestroy() {
        this.$root.$off('customer-changed')
    },

    async mounted() {
        await this.Fetch()

        this.$root.$on('customer-changed', async (customer) => {
            await this.Fetch()
        })
    },

    methods: {
        async Fetch() {
            await this.FetchUsers();
            await this.FetchUserProfileTemplates();
            await this.FetchBidTemplates();
        },

        async FetchUsers() {
            await this.$store.dispatch('loading/message', "Retrieving Users...")

            if (this.Authenticated) {
                API.get(`/admin/users/customer/${this.CurrentCustomer.CustomerNo}`)
                        .then(response => {
                            this.users = response.data
                        })
                        .catch(error => {
                            this.$toast.open({
                                message: error.response.data.message,
                                type: 'error',
                            });
                        })
            } else {
                this.$router.push('/login')
            }
        },

        async FetchUserProfileTemplates() {
            await this.$store.dispatch('loading/message', "Retrieving User Profile Templates...")

            if (this.Authenticated) {
                API.get('/admin/users/profile/templates')
                        .then(response => {
                            this.userprofiletemplates = response.data.map(t => t.Name)
                        })
                        .catch(error => {
                            this.$toast.open({
                                message: error.response.data.message,
                                type: 'error',
                            });
                        })
            } else {
                await this.$router.push('/login')
            }
        },

        async FetchBidTemplates() {
            await this.$store.dispatch('loading/message', "Retrieving Bid Templates...")

            if (this.Authenticated) {
                API.get(`/admin/customers/bidtemplates/${this.CurrentCustomer.CustomerNo}`)
                        .then(response => {
                            this.bidtemplates = response.data
                        })
                        .catch(error => {
                            this.$toast.open({
                                message: error.response.data.message,
                                type: 'error',
                            });
                        })
            } else {
                await this.$router.push('/login')
            }
        },

        async Refresh(e) {
            await this.Fetch()
        },

        NewUser() {
            return {
                Email: null,
                Phone: null,
                FirstName: null,
                LastName: null,
                UserProfileTemplate: null,
                BidTemplates: [],
                Role: [],
                Enroll: true
            }
        },

        ResetEditValues() {
            this.editValues = {
                Email: null,
                Phone: null,
                FirstName: null,
                LastName: null,
                BidTemplates: [],
                Role: [],
            }
        },

        ShowAddUser() {
            this.showNewUserPopup = true
            this.NewUserForm.getEditor('User.Email').focus()
        },

        async AddNewUser(e) {
            let result = await this.ValidateAdd(this.newUser)

            if (!result.valid) {
                this.$toast.open({message: result.message, type: 'error'})
                this.NewUserForm.getEditor(result.field).focus()
                return
            }

            if(this.newUser.BidTemplates.length > 1) {
                let resultConfirm = confirm("You have selected more than one bid template, is this your intent?", `Confirm Multiple Bid Templates`);

                resultConfirm.then(async (dialogResult) => {
                    if (dialogResult) await this.SendNewUser()
                })
            } else {
                await this.SendNewUser()
            }
        },

        async SendNewUser() {
            await this.$store.dispatch('loading/message', "Creating New User...")

            API.post(`/admin/users/customer/${this.CurrentCustomer.CustomerNo}`, this.newUser)
                    .then(response => {
                        this.users = response.data.data

                        this.selectedUser = null
                        this.CustomerUserManagementGrid.deselectAll()
                    })
                    .catch(error => {
                        this.$toast.open({
                            message: error.response.data.message,
                            type: 'error',
                        });
                    })
                    .finally(() => {
                        this.showNewUserPopup = false
                    })
        },

        async DeleteUser(e) {
            let result = confirm("This will remove the User permanently, are you sure?", `Confirm USER Deletion`);

            result.then(async (dialogResult) => {
                if (dialogResult) {
                    await this.$store.dispatch('loading/message', "Deleting User...")

                    API.delete(`/admin/users/customer/${this.CurrentCustomer.CustomerNo}/${this.selectedUser.User.ID}`)
                            .then(response => {
                                this.users = response.data.data
                            })
                            .catch(error => {
                                this.$toast.open({
                                    message: error.response.data.message,
                                    type: 'error',
                                });
                            })
                            .finally(() => {
                                this.ResetEditValues()
                                this.showRemoveBidTemplatePopup = false

                                this.selectedUser = null
                                this.CustomerUserManagementGrid.deselectAll()
                            })
                }
            })
        },

        async ToggleUser(e) {
            let result = confirm("Are you sure?", `${this.selectedUser.User.IsActive ? 'Disable' : 'Enable'} User`);

            result.then(async (dialogResult) => {
                if (dialogResult) {
                    await this.$store.dispatch('loading/message', "Toggling User Active Status...")

                    API.put(`/admin/users/customer/toggle/${this.CurrentCustomer.CustomerNo}/${this.selectedUser.User.ID}`)
                            .then(response => {
                                this.users = response.data.data

                                this.selectedUser = null
                                this.CustomerUserManagementGrid.deselectAll()
                            })
                            .catch(error => {
                                this.$toast.open({
                                    message: error.response.data.message,
                                    type: 'error',
                                });
                            })
                }
            })
        },

        async SyncToMas(e) {
            let result = confirm("Are you sure?", `Sync to MAS`);

            result.then(async (dialogResult) => {
                if (dialogResult) {
                    await this.$store.dispatch('loading/message', "Syncing Bid Template Contacts To MAS...")

                    API.post(`/admin/users/${this.CurrentCustomer.CustomerNo}/update-to-mas`)
                            .then(response => {
                                this.users = response.data.data

                                this.selectedUser = null
                                this.CustomerUserManagementGrid.deselectAll()
                            })
                            .catch(error => {
                                this.$toast.open({
                                    message: error.response.data.message,
                                    type: 'error',
                                });
                            })
                }
            })
        },

        async UpdatePhone(e) {
            let result = confirm("Are you sure?", `Update Phone`);

            result.then(async (dialogResult) => {
                if (dialogResult) {
                    let result = await this.ValidatePhone(this.editValues)

                    if (!result.valid) {
                        this.$toast.open({message: result.message, type: 'error'});
                        return
                    }

                    await this.$store.dispatch('loading/message', "Updating User Phone...")

                    API.put(`/admin/users/customer/change/phone/${this.CurrentCustomer.CustomerNo}/${this.selectedUser.User.ID}`, this.editValues)
                            .then(response => {
                                this.users = response.data.data

                                this.selectedUser = null
                                this.CustomerUserManagementGrid.deselectAll()
                            })
                            .catch(error => {
                                this.$toast.open({
                                    message: error.response.data.message,
                                    type: 'error',
                                });
                            })
                            .finally(() => {
                                this.ResetEditValues()
                                this.showUpdatePhonePopup = false
                            })
                }
            })
        },

        async UpdateEmail(e) {
            let result = confirm("Are you sure?", `Update Email`);

            result.then(async (dialogResult) => {
                if (dialogResult) {
                    let result = await this.ValidateEmail(this.editValues)

                    if (!result.valid) {
                        this.$toast.open({message: result.message, type: 'error'});
                        return
                    }

                    await this.$store.dispatch('loading/message', "Updating User Email...")

                    API.put(`/admin/users/customer/change/email/${this.CurrentCustomer.CustomerNo}/${this.selectedUser.User.ID}`, this.editValues)
                            .then(response => {
                                this.users = response.data.data

                                this.selectedUser = null
                                this.CustomerUserManagementGrid.deselectAll()
                            })
                            .catch(error => {
                                this.$toast.open({
                                    message: error.response.data.message,
                                    type: 'error',
                                });
                            })
                            .finally(() => {
                                this.ResetEditValues()
                                this.showUpdateEmailPopup = false
                            })
                }
            })
        },

        async UpdateName(e) {
            let result = confirm("Are you sure?", `Update Name`);

            result.then(async (dialogResult) => {
                if (dialogResult) {
                    let result = await this.ValidateName(this.editValues)

                    if (!result.valid) {
                        this.$toast.open({message: result.message, type: 'error'});
                        return
                    }

                    await this.$store.dispatch('loading/message', "Updating User's Name...")

                    API.put(`/admin/users/customer/change/name/${this.CurrentCustomer.CustomerNo}/${this.selectedUser.User.ID}`, this.editValues)
                            .then(response => {
                                this.users = response.data.data

                                this.selectedUser = null
                                this.CustomerUserManagementGrid.deselectAll()
                            })
                            .catch(error => {
                                this.$toast.open({
                                    message: error.response.data.message,
                                    type: 'error',
                                });
                            })
                            .finally(() => {
                                this.ResetEditValues()
                                this.showUpdateNamePopup = false
                            })
                }
            })
        },

        async AddBidTemplate(e) {
            let result = confirm("Are you sure?", `Add Bid Template`);

            result.then(async (dialogResult) => {
                if (dialogResult) {
                    let result = await this.ValidateBidTemplate(this.editValues)

                    if (!result.valid) {
                        this.$toast.open({message: result.message, type: 'error'});
                        return
                    }

                    await this.$store.dispatch('loading/message', "Adding Bid Template(s)...")

                    API.post(`/admin/users/customer/add/bidtemplate/${this.CurrentCustomer.CustomerNo}/${this.selectedUser.User.ID}`, this.editValues)
                            .then(response => {
                                this.users = response.data.data

                                this.selectedUser = null
                                this.CustomerUserManagementGrid.deselectAll()
                            })
                            .catch(error => {
                                this.$toast.open({
                                    message: error.response.data.message,
                                    type: 'error',
                                });
                            })
                            .finally(() => {
                                this.ResetEditValues()
                                this.showAddBidTemplatePopup = false
                            })
                }
            })
        },

        async RemoveBidTemplate(e) {
            let result = confirm("This will remove the Bid Template permanently, are you sure?", `Remove Bid Template`)

            result.then(async (dialogResult) => {
                if (dialogResult) {
                    let validation_result = await this.ValidateBidTemplate(this.editValues)

                    if (!validation_result.valid) {
                        this.$toast.open({message: validation_result.message, type: 'error'});
                        return
                    }

                    await this.$store.dispatch('loading/message', "Removing Bid Template(s)...")

                    API.post(`/admin/users/customer/remove/bidtemplate/${this.CurrentCustomer.CustomerNo}/${this.selectedUser.User.ID}`, this.editValues)
                            .then(response => {
                                this.users = response.data.data

                                this.selectedUser = null
                                this.CustomerUserManagementGrid.deselectAll()
                            })
                            .catch(error => {
                                this.$toast.open({
                                    message: error.response.data.message,
                                    type: 'error',
                                });
                            })
                            .finally(() => {
                                this.ResetEditValues()
                                this.showRemoveBidTemplatePopup = false
                            })
                }
            })
        },

        async UpdateRoles(e) {
            let result = confirm("Are you sure?", `Update Roles`);

            result.then(async (dialogResult) => {
                if (dialogResult) {
                    let result = await this.ValidateRoles(this.editValues)

                    if (!result.valid) {
                        this.$toast.open({message: result.message, type: 'error'});
                        return
                    }

                    await this.$store.dispatch('loading/message', "Updating User Roles...")

                    API.put(`/admin/users/customer/change/roles/${this.CurrentCustomer.CustomerNo}/${this.selectedUser.User.ID}`, this.editValues)
                            .then(response => {
                                this.users = response.data.data

                                this.selectedUser = null
                                this.CustomerUserManagementGrid.deselectAll()
                            })
                            .catch(error => {
                                this.$toast.open({
                                    message: error.response.data.message,
                                    type: 'error',
                                });
                            })
                            .finally(() => {
                                this.ResetEditValues()
                                this.showUpdateRolesPopup = false
                            })
                }
            })
        },

        async ValidateAdd(user) {
            return new Promise((resolve, reject) => {
                this.CheckAvailability(user).then(result => {
                    if (!stringHelpers.isEmptyOrWhitespace(user.Email) && !stringHelpers.isEmailValid(user.Email)) resolve({
                        valid: false,
                        message: "Email must be valid.",
                        field: 'Email'
                    })
                    else if (stringHelpers.isEmptyOrWhitespace(user.Email) && stringHelpers.isEmptyOrWhitespace(user.Phone)) resolve({
                        valid: false,
                        message: "Phone is required.",
                        field: 'Email'
                    })
                    else if (!result.Availability.Phone) resolve({
                        valid: false,
                        message: "Phone is already in use.",
                        field: 'Phone'
                    })
                    else if (!result.Availability.Email) resolve({
                        valid: false,
                        message: "Email is already in use.",
                        field: 'Email'
                    })
                    else if (!stringHelpers.isEmptyOrWhitespace(user.Phone) && user.Phone.length < 10) resolve({
                        valid: false,
                        message: "Valid Phone Number is required.",
                        field: 'Phone'
                    })
                    else if (stringHelpers.isEmptyOrWhitespace(user.UserProfileTemplate)) resolve({
                        valid: false,
                        message: "Profile Template must be selected.",
                        field: 'UserProfileTemplate'
                    })
                    else if (user.BidTemplates.length === 0) resolve({
                        valid: false,
                        message: "At least one Bid Template must be selected.",
                        field: 'BidTemplates'
                    })
                    else if (user.Role.length === 0) resolve({
                        valid: false,
                        message: "Must have ONE role selected.",
                        field: 'Role'
                    })
                    else resolve({
                        valid: true,
                        message: null,
                        field: null
                    })
                })
            })
        },

        async ValidateEmail(user) {
            return new Promise((resolve, reject) => {
                this.CheckAvailability(user).then(result => {
                    if (!stringHelpers.isEmailValid(user.Email)) resolve({
                        valid: false,
                        message: "Email must be valid."
                    })
                    else if (!result.Availability.Email) resolve({
                        valid: false,
                        message: "Email is already in use."
                    })
                    else resolve({
                            valid: true,
                            message: null
                        })
                })
            })
        },

        async ValidatePhone(user) {
            return new Promise((resolve, reject) => {
                this.CheckAvailability(user).then(result => {
                    if (stringHelpers.isEmptyOrWhitespace(user.Phone) && user.Phone.length >= 10) resolve({
                        valid: false,
                        message: "Phone is required."
                    })
                    else if (!result.Availability.Phone) resolve({
                        valid: false,
                        message: "Phone is already in use."
                    })
                    else resolve({
                        valid: true,
                        message: null
                    })
                })
            })
        },

        async ValidateName(user) {
            return new Promise((resolve, reject) => {
                if (stringHelpers.isEmptyOrWhitespace(user.FirstName)) resolve({
                    valid: false,
                    message: "First Name is required."
                })
                else if (stringHelpers.isEmptyOrWhitespace(user.LastName)) resolve({
                    valid: false,
                    message: "Last Name is required."
                })
                else resolve({
                    valid: true,
                    message: null
                })
            })
        },

        async ValidateBidTemplate(user) {
            return new Promise((resolve, reject) => {
                if (user.BidTemplates.length === 0) resolve({
                    valid: false,
                    message: "At least one bid template is required."
                })
                else resolve({
                    valid: true,
                    message: null
                })
            })
        },

        async ValidateRoles(user) {
            return new Promise((resolve, reject) => {
                if (user.Role.length === 0) resolve({
                    valid: false,
                    message: "Must have ONE role selected."
                })
                else resolve({
                    valid: true,
                    message: null
                })
            })
        },

        async CheckAvailability(user) {
            await this.$store.dispatch('loading/message', "Checking Email/Phone Availability...")

            return new Promise((resolve, reject) => {
                API.post(`/admin/users/availability/check`, user)
                        .then(response => {
                            resolve(response.data.data)
                        })
                        .catch(error => {
                            this.$toast.open({
                                message: error.response.data.message,
                                type: 'error',
                            });

                            reject()
                        })
            })
        },

        FormatPhone(rowData) {
            return stringHelpers.phonify(rowData.User.Phone);
        },

        CalculateRolesCellValue(rowData) {
            return rowData.Role.join(' / ')
        },

        CalculateBidTemplateContactsDisplayValue(rowData) {
            if(this.IsSuperAdministrator)
                return rowData.BidTemplateContacts.map(btc => `${btc.BidTemplate.Filename} [${ btc.IsUploaded ? 'Y' : 'N' }]`).join(' / ')
            else
                return rowData.BidTemplateContacts.map(btc => btc.BidTemplate.Filename).join(' / ')
        },

        CalculateBidTemplateContactsCellValue(rowData) {
            return rowData.BidTemplateContacts.map(btc => btc.TemplateID)
        },

        CalculateEnrolledDateCellValue(rowData) {
            return !rowData.User.EnrolledUser?.Enrolled ? 'Not Enrolled' : moment(rowData.User.EnrolledUser.Enrolled).format('MM/DD/YYYY')
        },

        CalculateLastAuthenticationCellValue(rowData) {
            return !rowData.LastAuthentication ? 'Not Logged In' : moment(rowData.LastAuthentication).format('MM/DD/YYYY hh:mm A')
        },

        onSelectionChanged({selectedRowsData}) {
            const data = selectedRowsData[0];
            this.selectedUser = data ? data : null
        },

        onAddUserShown(e) {
            this.newUser = this.NewUser()
            this.CustomerUserManagementGrid.repaint()
        },

        onContextMenuPreparing(e) {
            if (e.target !== 'header') {
                if (!e.items) e.items = [];

                this.selectedUser = e.row.data

                e.items.push(
                        { icon: 'fa fa-solid fa-phone', text: 'Change Phone', onItemClick: () => { this.showUpdatePhonePopup = true } },
                        { icon: 'fa fa-solid fa-envelope', text: 'Change E-Mail', onItemClick: () => { this.showUpdateEmailPopup = true } },
                        { icon: 'fa fa-solid fa-user', text: 'Change Name', onItemClick: () => { this.showUpdateNamePopup = true } },
                        { icon: 'plus', text: 'Add Bid Template', beginGroup: true, onItemClick: () => { this.showAddBidTemplatePopup = true } },
                        { icon: 'minus', text: 'Remove Bid Template', disabled: e.row.data.BidTemplateContacts.length === 0, onItemClick: () => { this.showRemoveBidTemplatePopup = true } },
                        { icon: 'fas fa-id-badge', text: 'Change Role(s)', beginGroup: true, onItemClick: () => { this.showUpdateRolesPopup = true } }
                );
            }
        },

        onUsersGridFilterValueChanged({value}) {
            switch (value) {
                case 0:
                    this.CustomerUserManagementGrid.clearFilter();
                    break;
                case 1:
                    this.CustomerUserManagementGrid.filter(['User.IsEnrolled', '=', false]);
                    break;
                case 2:
                    this.CustomerUserManagementGrid.filter(['LastAuthentication', '=', null]);
                    break;
                case 3:
                    this.CustomerUserManagementGrid.filter(['User.IsActive', '=', true]);
                    break;
                case 4:
                    this.CustomerUserManagementGrid.filter(['User.IsActive', '=', false]);
                    break;
            }
        },

        getSizeQualifier(width) {
            if (width < 768) return "xs";
            if (width < 992) return "sm";
            if (width < 1200) return "md";
            return "lg";
        },
    },

    computed: {
        Authenticated() {
            return this.$store.getters["user/authenticated"];
        },

        CustomerUserManagementGrid() {
            return this.$refs.CustomerUserManagementGridRef.instance
        },

        CurrentCustomer() {
            return this.$store.getters["customers/customer"]
        },

        NewUserForm() {
            return this.$refs.NewUserFormRef.instance
        },

        UpdatePhoneForm() {
            return this.$refs.UpdatePhoneFormRef.instance
        },

        UpdateEmailForm() {
            return this.$refs.UpdateEmailFormRef.instance
        },

        UpdateNameForm() {
            return this.$refs.UpdateNameFormRef.instance
        },

        UpdateBidTemplateForm() {
            return this.$refs.AddBidTemplateFormRef.instance
        },

        UpdateRolesForm() {
            return this.$refs.UpdateRolesFormRef.instance
        },

        Title() {
            return `${this.CurrentCustomer.CustomerName} - Customer User Management`
        },

        IsSuperAdministrator() {
            return this.$gates.hasAnyRole('Super Administrator')
        }
    }
}
</script>

<style scoped>
    ::v-deep .dx-tag-content-custom {
        padding: 2px 8px 2px 4px !important;
    }

    ::v-deep #gridContainer span.current-value {
        display: inline-block;
        margin-right: 5px;
    }

    ::v-deep #gridContainer span.Diff {
        width: 50px;
        display: inline-block;
    }

    ::v-deep #gridContainer .inc .arrow,
    ::v-deep #gridContainer .dec .arrow {
        display: inline-block;
        height: 10px;
        width: 10px;
        background-repeat: no-repeat;
        background-size: 10px 10px;
    }

    ::v-deep #gridContainer .inc .arrow {
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAADKSURBVHjaYtTaLs1ABEiG0nPRJa56PEHhsxBhmCUQT4OyrwHxcXyKmQgYJgHE64CYDYrXQcXIMhCbAcgWkGzgNKh38QUB0QamIUUErkhKI9ZAGyCeTERkTYaqxWsgKA2txhdG6GGsvUNGGpeBRMUiGhCFGsqGzUBQQJsxkA5AemaiG5hDIBIIgQSgK0FmMDACs549kN5FZLjhA7+A2A2U9YSAOBeLAk4gnoBDczoOcSFGPIUDPxB/wCHHiKtwYGKgMhg1cBAaCBBgAJTUIL3ToPZfAAAAAElFTkSuQmCC');
    }

    ::v-deep #gridContainer .dec .arrow {
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAADJSURBVHjaYvzPgBfgkhYA4o8QFahKmBioDEYNHIQGsgBxIBCLkqgvAYi/g1mMjMjir0EJzR6If/6HpChKMMgMe3DKBeIcKhiY8x/MYoDj+RQYNgdkGLqBbEB8kgzDToL1YjEQhKWB+BUJhj0H64Eahs1AELYhMpJ+gtUiGYbLQBBOI8LANLBaIg1kAAc0vkiAqSPBQFAkHcNi2DGoHMkGgrAENOCRI0ECRQ2JBoKwJTQCfkLZDPgMZPxPXN5NhtJzMSsJVBMAAgwAyWSY2svfmrwAAAAASUVORK5CYII=');
    }

    ::v-deep .dx-toolbar {
        background-color: #f8f9fa !important;
    }

    ::v-deep .dx-datagrid-header-panel {
        background-color: #f8f9fa !important;
    }

    .inline-form {
        float: left;
        overflow: hidden;
        white-space: nowrap;
        cursor: default;
    }

    ::v-deep .dx-selection td{
        background-color: #5990be !important;
        color: white !important;
    }

    ::v-deep .dx-invalid-message .dx-overlay-content {
        display: none !important;
    }
</style>
