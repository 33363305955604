<template>
    <div class="user-panel">
        <dx-context-menu
            v-if="menuMode === 'context'"
            target=".user-button"
            :items.sync="menuItems"
            show-event="dxclick"
            css-class="user-menu"
        >
            <dx-position my="top left" at="bottom center"/>
        </dx-context-menu>

        <dx-list
            v-if="menuMode === 'list'"
            class="dx-toolbar-menu-action"
            :items.sync="menuItems"
        />
    </div>
</template>

<script>
import DxContextMenu, {DxPosition} from "devextreme-vue/context-menu";
import DxList from "devextreme-vue/list";

export default {
    props: {
        menuMode: String,
        menuItems: Array
    },

    components: {
        DxContextMenu,
        DxPosition,
        DxList
    }
};
</script>

<style lang="scss" scoped>
@import "../themes/generated/variables.base.scss";
@import "../fm-styles";

.user-info {
    display: flex;
    align-items: center;
    vertical-align: center;
    margin: 5px;
    padding: 10px;

    .dx-toolbar-menu-section & {
        padding: 10px 6px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
}

.user-panel {
    .dx-list-item .dx-icon {
        vertical-align: middle;
        color: $base-text-color;
        margin-right: 16px;
    }

    .dx-rtl .dx-list-item .dx-icon {
        margin-right: 0;
        margin-left: 16px;
    }

    .user-name {
        font-size: 8px;
        color: $base-text-color;
        margin: 0 9px;
    }
}

.dx-context-menu.user-menu.dx-menu-base {
    &.dx-rtl {
        .dx-submenu .dx-menu-items-container .dx-icon {
            margin-left: 16px;
        }
    }

    .dx-submenu .dx-menu-items-container .dx-icon {
        margin-right: 16px;
    }

    .dx-menu-item .dx-menu-item-content {
        padding: 3px 15px 4px;
    }
}
</style>
