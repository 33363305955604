import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';

import 'vue-toast-notification/dist/theme-sugar.css';

import { BootstrapVue, IconsPlugin, TablePlugin, ButtonToolbarPlugin } from "bootstrap-vue";

import Vue from "vue";
import App from "./App";
import router from "./router";
import appInfo from "./app-info";
import store from './stores/store'
import moment from 'moment';
import numeral from 'numeral';
import underscore from 'vue-underscore';
import VueGates from 'vue-gates';
import VSwitch from 'v-switch-case'
import VueToast from 'vue-toast-notification';


Vue.prototype.moment = moment;
Vue.prototype.numeral = numeral;

Vue.config.productionTip = false;
Vue.prototype.$appInfo = appInfo;

Vue.use(BootstrapVue, {
	breakpoints: [`xs`, 'sm', 'md', 'lg', 'xl', 'xxl']
});
Vue.use(IconsPlugin);
Vue.use(TablePlugin);
Vue.use(ButtonToolbarPlugin);

Vue.use(underscore);
Vue.use(VueGates, {
	superRole: 'Super Administrator',
	persistent: false
});
Vue.use(VSwitch)
Vue.use(VueToast, {
	duration: 5000
});

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount("#app");
