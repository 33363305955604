<template>
    <div class="p-2">
        <single-page :requires-auth="false" :fluid="true">
            <CustomerPricingTable class="shadow-md"/>
        </single-page>
    </div>
</template>

<script>
import SinglePage from "@/components/SinglePage";
import CustomerPricingTable from "../../components/Pricing/CustomerPricingTable";

export default {
    name: "customer-pricing-current-view",

    components: {
        CustomerPricingTable,
        SinglePage,
    },

    beforeRouteEnter(to, from, next) {
        next(vm => {
            let roles = to.meta.requiredRoles.join("|")
            let permissions = to.meta.requiredPermissions.join("|")

            if (vm.$gates.hasAnyRole(roles) || vm.$gates.hasAnyPermission(permissions))
                next()
            else
                next('/home')
        })
    },

    data() {
        return {
        }
    },

    methods: {
    },

    computed: {
    }
}

</script>

<style scoped>

</style>
