<template>
    <div
            class="dx-swatch-additional side-navigation-menu"
            @click="forwardClick"
    >
        <slot/>
        <div class="menu-container">
            <dx-tree-view
                    ref="MenuTreeViewRef"
                    :items.sync="items"
                    :select-nodes-recursive="true"
                    :select-by-click="true"
                    key-expr="path"
                    selection-mode="single"
                    :focus-state-enabled="false"
                    expand-event="click"
                    @item-click="handleItemClick"
                    @initialized="handleMenuInitialized"
                    width="100%"
            />
        </div>
    </div>
</template>

<script>
import DxTreeView from "devextreme-vue/ui/tree-view";
import {sizes} from '../utils/media-query';
import navigation from '../app-navigation';
import {API} from "@/API";

const isLargeScreen = sizes()['screen-large'];
const appnav = navigation.map((item) => ({...item, expanded: item.expanded, visible: item.visible, requiredRoles: item.requiredRoles}));

export default {
    props: {
        compactMode: Boolean
    },

    components: {
        DxTreeView
    },

    data() {
        return {
            items: []
        };
    },

    mounted() {
        this.SetVisibility()

        this.$root.$on('user-login', this.SetVisibility)
        this.$root.$on('user-logout', () => this.items = [...appnav.map((item) => ({...item, expanded: item.expanded, visible: item.visible, requiredRoles: item.requiredRoles}))])

        this.$root.$on('user-impersonated', this.SetVisibility)
        this.$root.$on('user-unpersonated', this.SetVisibility)
    },

    created() {
        this.updateSelection();

        if (this.MenuTreeView && this.compactMode) {
            this.MenuTreeView.collapseAll();
        }
    },

    beforeDestroy() {
        this.$root.$off('user-login')
        this.$root.$off('user-logout')

        this.$root.$off('user-impersonated')
        this.$root.$off('user-unpersonated')
    },

    watch: {
        $route() {
            this.updateSelection();
            this.SetVisibility()
        },

        compactMode() {
            if (this.MenuTreeView && this.compactMode) {
                this.MenuTreeView.collapseAll();
            } else {
                this.updateSelection();
            }
        }
    },

    methods: {
        forwardClick(...args) {
            this.$emit("click", args);
        },

        handleItemClick(e) {
            if (!e.itemData.path || this.compactMode) {
                return;
            }

            API.post('/user/activity', {
                MenuName: e.itemData.text
            })

            this.$router.push(e.itemData.path);

            const pointerEvent = e.event;
            pointerEvent.stopPropagation();
        },

        handleMenuInitialized(event) {
            event.component.option("deferRendering", true);
        },

        updateSelection() {
            if (!this.MenuTreeView) {
                return;
            }

            this.MenuTreeView.selectItem(this.$route.path);
            this.MenuTreeView.expandItem(this.$route.path);
        },

        SetVisibility() {
            this.items = [...appnav.map((item) => ({...item, expanded: item.expanded, visible: item.visible, requiredRoles: item.requiredRoles}))];

            if(this.Impersonating()) {
                this.$gates.setPermissions(this.ImpersonatedUser().Permissions)
                this.$gates.setRoles(this.ImpersonatedUser().Roles)
            } else {
                this.$gates.setPermissions(this.User)
                this.$gates.setRoles(this.Roles)
            }

            const EvaluateLeafVisibility = (item) => {
                if(item.disabled) return;

                item.visible = this.$gates.hasAnyRole(item.requiredRoles.join("|"))
            };

            const ProcessLeaf = (items) => {
                if (!items) return

                for (let i in items) {
                    EvaluateLeafVisibility(items[i])
                    ProcessLeaf(items[i].items);
                }
            };

            ProcessLeaf(this.items)
        },

        Impersonating() {
            return this.$store.getters["impersonate/impersonating"]
        },

        ImpersonatedUser() {
            return this.$store.getters['impersonate/user'];
        },
    },

    computed: {
        MenuTreeView() {
            return this.$refs['MenuTreeViewRef'] && this.$refs['MenuTreeViewRef'].instance;
        },

        Authenticated() {
            return this.$store.getters["user/authenticated"];
        },

        User() {
            return this.$store.getters["user/permissions"]
        },

        Roles() {
            return this.$store.getters["user/roles"]
        }
    },
};
</script>

<style lang="scss">
@import "../dx-styles.scss";
@import "../themes/generated/variables.additional.scss";

.side-navigation-menu {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    height: 100%;
    width: 250px !important;

    .menu-container {
        min-height: 100%;
        display: flex;
        flex: 1;

        .dx-treeview {
            // ## Long text positioning
            white-space: nowrap;
            // ##

            // ## Icon width customization
            .dx-treeview-item {
                padding-left: 0;
                padding-right: 0;

                .dx-icon {
                    width: $side-panel-min-width !important;
                    margin: 0 !important;
                }
            }

            // ##

            // ## Arrow customization
            .dx-treeview-node {
                padding: 0 0 !important;
            }

            .dx-treeview-toggle-item-visibility {
                right: 10px;
                left: auto;
            }

            .dx-rtl .dx-treeview-toggle-item-visibility {
                left: 10px;
                right: auto;
            }

            // ##

            // ## Item levels customization
            .dx-treeview-node {
                &[aria-level="1"] {
                    font-weight: bold;
                    //border-bottom: 1px solid $base-border-color;
                }

                &[aria-level="2"] .dx-treeview-item-content {
                    font-weight: normal;
                    padding: 0 $side-panel-min-width;
                }
                &[aria-level="3"] .dx-treeview-item-content {
                    font-weight: normal;
                    padding: 0 $side-panel-min-width 0 80px !important;
                }
            }

            // ##
        }

        // ## Selected & Focuced items customization
        .dx-treeview {
            padding-top: 5px !important;

            .dx-treeview-node-container {
                .dx-treeview-node {
                    &.dx-state-selected:not(.dx-state-focused) > .dx-treeview-item {
                        background: transparent;
                    }

                    &.dx-state-selected > .dx-treeview-item * {
                        color: $base-accent;
                    }

                    &:not(.dx-state-focused) > .dx-treeview-item.dx-state-hover {
                        background-color: lighten($base-bg, 4);
                    }
                }
            }
        }

        .dx-theme-generic .dx-treeview {
            .dx-treeview-node-container
            .dx-treeview-node.dx-state-selected.dx-state-focused
            > .dx-treeview-item
            * {
                color: inherit;
            }
        }

        // ##
    }
}
</style>
