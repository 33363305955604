<template>
    <div>
        <b-container fluid class="px-2 border rounded pb-0 bg-light">
            <b-row no-gutters class="d-flex">
                <b-col xs="auto" sm="auto" md="auto" lg="auto" xl="auto" class="py-2 w-100">
                    <DxDataGrid
                            id="gridContainer"
                            ref="UserAlertsGridRef"
                            no-data-text="No Alert Records..."
                            :data-source.sync="userMarketAlerts"
                            :show-borders="true"
                            :show-row-lines="true"
                            :column-auto-width="true"
                            :two-way-binding-enabled="false"
                            :column-hiding-enabled="false"
                            :selection="{ mode: 'single' }"
                            key-expr="ID"
                            width="auto"
                            class="p-0"
                            @selection-changed="onSelectionChanged"
                        >
                        <DxHeaderFilter :visible="true"/>

                        <DxSorting show-sort-indexes />
                        <DxScrolling mode="infinite"/>

                        <DxColumn
                                cell-template="active-cell-template"
                                :header-filter="{height: 'auto', allowSearch: true, position: { my: 'center', at: 'center' } }"
                                :allow-header-filtering="false"
                                :allow-sorting="false"
                        />
                        <DxColumn
                                data-field="Market.Name"
                                caption="Market"
                                :visible="true"
                                :sort-index="0"
                                sort-order="asc"
                                :allow-header-filtering="false"
                                :allow-sorting="false"
                        />
                        <DxColumn
                                data-field="Product.Name"
                                caption="Ticker"
                                :sort-index="1"
                                sort-order="asc"
                                :allow-header-filtering="false"
                                :allow-sorting="false"
                        />
                        <DxColumn
                                alignment="right"
                                data-field="Threshold"
                                data-type="number"
                                caption="Trigger"
                                :format="{ type: 'fixedPoint', precision: 4 }"
                                :allow-header-filtering="false"
                                :allow-sorting="false"
                        />
                        <DxColumn
                                alignment="center"
                                data-field="Type"
                                cell-template="changeCellTemplate"
                                caption="Direction"
                                :allow-header-filtering="false"
                                :allow-sorting="false"
                        />

                        <DxToolbar>
                            <DxItem location="before" template="addTemplate"/>
                            <DxItem location="before" template="editTemplate"/>
                            <DxItem location="before" template="removeTemplate"/>
                            <DxItem location="before" template="toggleTemplate"/>
                            <DxItem location="after" template="refreshTemplate"/>
                        </DxToolbar>

                        <template #changeCellTemplate="{ data: cellData }">
                            <div v-switch="cellData.data.Type">
                                <div v-case="1" class="inc">
                                    <span class="arrow"/> Above
                                </div>
                                <div v-case="0">
                                    <span class="arrow"/> Both
                                </div>
                                <div v-case="-1" class="dec">
                                    <span class="arrow"/> Below
                                </div>
                            </div>
                        </template>

                        <template #active-cell-template="{ data }">
                            <div class="text-center mx-1">
                                <status-indicator :status="data.data.Active ? 'positive': 'negative'" />
                            </div>
                        </template>

                        <template #addTemplate>
                            <DxButton icon="add" @click="ShowAddAlert"/>
                        </template>

                        <template #editTemplate>
                            <DxButton icon="edit" :disabled="!selectedUserMarketAlert || !userMarketAlerts.length" @click="ShowEditAlert"/>
                        </template>

                        <template #removeTemplate>
                            <DxButton icon="minus" :disabled="!selectedUserMarketAlert || !userMarketAlerts.length" @click="DeleteUserAlert"/>
                        </template>

                        <template #toggleTemplate>
                            <DxButton icon="redo" :disabled="!selectedUserMarketAlert || !userMarketAlerts.length" @click="ToggleUserAlert"/>
                        </template>

                        <template #refreshTemplate>
                            <DxButton icon="refresh" @click="RefreshUserAlerts"/>
                        </template>
                    </DxDataGrid>
                </b-col>
            </b-row>
        </b-container>

        <DxPopup
            :visible="showAddUserMarketAlertPopup"
            :drag-enabled="false"
            :close-on-outside-click="false"
            :show-close-button="false"
            :show-title="true"
            :animation="{
                show: { type: 'pop', duration: 100 },
                hide: { type: 'pop', duration: 0 }
            }"
            width="auto"
            shading-color="rgba(0,0,0,0.4)"
            height="auto"
            container=".dx-viewport"
            title="Add User Market Alert"
            @shown="onAddUserAlertShown"
        >
            <DxToolbarItem widget="dxButton" toolbar="bottom" location="after" :disabled="!(newUserMarketAlert.MarketID && newUserMarketAlert.ProductID)" :options="{ text: 'Save', onClick: CreateUserAlert}"/>
            <DxToolbarItem widget="dxButton" toolbar="bottom" location="after" :options="{ text: 'Close', onClick: () => { this.showAddUserMarketAlertPopup = false } }"/>

            <template #content class="py-0">
                <DxForm
                    id="newalertform"
                    :form-data="newUserMarketAlert"
                    :col-count-by-screen="{ lg: 2, md: 2, sm: 2, xs: 2 }"
                    :screen-by-width="getSizeQualifier"
                    label-location="left"
                    width="auto"
                    height="auto"
                >
                    <DxSimpleItem
                            data-field="Channel"
                            :col-span="2"
                    >
                        <DxLabel text="Send Alert To" />
                        <template #default>
                            <DxDropDownButton
                                    :items="AlertChannelsFiltered"
                                    :use-select-mode="true"
                                    :drop-down-options="{ width: 'auto' }"
                                    :selected-item-key.sync="newUserMarketAlert.Channel"
                                    :width="100"
                                    display-expr="name"
                                    key-expr="id"
                            />
                        </template>
                    </DxSimpleItem>
                    <DxSimpleItem
                            data-field="Type"
                            :col-span="2"
                    >
                        <DxLabel text="Move Direction" />
                        <template #default>
                            <DxDropDownButton
                                    :items="AlertTypes"
                                    :use-select-mode="true"
                                    :drop-down-options="{ width: 'auto' }"
                                    :selected-item-key.sync="newUserMarketAlert.Type"
                                    :width="100"
                                    display-expr="name"
                                    key-expr="id"
                            />
                        </template>
                    </DxSimpleItem>

                    <DxSimpleItem
                            data-field="MarketID"
                            :col-span="2"
                    >
                        <DxLabel text="Market" />
                        <template #default>
                            <DxSelectBox
                                    :data-source="markets"
                                    :value.sync="newUserMarketAlert.MarketID"
                                    :width="200"
                                    display-expr="Market.Name"
                                    value-expr="Market.ID"
                                    @value-changed="onMarketsValueChange"
                            />
                        </template>
                    </DxSimpleItem>

                    <DxSimpleItem
                            data-field="ProductID"
                            :col-span="2"
                    >
                        <DxLabel text="Ticker" />
                        <template #default>
                            <DxSelectBox
                                    :data-source="marketProducts && marketProducts.ActiveProducts"
                                    :value.sync="newUserMarketAlert.ProductID"
                                    :disabled="!newUserMarketAlert.MarketID"
                                    :width="200"
                                    display-expr="Name"
                                    value-expr="ID"
                            />
                        </template>
                    </DxSimpleItem>

                    <DxSimpleItem
                        data-field="Threshold"
                        :col-span="2"
                    >
                        <DxLabel text="Initial Trigger" />
                        <template #default>
                            <DxNumberBox
                                    :min="threshold.min"
                                    :max="threshold.max"
                                    :step="threshold.step"
                                    :value.sync="newUserMarketAlert.Threshold"
                                    :format="{ type: 'fixedPoint', precision: 4 }"
                                    :height="24"
                                    :input-attr="{ class: 'text-center' }"
                                    :width="100"
                            >
                                <DxNumberBoxButton
                                        :options="minusThresholdCreateButton"
                                        name="prevValue"
                                        location="before"
                                />
                                <DxNumberBoxButton
                                        :options="plusThresholdCreateButton"
                                        name="nextValue"
                                        location="after"
                                />
                            </DxNumberBox>
                        </template>
                    </DxSimpleItem>

                    <DxSimpleItem
                            data-field="Frequency"
                            :col-span="2"
                    >
                        <DxLabel text="Secondary Trigger" />
                        <template #default>
                            <DxNumberBox
                                    :min="frequency.min"
                                    :max="frequency.max"
                                    :step="frequency.step"
                                    :value.sync="newUserMarketAlert.Frequency"
                                    :format="{ type: 'fixedPoint', precision: 4 }"
                                    :height="24"
                                    :input-attr="{ class: 'text-center' }"
                                    :width="100"
                            >
                                <DxNumberBoxButton
                                        :options="minusFrequencyCreateButton"
                                        name="prevValue"
                                        location="before"
                                />
                                <DxNumberBoxButton
                                        :options="plusFrequencyCreateButton"
                                        name="nextValue"
                                        location="after"
                                />
                            </DxNumberBox>
                        </template>
                    </DxSimpleItem>

                    <DxSimpleItem
                            data-field="Snooze"
                            :col-span="2"
                    >
                        <DxLabel text="Snooze" />
                        <template #default>
                            <DxSwitch :value.sync="newUserMarketAlert.Snooze"/>
                        </template>
                    </DxSimpleItem>

                    <DxSimpleItem
                            data-field="SnoozeStartTime"
                            :col-span="2"
                            :visible="newUserMarketAlert.Snooze"
                    >
                        <DxLabel text="Snooze Start" />
                        <template #default>
                            <DxDateBox
                                :value.sync="newUserMarketAlert.SnoozeStartTime"
                                :disabled="!newUserMarketAlert.Snooze"
                                :use-mask-behavior="true"
                                :show-drop-down-button="false"
                                :width="100"
                                display-format="HH:mm"
                                type="time"
                            />
                        </template>
                    </DxSimpleItem>

                    <DxSimpleItem
                            data-field="SnoozeEndTime"
                            :col-span="2"
                            :visible="newUserMarketAlert.Snooze"
                    >
                        <DxLabel text="Snooze End" />
                        <template #default>
                            <DxDateBox
                                :value.sync="newUserMarketAlert.SnoozeEndTime"
                                :disabled="!newUserMarketAlert.Snooze"
                                :use-mask-behavior="true"
                                :show-drop-down-button="false"
                                :width="100"
                                display-format="HH:mm"
                                type="time"
                            />
                        </template>
                    </DxSimpleItem>

                    <DxSimpleItem
                            :col-span="2"
                    >
                        <template #default>
                            <div style="color: red"><i><small>* Setting Secondary Trigger to zero will ONLY use Initial Trigger</small></i></div>
                        </template>
                    </DxSimpleItem>

                    <DxSimpleItem
                            :col-span="2"
                            css-class="py-0"
                    >
                        <template #default>
                            <div style="color: red"><i><small>* Snooze will assume overnight if PM -> AM</small></i></div>
                        </template>
                    </DxSimpleItem>
                </DxForm>
            </template>
        </DxPopup>

        <DxPopup
            :visible="showEditUserMarketAlertPopup"
            :drag-enabled="false"
            :close-on-outside-click="false"
            :show-close-button="false"
            :show-title="true"
            width="auto"
            :animation="{
                show: { type: 'pop', duration: 100 },
                hide: { type: 'pop', duration: 0 }
            }"
            shading-color="rgba(0,0,0,0.4)"
            height="auto"
            container=".dx-viewport"
            title="Edit User Market Alert"
            @shown="onEditUserAlertShown"
        >
            <DxToolbarItem widget="dxButton" toolbar="bottom" location="after" :options="{ text: 'Save', onClick: EditUserAlert }"/>
            <DxToolbarItem widget="dxButton" toolbar="bottom" location="after" :options="{ text: 'Close', onClick: CloseEditAlert }"/>

            <template #content class="py-0">
                <DxForm
                        id="editalertform"
                        :form-data="editUserMarketAlert"
                        :col-count-by-screen="{ lg: 2, md: 2, sm: 2, xs: 2 }"
                        :screen-by-width="getSizeQualifier"
                        label-location="left"
                        width="auto"
                        height="auto"
                >
                    <DxSimpleItem
                            data-field="Channel"
                            :col-span="2"
                    >
                        <DxLabel text="Send Alert To" />
                        <template #default>
                            <DxDropDownButton
                                    :items="AlertChannels"
                                    :use-select-mode="true"
                                    :drop-down-options="{ width: 'auto' }"
                                    :selected-item-key.sync="editUserMarketAlert.Channel"
                                    :width="100"
                                    display-expr="name"
                                    key-expr="id"
                            />
                        </template>
                    </DxSimpleItem>

                    <DxSimpleItem
                            data-field="Type"
                            :col-span="2"
                            :width="100"
                    >
                        <DxLabel text="Move Direction" />
                        <template #default>
                            <DxDropDownButton
                                    :items="AlertTypes"
                                    :use-select-mode="true"
                                    :drop-down-options="{ width: 'auto' }"
                                    :selected-item-key.sync="editUserMarketAlert.Type"
                                    :width="100"
                                    display-expr="name"
                                    key-expr="id"
                            />
                        </template>
                    </DxSimpleItem>

                    <DxSimpleItem
                            data-field="MarketID"
                            :col-span="2"
                    >
                        <DxLabel text="Market" />
                        <template #default>
                            <DxSelectBox
                                    :data-source="markets"
                                    :value.sync="editUserMarketAlert.MarketID"
                                    display-expr="Market.Name"
                                    value-expr="Market.ID"
                                    :width="200"
                                    @value-changed="onMarketsValueChange"
                            />
                        </template>
                    </DxSimpleItem>

                    <DxSimpleItem
                            data-field="ProductID"
                            :col-span="2"
                    >
                        <DxLabel text="Ticker" />
                        <template #default>
                            <DxSelectBox
                                    :data-source="marketProducts && marketProducts.ActiveProducts"
                                    :value.sync="editUserMarketAlert.ProductID"
                                    display-expr="Name"
                                    value-expr="ID"
                                    :width="200"
                            />
                        </template>
                    </DxSimpleItem>

                    <DxSimpleItem
                            data-field="Threshold"
                            :col-span="2"
                    >
                        <DxLabel text="Initial Trigger" />
                        <template #default>
                            <DxNumberBox
                                    :min="threshold.min"
                                    :max="threshold.max"
                                    :step="threshold.step"
                                    :value.sync="editUserMarketAlert.Threshold"
                                    :format="{ type: 'fixedPoint', precision: 4 }"
                                    :height="24"
                                    :width="100"
                                    :input-attr="{ class: 'text-center' }"
                            >
                                <DxNumberBoxButton
                                        :options="minusThresholdEditButton"
                                        name="prevValue"
                                        location="before"
                                />
                                <DxNumberBoxButton
                                        :options="plusThresholdEditButton"
                                        name="nextValue"
                                        location="after"
                                />
                            </DxNumberBox>
                        </template>
                    </DxSimpleItem>

                    <DxSimpleItem
                            data-field="Frequency"
                            :col-span="2"
                    >
                        <DxLabel text="Secondary Trigger" />
                        <template #default>
                            <DxNumberBox
                                    :min="frequency.min"
                                    :max="frequency.max"
                                    :step="frequency.step"
                                    :value.sync="editUserMarketAlert.Frequency"
                                    :format="{ type: 'fixedPoint', precision: 4 }"
                                    :height="24"
                                    :width="100"
                                    :input-attr="{ class: 'text-center' }"
                            >
                                <DxNumberBoxButton
                                        :options="minusFrequencyEditButton"
                                        name="prevValue"
                                        location="before"
                                />
                                <DxNumberBoxButton
                                        :options="plusFrequencyEditButton"
                                        name="nextValue"
                                        location="after"
                                />
                            </DxNumberBox>
                        </template>
                    </DxSimpleItem>

                    <DxSimpleItem
                            data-field="Snooze"
                            :col-span="2"
                    >
                        <DxLabel text="Snooze" />
                        <template #default>
                            <DxSwitch :value.sync="editUserMarketAlert.Snooze"/>
                        </template>
                    </DxSimpleItem>

                    <DxSimpleItem
                            data-field="SnoozeStartTime"
                            :col-span="2"
                            :visible="editUserMarketAlert.Snooze"
                    >
                        <DxLabel text="Snooze Start" />
                        <template #default>
                            <DxDateBox
                                    :value.sync="editUserMarketAlert.SnoozeStartTime"
                                    :disabled="!editUserMarketAlert.Snooze"
                                    :use-mask-behavior="true"
                                    :show-drop-down-button="false"
                                    :width="100"
                                    display-format="HH:mm"
                                    type="time"
                            />
                        </template>
                    </DxSimpleItem>

                    <DxSimpleItem
                            data-field="SnoozeEndTime"
                            :col-span="2"
                            :visible="editUserMarketAlert.Snooze"
                    >
                        <DxLabel text="Snooze End" />
                        <template #default>
                            <DxDateBox
                                    :value.sync="editUserMarketAlert.SnoozeEndTime"
                                    :disabled="!editUserMarketAlert.Snooze"
                                    :use-mask-behavior="true"
                                    :show-drop-down-button="false"
                                    :width="100"
                                    display-format="HH:mm"
                                    type="time"
                            />
                        </template>
                    </DxSimpleItem>

                    <DxSimpleItem
                            :col-span="2"
                    >
                        <template #default>
                            <div style="color: red"><i><small>* Setting Secondary Trigger to zero will ONLY use Initial Trigger</small></i></div>
                        </template>
                    </DxSimpleItem>

                    <DxSimpleItem
                            :col-span="2"
                            css-class="py-0"
                    >
                        <template #default>
                            <div style="color: red"><i><small>* Snooze will assume overnight if PM -> AM</small></i></div>
                        </template>
                    </DxSimpleItem>
                </DxForm>
            </template>
        </DxPopup>
    </div>
</template>

<script>
import { DxForm, DxSimpleItem, DxLabel } from 'devextreme-vue/form';
import { DxNumberBox, DxButton as DxNumberBoxButton } from 'devextreme-vue/number-box';
import { DxDateBox } from 'devextreme-vue/date-box';
import { DxSwitch } from 'devextreme-vue/switch';
import {
    DxDataGrid,
    DxColumn,
    DxHeaderFilter,
    DxSorting,
    DxScrolling,
    DxToolbar,
    DxItem,
} from 'devextreme-vue/data-grid';

import { StatusIndicator } from 'vue-status-indicator';

import DxSelectBox from 'devextreme-vue/select-box';
import DxDropDownButton from 'devextreme-vue/drop-down-button';
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import { DxButton } from 'devextreme-vue/button';

import {confirm} from "devextreme/ui/dialog";

import {API} from "@/API";

const AlertTypes = [
    {
        id: 1,
        name: 'Above',
        icon: 'arrowup'
    },
    {
        id: 0,
        name: 'Both',
        icon: 'expand'
    },
    {
        id: -1,
        name: 'Below',
        icon: 'arrowdown'
    }
]

const AlertChannels = [
    {
        id: 0,
        name: 'All',
        icon: 'todo',
        visible: true,
    },
    {
        id: 1,
        name: 'Notification',
        icon: 'fas fa-solid fa-bell',
        visible: true,
    },
    {
        id: 2,
        name: 'E-Mail',
        icon: 'far fa-envelope',
        visible: true,
    }
]

export default {
    name: "user-market-alerts",

    components: {
        DxNumberBox,
        DxDataGrid,
        DxColumn,
        DxHeaderFilter,
        DxSorting,
        DxScrolling,
        DxSelectBox,
        DxToolbar,
        DxItem,
        DxButton,
        DxPopup,
        DxToolbarItem,
        DxDropDownButton,
        DxForm,
        DxSimpleItem,
        DxLabel,
        StatusIndicator,
        DxNumberBoxButton,
        DxDateBox,
        DxSwitch
    },

    data() {
        return {
            AlertTypes,
            AlertChannels,
            newUserMarketAlert: {},
            editUserMarketAlert: {},
            showAddUserMarketAlertPopup: false,
            showEditUserMarketAlertPopup: false,
            userMarketAlerts: [],
            selectedUserMarketAlert: null,
            markets: [],
            products: [],
            marketProducts: [],

            threshold: {
                min: 0.0100,
                max: 10.0000,
                step: 0.0100
            },

            frequency: {
                min: 0,
                max: 10.0000,
                step: 0.0100
            },

            minusThresholdCreateButton: {
                icon: 'fas fa-chevron-left',
                stylingMode: 'text',

                onClick: (e) => {
                    if(this.newUserMarketAlert.Threshold > this.threshold.min)
                        this.newUserMarketAlert.Threshold -= this.threshold.step
                },
            },
            plusThresholdCreateButton: {
                icon: 'fas fa-chevron-right',
                stylingMode: 'text',

                onClick: (e) => {
                    if(this.newUserMarketAlert.Threshold < this.threshold.max)
                        this.newUserMarketAlert.Threshold += this.threshold.step
                },
            },

            minusThresholdEditButton: {
                icon: 'fas fa-chevron-left',
                stylingMode: 'text',

                onClick: (e) => {
                    if(this.editUserMarketAlert.Threshold > this.threshold.min)
                        this.editUserMarketAlert.Threshold -= this.threshold.step
                },
            },
            plusThresholdEditButton: {
                icon: 'fas fa-chevron-right',
                stylingMode: 'text',

                onClick: (e) => {
                    if(this.editUserMarketAlert.Threshold < this.threshold.max)
                        this.editUserMarketAlert.Threshold += this.threshold.step
                },
            },

            minusFrequencyCreateButton: {
                icon: 'fas fa-chevron-left',
                stylingMode: 'text',

                onClick: (e) => {
                    if(this.newUserMarketAlert.Frequency > this.frequency.min)
                        this.newUserMarketAlert.Frequency -= this.frequency.step
                },
            },
            plusFrequencyCreateButton: {
                icon: 'fas fa-chevron-right',
                stylingMode: 'text',

                onClick: (e) => {
                    if(this.newUserMarketAlert.Frequency < this.frequency.max)
                        this.newUserMarketAlert.Frequency += this.frequency.step
                },
            },

            minusFrequencyEditButton: {
                icon: 'fas fa-chevron-left',
                stylingMode: 'text',

                onClick: (e) => {
                    if(this.editUserMarketAlert.Frequency > this.frequency.min)
                        this.editUserMarketAlert.Frequency -= this.frequency.step
                },
            },
            plusFrequencyEditButton: {
                icon: 'fas fa-chevron-right',
                stylingMode: 'text',

                onClick: (e) => {
                    if(this.editUserMarketAlert.Frequency <= this.frequency.max)
                        this.editUserMarketAlert.Frequency += this.frequency.step
                },
            }
        }
    },

    async beforeMount() {
        this.$root.$on('user-impersonated', await this.Fetch)
        this.$root.$on('user-unpersonated', await this.Fetch)

        await this.Fetch()
    },

    beforeDestroy() {
        this.$root.$off('user-impersonated')
        this.$root.$off('user-unpersonated')
    },

    methods: {
        async Fetch() {
            await Promise.all([
                this.FetchUserMarketAlerts(),
                this.FetchMarkets(),
                this.FetchProducts()
            ])
        },

        FetchUserMarketAlerts() {
            this.$store.dispatch('loading/message', "Retrieving User Market Alerts...")

            API.get('/alerts/markets')
                    .then(response => {
                        this.userMarketAlerts = response.data

                        this.selectedUserMarketAlert = null
                        this.UserAlertsGrid.deselectAll()
                    })
                    .catch(error => {
                        this.$toast.open({
                            message: error.response.data.message,
                            type: 'error',
                        });
                    })
        },

        FetchMarkets() {
            this.$store.dispatch('loading/message', "Retrieving Markets...")

            API.get('/dtn/markets/user')
                    .then(response => {
                        this.markets = response.data
                    })
                    .catch(error => {
                        this.$toast.open({
                            message: error.response.data.message,
                            type: 'error',
                        });
                    })
        },

        FetchProducts() {
            this.$store.dispatch('loading/message', "Retrieving Products...")

            API.get('/dtn/products')
                    .then(response => {
                        this.products = response.data
                    })
                    .catch(error => {
                        this.$toast.open({
                            message: error.response.data.message,
                            type: 'error',
                        });
                    })
        },

        ShowAddAlert(e) {
            this.showAddUserMarketAlertPopup = true
        },

        ShowEditAlert() {
            this.showEditUserMarketAlertPopup = true
        },

        RefreshUserAlerts(e) {
            this.FetchUserMarketAlerts()
        },

        NewUserAlert() {
            return {
                Channel: 0,
                Type: 1,
                Threshold: 0.0100,
                Frequency: 0.0000,
                MarketID: null,
                ProductID: null,
                Snooze: false,
                SnoozeStartTime: "17:00",
                SnoozeEndTime: "08:00",
                Active: true
            }
        },

        CreateUserAlert(e) {
            this.$store.dispatch('loading/message', "Creating New User Market Alert...")

            API.post('/alerts/markets', this.newUserMarketAlert)
                    .then(response => {
                        this.userMarketAlerts = response.data.data

                        this.selectedUserMarketAlert = null
                        this.UserAlertsGrid.deselectAll()
                    })
                    .catch(error => {
                        this.$toast.open({
                            message: error.response.data.message,
                            type: 'error',
                        });
                    })
                    .finally(() => {
                        this.showAddUserMarketAlertPopup = false
                    })

        },

        EditUserAlert(e) {
            this.$store.dispatch('loading/message', "Updating User Market Alert...")

            API.put(`/alerts/markets/${this.selectedUserMarketAlert.ID}`, this.editUserMarketAlert)
                    .then(response => {
                        this.userMarketAlerts = response.data.data

                        this.selectedUserMarketAlert = null
                        this.UserAlertsGrid.deselectAll()
                    })
                    .catch(error => {
                        this.$toast.open({
                            message: error.response.data.message,
                            type: 'error',
                        });
                    })
                    .finally(() => {
                        this.showEditUserMarketAlertPopup = false
                    })
        },

        DeleteUserAlert(e) {
            let result = confirm("Are you sure?", `Confirm Deletion`);

            result.then((dialogResult) => {
                if (dialogResult) {
                    this.$store.dispatch('loading/message', "Deleting User Market Alert...")

                    API.delete(`/alerts/markets/${this.selectedUserMarketAlert.ID}`)
                            .then(response => {
                                this.userMarketAlerts = response.data.data

                                this.selectedUserMarketAlert = null
                                this.UserAlertsGrid.deselectAll()
                            })
                            .catch(error => {
                                this.$toast.open({
                                    message: error.response.data.message,
                                    type: 'error',
                                });
                            })
                }
            })
        },

        ToggleUserAlert(e) {
            let result = confirm("Are you sure?", `${ this.selectedUserMarketAlert.Active ? 'Disable' : 'Enable' } Alert`);

            result.then((dialogResult) => {
                if (dialogResult) {
                    this.$store.dispatch('loading/message', "Toggling User Market Alert...")

                    API.put(`/alerts/markets/toggle/${this.selectedUserMarketAlert.ID}`)
                            .then(response => {
                                this.userMarketAlerts = response.data.data

                                this.selectedUserMarketAlert = null
                                this.UserAlertsGrid.deselectAll()
                            })
                            .catch(error => {
                                this.$toast.open({
                                    message: error.response.data.message,
                                    type: 'error',
                                });
                            })
                }
            })
        },

        CloseEditAlert(e) {
            this.showEditUserMarketAlertPopup = false
            this.editUserMarketAlert = {}
        },

        onSelectionChanged({ selectedRowsData }) {
            const data = selectedRowsData[0];

            this.selectedUserMarketAlert = data ? data : null

        },

        onAddUserAlertShown(e) {
            this.newUserMarketAlert = this.NewUserAlert()
            this.UserAlertsGrid.repaint()
        },

        onEditUserAlertShown(e) {
            this.editUserMarketAlert = {...this.selectedUserMarketAlert}
            this.UserAlertsGrid.repaint()
        },

        getSizeQualifier(width) {
            if (width < 768) return "xs";
            if (width < 992) return "sm";
            if (width < 1200) return "md";
            return "lg";
        },

        onMarketsValueChange(e) {
            this.marketProducts = this.products.find(market => market.ID === e.value)
        }
    },

    computed: {
        User() {
            return this.$store.getters['user/user']
        },

        Profile() {
            return this.$store.getters['user/profile']
        },

        Authenticated() {
            return this.$store.getters["user/authenticated"];
        },

        MarketAlerts() {
            return this.$store.getters['user/market_alerts']
        },

        UserAlertsGrid() {
            return this.$refs.UserAlertsGridRef.instance
        },

        AlertChannelsFiltered() {
            let channels = [...this.AlertChannels]

            channels[0].visible = this.User.Phone && this.User.Email
            channels[1].visible = this.User.Phone
            channels[2].visible = this.User.Email

            return channels
        }
    }
}
</script>

<style scoped>

::v-deep #gridContainer span.current-value {
    display: inline-block;
    margin-right: 5px;
}

::v-deep #gridContainer span.Diff {
    width: 50px;
    display: inline-block;
}

::v-deep #gridContainer .inc .arrow,
::v-deep #gridContainer .dec .arrow {
    display: inline-block;
    height: 10px;
    width: 10px;
    background-repeat: no-repeat;
    background-size: 10px 10px;
}

::v-deep #gridContainer .inc .arrow {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAADKSURBVHjaYtTaLs1ABEiG0nPRJa56PEHhsxBhmCUQT4OyrwHxcXyKmQgYJgHE64CYDYrXQcXIMhCbAcgWkGzgNKh38QUB0QamIUUErkhKI9ZAGyCeTERkTYaqxWsgKA2txhdG6GGsvUNGGpeBRMUiGhCFGsqGzUBQQJsxkA5AemaiG5hDIBIIgQSgK0FmMDACs549kN5FZLjhA7+A2A2U9YSAOBeLAk4gnoBDczoOcSFGPIUDPxB/wCHHiKtwYGKgMhg1cBAaCBBgAJTUIL3ToPZfAAAAAElFTkSuQmCC');
}

::v-deep #gridContainer .dec .arrow {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAADJSURBVHjaYvzPgBfgkhYA4o8QFahKmBioDEYNHIQGsgBxIBCLkqgvAYi/g1mMjMjir0EJzR6If/6HpChKMMgMe3DKBeIcKhiY8x/MYoDj+RQYNgdkGLqBbEB8kgzDToL1YjEQhKWB+BUJhj0H64Eahs1AELYhMpJ+gtUiGYbLQBBOI8LANLBaIg1kAAc0vkiAqSPBQFAkHcNi2DGoHMkGgrAENOCRI0ECRQ2JBoKwJTQCfkLZDPgMZPxPXN5NhtJzMSsJVBMAAgwAyWSY2svfmrwAAAAASUVORK5CYII=');
}

::v-deep .dx-toolbar {
    background-color: #f8f9fa !important;
}

::v-deep .dx-datagrid-header-panel {
    background-color: #f8f9fa !important;
}

.inline-form {
    float: left;
    overflow: hidden;
    white-space: nowrap;
    cursor: default;
}

::v-deep .dx-selection td{
    background-color: #5990be !important;
    color: white !important;
}

</style>
