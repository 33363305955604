<template>
    <div>
        <b-container fluid class="px-2 pb-0 bg-light">
            <b-row no-gutters class="d-flex">
                <b-col xs="auto" sm="auto" md="12" lg="12" xl="12" class="py-2 w-100">
                    <DxDataGrid
                            id="gridContainer"
                            ref="UploadedDocumentsGridRef"
                            no-data-text="No User Documents..."
                            :data-source.sync="documents"
                            :show-borders="true"
                            :show-row-lines="true"
                            :column-auto-width="true"
                            :two-way-binding-enabled="false"
                            :column-hiding-enabled="false"
                            :selection="{ mode: 'none' }"
                            :word-wrap-enabled="true"
                            key-expr="ID"
                            width="auto"
                            class="p-0"
                            @row-prepared="onRowPrepared"
                        >
                        <DxHeaderFilter :visible="true"/>

                        <DxSorting show-sort-indexes />
                        <DxScrolling row-rendering-mode="virtual"/>
                        <DxPaging :page-size="20"/>
                        <DxPager :show-info="true" :show-navigation-buttons="true" display-mode="full"/>

                        <DxColumn
                                data-field="DocumentCreated"
                                data-type="datetime"
                                format="MM/dd/yyyy hh:mm a"
                                caption="Created"
                                sort-order="desc"
                                :sort-index="0"
                                :allow-header-filtering="false"
                                :width="150"
                        />
                        <DxColumn
                                data-field="User.FullName"
                                caption="User"
                                :visible="true"
                                :allow-header-filtering="true"
                                :allow-sorting="false"
                                :width="150"
                        />
                        <DxColumn
                                data-field="DocumentType"
                                caption="Type"
                                :visible="true"
                                :allow-header-filtering="true"
                                :allow-sorting="false"
                                :width="150"
                        />
                        <DxColumn
                                data-field="Customer.CustomerNo"
                                caption="Customer No."
                                :hiding-priority="1"
                                :visible="true"
                                :allow-header-filtering="true"
                                :allow-sorting="false"
                                :width="115"
                        />
                        <DxColumn
                                data-field="Customer.CustomerName"
                                caption="Customer Name"
                                :visible="true"
                                :allow-header-filtering="true"
                                :allow-sorting="true"
                                :width="250"
                        />
                        <DxColumn
                                data-field="Comments"
                                :hiding-priority="0"
                                :allow-header-filtering="false"
                                :allow-sorting="false"
                        />

                        <DxColumn
                                data-field="IsSent"
                                caption="Sent"
                                data-type="boolean"
                                false-text="No"
                                true-text="Yes"
                                alignment="center"
                                :hiding-priority="0"
                                :allow-header-filtering="false"
                                :allow-sorting="false"
                                :width="70"
                                :show-editor-always="false"
                                :customize-text="(cellInfo) => { return cellInfo.value ? 'Yes' : 'No' }"
                        />

                        <DxColumn
                                type="buttons"
                                hint="Refresh Uploaded Documents"
                                :width="50"
                        >
                            <DxButton
                                    hint="Download Document"
                                    icon="far fa-sharp fa-solid fa-eye"
                                    :visible="(e) => { return e.row.data.File }"
                                    :element-attr="{ class: 'text-black' }"
                                    :on-click="OpenDocument"
                            />
                        </DxColumn>
                        <DxToolbar>
                            <DxItem location="after" template="refreshTemplate"/>
                        </DxToolbar>

                        <template #refreshTemplate>
                            <DxToolbarButton icon="refresh" @click="FetchDocuments"/>
                        </template>
                    </DxDataGrid>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import {
    DxDataGrid,
    DxColumn,
    DxHeaderFilter,
    DxSorting,
    DxScrolling,
    DxToolbar,
    DxItem,
    DxPaging,
    DxPager,
    DxButton
} from 'devextreme-vue/data-grid';

import { DxButton as DxToolbarButton } from 'devextreme-vue/button';

import {API} from "@/API";

const DocumentTypes = [ 'BOL, Drop Ticket, Freight Bill,  Other' ]

export default {
    name: "uploaded-documents-grid",

    components: {
        DxDataGrid,
        DxColumn,
        DxHeaderFilter,
        DxSorting,
        DxScrolling,
        DxPaging,
        DxPager,
        DxToolbar,
        DxItem,
        DxButton,
        DxToolbarButton
    },

    data() {
        return {
            DocumentTypes,
            documents: [],
        }
    },

    async beforeMount() {
        this.$root.$on('user-impersonated', await this.Fetch)
        this.$root.$on('user-unpersonated', await this.Fetch)

        await this.FetchDocuments()
    },

    beforeDestroy() {
        this.$root.$off('user-impersonated')
        this.$root.$off('user-unpersonated')
    },

    methods: {
        FetchDocuments() {
            this.$store.dispatch('loading/message', "Retrieving User Documents...")

            API.get(`/documents/uploaded/${this.Customer.CustomerNo}`)
                    .then(response => {
                        this.documents = response.data
                        this.UploadedDocumentsGrid.deselectAll()
                    })
                    .catch(error => {
                        this.$toast.open({
                            message: error.response.data.message,
                            type: 'error',
                        });
                    })
        },

        getSizeQualifier(width) {
            if (width < 768) return "xs";
            if (width < 992) return "sm";
            if (width < 1200) return "md";
            return "lg";
        },

        async OpenDocument(e) {
            window.open(e.row.data.File)
        },

        onRowPrepared(e) {
            if (e.rowType === "data" && !e.data.IsSent) {
                    e.rowElement.style.backgroundColor = '#FAA0A0'
            }
        }
    },

    computed: {
        User() {
            return this.$store.getters['user/user']
        },

        Profile() {
            return this.$store.getters['user/profile']
        },

        Customer() {
            return this.$store.getters['customers/customer']
        },

        Authenticated() {
            return this.$store.getters["user/authenticated"];
        },

        UploadedDocumentsGrid() {
            return this.$refs.UploadedDocumentsGridRef.instance
        }
    }
}
</script>

<style scoped>
::v-deep .dx-toolbar {
    background-color: #f8f9fa !important;
}

::v-deep .dx-datagrid-header-panel {
    background-color: #f8f9fa !important;
}

.inline-form {
    float: left;
    overflow: hidden;
    white-space: nowrap;
    cursor: default;
}

::v-deep .dx-selection td{
    background-color: #5990be !important;
    color: white !important;
}

::v-deep .dx-datagrid .dx-link {
    color: black !important;
}

::v-deep .dx-datagrid-content .dx-datagrid-table .dx-data-row .dx-command-edit-with-icons {
    vertical-align: middle !important;
}

</style>
