/* eslint-disable */

const LOADING_MESSAGE = 'Retrieving Data...'

const loading = {
	namespaced: true,
	
	state: {
		loading: false,
		message: LOADING_MESSAGE
	},
	
	actions: {
		message({commit}, message) {
			commit('SET_MESSAGE', message);
		},
		
		start({commit}) {
			commit('START_LOADING');
		},
		
		end({commit}) {
			commit('END_LOADING');
		}
	},
	
	mutations: {
		SET_MESSAGE(state, message) {
			state.message = message
		},
		
		START_LOADING(state) {
			state.loading = true
			state.message = state.message ? state.message : LOADING_MESSAGE
		},
		
		END_LOADING(state) {
			state.loading = false
			state.message = LOADING_MESSAGE
		}
	},
	
	getters: {
		loading: state => state.loading,
		message: state => state.message
	}
}

export default loading
