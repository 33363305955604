/* eslint-disable */

import {API} from "@/API";
import store from "@/stores/store";
import strings from "@/utils/string-helpers"

const user = {
	namespaced: true,

	state: {
		status: null,
		token: localStorage.getItem('token') || '',
		user: JSON.parse(localStorage.getItem('user')) || null,
		roles: JSON.parse(localStorage.getItem('roles')) || null,
		permissions: JSON.parse(localStorage.getItem('permissions') || null),
		profile: JSON.parse(localStorage.getItem('profile')) || null,
	},

	mutations: {
		auth_success(state, payload) {
			state.token = payload.token
			state.user = payload.user
			state.roles = payload.roles
			state.permissions = payload.permissions
			state.profile = payload.profile
		},
		
		auth_error(state, payload) {
			state.status = payload
		},
		
		logout(state) {
			state.status = null
			state.token = ''
			state.roles = []
			state.permissions = []
			
			localStorage.removeItem('token')
			localStorage.removeItem('roles')
			localStorage.removeItem('permissions')
			
			delete API.defaults.headers.common['Authorization']
			
			store.dispatch('customers/CLEAR_CUSTOMERS')
		},
		
		update_profile(state, payload) {
			state.user = payload.user
			state.profile = payload.profile
		},
	},

	actions: {
		login({commit}, credentials) {
			store.dispatch('loading/message', 'Logging In...')
			return new Promise((resolve, reject) => {
				API
					.post("/auth/login", credentials)
					.then(response => {
						const token = response.data.token
						const user = response.data.user
						const roles = response.data.roles
						const permissions = response.data.permissions
						const profile = response.data.profile
						
						localStorage.setItem('token', token)
						localStorage.setItem('user', JSON.stringify(user))
						localStorage.setItem('roles', JSON.stringify(roles))
						localStorage.setItem('permissions', JSON.stringify(permissions))
						localStorage.setItem('profile', JSON.stringify(profile))
						
						API.defaults.headers['Authorization'] = `Bearer ${token}`
						
						commit('auth_success', response.data)
						
						resolve(response.data)
					})
					.catch(error => {
						commit('auth_error', error.response.data)
						
						reject(error.response.data)
					});
			})
		},
		
		logout({commit}) {
			return new Promise((resolve, reject) => {
				store.dispatch('loading/message', 'Logging Out...')
				
				commit('logout')
				
				API
					.post("/auth/logout")
					.then(response => {
						resolve()
					})
					.catch(error => {
						commit('auth_error', error.response.data)
						
						reject(error.response.data)
					});
			})
		},
		
		update_profile({commit}, data) {
			return new Promise((resolve, reject) => {
				store.dispatch('loading/message', 'Updating Profile..')
				
				const impersonating = store.getters["impersonate/impersonating"]
				
				API
					.put("/user/profile", data)
					.then(response => {
						if(impersonating) {
							localStorage.setItem('impersonate.user', JSON.stringify(response.data))
							
							commit('impersonate/impersonate', response.data)
						} else {
							localStorage.setItem('user', JSON.stringify(response.data.user))
							localStorage.setItem('profile', JSON.stringify(response.data.profile))
							
							commit('update_profile', response.data)
						}

						resolve(response.data)
					})
			})
		},
		
		forgot_password({commit}, data) {
			return new Promise((resolve, reject) => {
				store.dispatch('loading/message', 'Requesting..')
				
				API.post('/auth/password/forgot', data)
					.then(response => {
						resolve(response)
					})
					.catch(error => {
						reject(error)
					})
			})
		},
	},

	getters: {
		authenticated: state => !!state.token,
		user: state => state.user,
		roles: state => state.roles,
		permissions: state => state.permissions,
		token: state => state.token,
		status: state => state.status,
		profile: state => state.profile,
		name: state => state.user.FirstName ? state.user.FirstName.concat(' ', state.user.LastName) : state.user.Email ? state.user.Email : state.user.Phone,
		initials: state => strings.initials(state.user.FirstName ? state.user.FirstName.concat(' ', state.user.LastName) : "")
	}
};

export default user;
