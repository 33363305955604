import axios from 'axios';
import store from "@/stores/store";
import router from "@/router";


export const API = axios.create({
	baseURL: process.env.VUE_APP_API_URL,
	responseType: "json",
	withCredentials: true,
	
	headers: {
		Accept: 'application/json',
		Authorization: `Bearer ${store.getters['user/token']}`,
	},
})


API.interceptors.request.use((config) => {
	store.dispatch('loading/start')

	if(store.getters["impersonate/impersonating"])
		config.headers['X-Impersonate'] = store.getters["impersonate/user"].ID
	else
		delete config.headers.common['X-Impersonate']

	return config;
}, (error) => {
	store.dispatch('loading/end')
	
	return Promise.reject(error);
})

API.interceptors.response.use((config) => {
	store.dispatch('loading/end')
	return config
}, (error) => {
	store.dispatch('loading/end')
	
	switch (error.response.status) {
		case 401:
			store.commit('user/logout');
			router.push('/login');
			break;
		
		case 403:
			//store.dispatch('user/logout')
			router.push({
				name: 'error',
				params: { code: 403, message: error.response.data.message }
			})
			break;
		
		case 418:
			router.push(`/enroll/${error.response.data.data.Token}`)
			break;
			
		case 503:
			store.commit('user/logout');
			router.push('/login');
			break;
		
		default:
			store.dispatch('loading/end')
			break;
	}
	
	return Promise.reject(error);
})
