<template>
    <single-page :requires-auth="false" :fluid="true">
        <b-container fluid class="px-2">
            <b-row no-gutters class="d-flex">
                <b-col xs="auto" sm="auto" md="auto" lg="auto" xl="auto" class="py-2">
                    <DashboardPanel title="Outages">
                        <template #toolbar>
                            <DxButton icon="refresh" class="float-right" @click="RefreshOutages"/>
                        </template>
                        <template #content>
                            <OutagesGrid :height="700" ref="OutagesGridRef"/>
                        </template>
                    </DashboardPanel>
                </b-col>
            </b-row>
        </b-container>
    </single-page>
</template>

<script>
import OutagesGrid from "@/components/OutagesGrid";
import SinglePage from "@/components/SinglePage";
import DashboardPanel from "@/components/DashboardPanel";
import DxButton from 'devextreme-vue/button'

export default {
    name: "outages-view",

    components: {
        OutagesGrid,
        SinglePage,
        DashboardPanel,
        DxButton
    },

    data() {
        return {

        }
    },

    mounted() {
        this.RefreshOutages()
    },

    methods: {
        RefreshOutages(e) {
            this.OutagesGrid.Fetch()
        }
    },

    computed: {
        OutagesGrid() {
            return this.$refs.OutagesGridRef
        }
    }
}
</script>

<style scoped>

</style>
