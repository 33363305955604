<template>
    <single-page :requires-auth="true">
        <b-container fluid>
            <b-row class="p-2 d-flex">
                <b-col xs="auto" sm="auto" md="4" lg="4" xl="4" class="px-0">
                    <b-card-group deck>
                        <b-card bg-variant="light" header="Products Color Management" class="text-center shadow-sm font-weight-bold">
                            <b-card-text>
                                <div class="px-2 py-2">
                                    <DxDataGrid
                                            ref="ProductsGridRef"
                                            no-data-text="No Product Records..."
                                            :data-source.sync="products"
                                            :show-borders="true"
                                            :show-row-lines="true"
                                            :column-auto-width="true"
                                            :two-way-binding-enabled="false"
                                            :column-hiding-enabled="false"
                                            :focused-row-enabled="true"
                                            :show-row-indicator="true"
                                            key-expr="Description"
                                            width="auto"
                                            :height="650"
                                            class="p-0"
                                    >
                                        <DxEditing :allow-updating="true" :allow-adding="false" mode="cell"/>
                                        <DxFilterRow :visible="true"/>
                                        <DxHeaderFilter :visible="true"/>
                                        <DxSorting show-sort-indexes />
                                        <DxScrolling mode="infinite"/>
                                        <DxGroupPanel :visible="true"/>
                                        <DxGrouping :auto-expand-all="false"/>
                                        <DxToolbar>
                                            <DxItem location="before" template="saveTemplate"/>
                                            <DxItem location="after" template="refreshTemplate"/>
                                        </DxToolbar>

                                        <DxColumn
                                            data-field="Description"
                                            sort-order="asc"
                                            :allow-header-filtering="false"
                                            :allow-updating="false"
                                            :allow-editing="false"
                                        />
                                        <DxColumn
                                            data-field="ShortDescription"
                                            :allow-sorting="false"
                                            :allow-updating="false"
                                            :editor-options="{ maxLength: 20 }"
                                        />
                                        <DxColumn
                                            data-field="Color"
                                            :allow-sorting="false"
                                            :allow-editing="true"
                                            :width="115"
                                            :editor-options="{ itemTemplate: 'productColorItemTemplate' }"
                                        >
                                            <DxLookup
                                                :data-source="colors"
                                                display-expr="Name"
                                                value-expr="Color"
                                            />
                                        </DxColumn>

                                        <template #saveTemplate>
                                            <DxButton icon="save" hint="Save Product Color Configurations" @click="Save"/>
                                        </template>

                                        <template #refreshTemplate>
                                            <DxButton icon="refresh" hint="Refresh Product COlor Configurations" @click="Refresh"/>
                                        </template>

                                        <template #productColorItemTemplate="{ data }">
                                            <ProductColorCell :data="data"/>
                                        </template>
                                    </DxDataGrid>
                                </div>
                            </b-card-text>
                        </b-card>
                    </b-card-group>
                </b-col>
            </b-row>
        </b-container>
    </single-page>
</template>

<script>
import SinglePage from "@/components/SinglePage";
import {
    DxDataGrid,
    DxColumn,
    DxHeaderFilter,
    DxSorting,
    DxGrouping,
    DxGroupPanel,
    DxFilterRow,
    DxScrolling,
    DxToolbar,
    DxItem,
    DxLookup,
    DxEditing,
} from 'devextreme-vue/data-grid';

import { DxButton } from 'devextreme-vue/button';
import { API } from "@/API";
import { confirm } from "devextreme/ui/dialog";
import { trigger } from "devextreme/events";
import ProductColorCell from "@/components/ProductColorCell.vue";

export default {
    name: "product-colors-view",

    components: {
        ProductColorCell,
        SinglePage,
        DxDataGrid,
        DxColumn,
        DxHeaderFilter,
        DxSorting,
        DxGrouping,
        DxFilterRow,
        DxGroupPanel,
        DxScrolling,
        DxToolbar,
        DxItem,
        DxLookup,
        DxButton,
        DxEditing
    },

    data() {
        return {
            products: [],
            colors: [],
            selected: {},
        }
    },

    async mounted() {
        await this.Fetch()
    },

    methods: {
        async Fetch() {
            await Promise.all([
                this.FetchProducts(),
                this.FetchColors()
            ])
        },

        async FetchProducts() {
            if (this.Authenticated) {
                API.get('/admin/products')
                        .then(response => {
                            this.products = response.data
                        })
                        .catch(error => {
                            this.$toast.open({
                                message: error.response.data.message,
                                type: 'error',
                            });
                        })
            } else {
                this.$router.push('/login')
            }
        },

        async FetchColors() {
            if (this.Authenticated) {
                API.get('/admin/products/colors')
                        .then(response => {
                            this.colors = response.data
                        })
                        .catch(error => {
                            this.$toast.open({
                                message: error.response.data.message,
                                type: 'error',
                            });
                        })
            } else {
                this.$router.push('/login')
            }
        },

        async Refresh(e) {
            await this.Fetch()
        },

        async Save(e) {
            API.put('/admin/products/colors', this.products)
                    .then(response => {
                        this.products = response.data
                    })
        }
    },

    computed: {
        User() {
            return this.$store.getters['user/user']
        },

        Authenticated() {
            return this.$store.getters["user/authenticated"];
        },

        ProductsGrid() {
            return this.$refs.ProductsGridRef.instance
        }
    }
}
</script>

<style scoped>
    ::v-deep .dx-toolbar {
        background-color: #f8f9fa !important;
    }

    ::v-deep .dx-datagrid-header-panel {
        background-color: #f8f9fa !important;
    }

    ::v-deep .dx-datagrid-revert-tooltip {
        display: none;
    }
</style>
