<template>
    <footer class="footer d-none d-lg-block">
        <b-container fluid>
            <b-row :cols="12">
                <b-col cols="2">
                    <i>{{ AppInfo.company }}, LLC, Copyright © {{ new Date().getFullYear() }}</i>
                </b-col>
                <b-col class="link text-center">
                    <a @click.prevent="openPrivacyNotice" class="footer-link" href="">Privacy Notice</a> <span >|</span> <a @click.prevent="openEULA" class="footer-link" href="">End User License Agreement</a> | <a @click.prevent="openDOA" class="footer-link" href="">Digital Offering Agreement</a>
                </b-col>
                <b-col cols="2" v-if="environment.NODE_ENV !== 'production'" class="text-right mr-2">
                    <h5 class="text-light font-weight-bolder">{{ environment.VUE_APP_DISTRO }} Version</h5>
                </b-col>
            </b-row>
        </b-container>
    </footer>
</template>

<script>
    export default {
        data() {
            return {
                environment: process.env
            }
        },

        methods: {
            openPrivacyNotice(e) {
                window.open('/legal/privacy-notice.htm', "_blank")
            },

            openEULA(e) {
                window.open('/legal/enduser-agreement.htm', "_blank")
            },

            openDOA(e) {
                window.open('/legal/digitaloffering-agreement.htm', "_blank")
            }
        },

        computed: {
            AppInfo() {
                return this.$appInfo
            }
        }
    }
</script>

<style lang="scss">
@import "../themes/generated/variables.base.scss";
@import "../fm-styles";

.footer {
    display: block;
    background-color: $FuelMastersBlue;
    color: white;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 3px;
    padding-bottom: 6px;
    padding-left: 10px;
}

.footer-link {
    color: white !important;
    font-weight: bold;
    text-decoration: none;
}
.footer-link:hover {
    color: white !important;
    font-weight: bold;
    text-decoration: underline;
}

</style>
