<template>
    <div :class="{ inc: cellData.Change > 0, dec: cellData.Change < 0 }">
        <span class="current-value">{{ cellData.Change }}</span>
        <span class="arrow"/>
    </div>
</template>

<script>
export default {
    props: {
        cellData: {
            type: Object,
            default: () => {}
        }
    }
};
</script>
