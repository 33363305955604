<template>
    <single-page :requires-auth="true">
        <b-container fluid class="pt-2">
            <b-row cols-sm="12" cols-md="12" cols-lg="12" cols-xl="12" align-h="left">
                <b-col v-if="prices.length === 0">
                    <h4 class="text-uppercase text-center text-danger pt-3">No Pricing Records Found for Customer: {{ Customer.CustomerName }}</h4>
                </b-col>
                <b-col v-else sm="auto" md="auto" lg="8" xl="7">
                    <b-container fluid class="py-2 border rounded bg-light shadow-sm">
                        <b-row cols-sm="12" cols-md="6" cols-lg="6" cols-xl="5" class="my-1" align-h="left">
                            <b-col sm="10" md="6" lg="5" xl="5">
                                <DxDropDownBox
                                        ref="LocationsDropDownRef"
                                        :value="selectedLocation && selectedLocation.LocationGroup"
                                        :disabled="locations.length === 0"
                                        display-expr="LocationGroup"
                                        :data-source="locations"
                                        :defer-rendering="false"
                                        value-expr="LocationGroup"
                                        placeholder="Select Location..."
                                        width="100%"
                                >
                                    <DxDropDownOptions width="auto" height="auto" />
                                    <template #content>
                                        <DxDataGrid
                                                ref="LocationsGridRef"
                                                no-data-text="No Locations Found..."
                                                key-expr="LocationGroup"
                                                style="background-color: #fff"
                                                width="100%"
                                                height="100%"
                                                :defer-rendering="false"
                                                :data-source="locations"
                                                :hover-state-enabled="true"
                                                :show-borders="true"
                                                :show-row-lines="true"
                                                :column-auto-width="true"
                                                :column-hiding-enabled="false"
                                                :selected-row-keys="[selectedLocation && selectedLocation.LocationGroup]"
                                                @selection-changed="onLocationSelectionChanged"
                                        >
                                            <DxScrolling mode="virtual"/>
                                            <DxPaging :enabled="true" :page-size="10"/>
                                            <DxSelection mode="single"/>
                                            <DxSorting :show-sort-indexes="false"/>
                                            <DxColumn
                                                    caption="Location"
                                                    data-field="LocationGroup"
                                                    sort-order="asc"
                                                    :sort-index="0"
                                            />
                                            <DxColumn
                                                    caption="City"
                                                    :calculate-display-value="(location) => { return location.LocationGroup ? location.LocationGroup : location.Terminal.City }"
                                            />
                                            <DxColumn
                                                    caption="State"
                                                    data-field="Terminal.State"
                                                    :width="85"
                                            />
                                        </DxDataGrid>
                                    </template>
                                </DxDropDownBox>
                            </b-col>
                            <b-col sm="2" md="6" lg="7" xl="7">
                                <DxButton
                                        icon="refresh"
                                        hint="Refresh Prices"
                                        class="float-right"
                                        :disabled="!selectedProducts"
                                        @click="Refresh"
                                />
                            </b-col>
                        </b-row>

                        <b-row cols-sm="12" cols-md="6" cols-lg="6" cols-xl="5" class="my-1" align-h="left">
                            <b-col sm="10" md="6" lg="5" xl="5">
                                <DxSelectBox
                                        :data-source="states"
                                        :value="selectedDestinationState"
                                        :disabled="!selectedLocation"
                                        placeholder="Select Destination State (optional)..."
                                        @value-changed="onLoadNumberDestinationsValueChanged"
                                />
                            </b-col>
                        </b-row>

                        <b-row cols-sm="12" cols-md="6" cols-lg="6" cols-xl="5" class="my-1" align-h="left">
                            <b-col sm="10" md="6" lg="5" xl="5">
                                <DxTagBox
                                        :data-source="products"
                                        :disabled="!selectedLocation"
                                        :show-selection-controls="true"
                                        :value.sync="selectedProducts"
                                        :multiline="true"
                                        :max-displayed-tags="4"
                                        :show-multi-tag-only="false"
                                        display-expr="ItemCodeDesc"
                                        value-expr="ItemCodeDesc"
                                        placeholder="Select Product(s)..."
                                        apply-value-mode="useButtons"
                                        @value-changed="onProductValueChanged"
                                />
                            </b-col>
                        </b-row>

                        <b-row cols-sm="12" cols-md="12" cols-lg="6" cols-xl="6" class="pt-2" align-h="left">
                            <b-col sm="12" md="auto" lg="auto" xl="auto">
                                <DxDataGrid
                                    width="100%"
                                    ref="PricesDataGridRef"
                                    no-data-text="No Pricing Records..."
                                    :data-source="filtered"
                                    :show-borders="true"
                                    :show-row-lines="true"
                                    :column-auto-width="true"
                                    :repaint-changes-only="false"
                                >
                                    <DxSorting :show-sort-indexes="false"/>

                                    <DxColumn
                                          sort-order="asc"
                                        data-field="Rank"
                                        :width="65"
                                        :sort-index="0"
                                        :allow-sorting="false"
                                    />
                                    <DxColumn
                                        data-field="SupplierName"
                                        caption="Supplier"
                                        :allow-sorting="false"
                                    />
                                    <DxColumn
                                        data-field="ItemCodeDesc"
                                        group-cell-template="groupCellTemplate"
                                        :visible="false"
                                    />
                                    <DxColumn
                                        caption="TCN#"
                                        data-field="Terminal.TerminalNo"
                                        :width="100"
                                        :visible="IsDevEnvironment"
                                        :allow-sorting="false"
                                    />
                                    <DxColumn
                                        data-field="LocationGroup"
                                        caption="Terminal"
                                        :calculate-display-value="(price) => { return price.Terminal.Name }"
                                        :allow-sorting="false"
                                    />
                                    <DxColumn
                                        data-field="SellingPrice"
                                        caption="Price"
                                        alignment="right"
                                        data-type="float"
                                        format="$ 0#.0000"
                                        :width="75"
                                        :visible="(HasCustomerBuyerPermissions || HasCustomerBuyerRole || HasCustomerAdministratorRole)"
                                        :allow-sorting="false"
                                    />
                                    <DxColumn
                                        data-field="LoadNumber"
                                        width="auto"
                                        :calculate-display-value="calculateLoadNumber"
                                        :visible="(HasCustomerDriverPermissions || HasCustomerDriverRole || HasCustomerAdministratorRole)"
                                        :allow-sorting="false"
                                    />
                                    <DxColumn
                                        sort-order="desc"
                                        data-field="EffectiveDateTime"
                                        data-type="datetime"
                                        format="MM/dd/yyyy hh:mm a"
                                        caption="Effective Date"
                                        :sort-index="1"
                                        :allow-sorting="false"
                                    />

                                    <template #groupCellTemplate="{ data }">
                                        <div>{{data.value}}</div>
                                    </template>
                                </DxDataGrid>
                            </b-col>
                        </b-row>
                    </b-container>
                </b-col>
            </b-row>
        </b-container>
    </single-page>
</template>

<script>
import SinglePage from "@/components/SinglePage";
import {DxColumn, DxDataGrid, DxPaging, DxScrolling, DxSelection, DxSorting} from 'devextreme-vue/data-grid';
import {DxDropDownBox, DxDropDownOptions} from 'devextreme-vue/drop-down-box';
import {DxButton} from "devextreme-vue";
import DxTagBox from 'devextreme-vue/tag-box';
import DxSelectBox from 'devextreme-vue/select-box';

import {API} from "@/API";

import permissions from "@/permissions";

function unique(arr, keyProps) {
    const kvArray = arr.map(entry => {
        const key = keyProps.map(k => entry[k]).join('|');
        return [key, entry];
    });
    const map = new Map(kvArray);
    return Array.from(map.values());
}

export default {
    name: "customer-pricing-live-loading",

    components: {
        SinglePage,
        DxDataGrid,
        DxColumn,
        DxSorting,
        DxDropDownOptions,
        DxButton,
        DxDropDownBox,
        DxSelection,
        DxScrolling,
        DxPaging,
        DxTagBox,
        DxSelectBox
    },

    data() {
        return {
            permissions,
            prices: [],
            locations: null,
            states: [],
            products: null,
            terminals: [],
            filtered: [],
            selectedLocation: null,
            selectedProducts: null,
            selectedDestinationState: null,

            dropDownOptions: {
                toolbarItems: [
                    {
                        widget: "dxButton",
                        toolbar: "bottom",
                        location: "before",
                        options: {
                            text: "Today",
                            onClick: function(e) {
                                e.component.option("value", new Date())
                                e.component.close();
                            }
                        }
                    }
                ]
            }
        }
    },

    beforeDestroy() {
        this.$root.$off('user-impersonated')
        this.$root.$off('user-unpersonated')

        this.$root.$off('customer-changed')
        this.$root.$off('bid-template-changed')
    },

    beforeRouteEnter(to, from, next) {
        next(vm => {
            let roles = to.meta.requiredRoles.join("|")

            if (vm.$gates.hasAnyRole(roles))
                next()
            else
                next('/home')
        })
    },

    async mounted() {
        this.$root.$on('user-impersonated', this.Fetch)
        this.$root.$on('user-unpersonated', this.Fetch)

        await this.Fetch()

        this.$root.$on('customer-changed', async (customer) => {
            this.selectedLocation = null
            this.selectedProducts = null

            this.filtered = []
            this.prices = []

            await this.Fetch()
        })

        this.$root.$on('bid-template-changed', async (bidtemplate) => {
            this.selectedLocation = null
            this.selectedProducts = null

            this.filtered = []
            this.prices = []

            await this.Fetch()
        })
    },

    methods: {
        async Fetch() {
            if(this.Authenticated) {
                if(this.BidTemplate)
                    API.get(`/customers/reports/pricing/current/${this.Customer.CustomerNo}/${this.BidTemplate.TemplateID}`)
                            .then(response => {
                                this.prices = response.data?.Prices ?? []
                                this.locations = unique(this.prices, ['LocationGroup'])
                            })
                            .catch(error => {
                                this.prices = []
                                this.$toast.open({
                                    message: error.response.data.message,
                                    type: 'error',
                                });
                            })
                else
                    this.loading = false
            }
            else {
                await this.$router.push('/login')
            }
        },

        async Refresh() {
            await this.Fetch()

            this.filtered = this.prices.filter((price) => {
                return this.selectedLocation.LocationGroup === price.LocationGroup &&
                        this.selectedProducts.includes(price.ItemCodeDesc)
            })
        },

        async onLocationSelectionChanged({selectedRowsData}) {
            if(selectedRowsData.length === 0) return

            this.selectedLocation = selectedRowsData[0]
            this.selectedDestinationState = null
            this.selectedProducts = null
            this.filtered = null

            this.LocationsDropDown.close()

            this.products = unique(this.prices.filter((price) => {
                return this.selectedLocation.LocationGroup === price.LocationGroup
            }), ['ItemCodeDesc'])

            this.states = [...new Set(this.products.flatMap(price => price.LoadNumberDestinations.map(lnd => lnd.DestinationState)))].sort()

            this.selectedDestinationState = this.selectedLocation.Terminal.State
        },

        async onLoadNumberDestinationsValueChanged(e) {
            if(!this.selectedLocation) return

            this.selectedDestinationState = e.value

            this.filtered = []

            this.PricesDataGrid.columnOption('ItemCodeDesc', 'groupIndex', -1)

            this.PricesDataGrid.repaint()

            if (this.selectedProducts && !this.selectedProducts.length === 0) return

            this.filtered = this.prices.filter((price) => {
                return this.selectedLocation.LocationGroup === price.LocationGroup &&
                        this.selectedProducts.includes(price.ItemCodeDesc)
            })

            if(this.selectedProducts.length > 1)
                this.PricesDataGrid.columnOption('ItemCodeDesc', 'groupIndex', 0)
        },

        async onProductValueChanged(e) {
            this.selectedProducts = e.value
            this.filtered = []

            this.PricesDataGrid.columnOption('ItemCodeDesc', 'groupIndex', -1)

            this.PricesDataGrid.repaint()

            if (this.selectedProducts && this.selectedProducts.length === 0) return

            this.filtered = this.prices.filter((price) => {
                return this.selectedLocation.LocationGroup === price.LocationGroup &&
                        this.selectedProducts.includes(price.ItemCodeDesc)
            })

            if(this.selectedProducts.length > 1)
                this.PricesDataGrid.columnOption('ItemCodeDesc', 'groupIndex', 0)
        },

        calculateLoadNumber(price) {
            if(!this.selectedDestinationState) return price.LoadNumber

            const result = price.LoadNumberDestinations.find(lnd => lnd.DestinationState === this.selectedDestinationState)

            return result ? result.LoadNumber : 'Call Office'
        }
    },

    computed: {
        User() {
            return this.$store.getters['user/user']
        },

        Roles() {
            return this.$store.getters["user/roles"]
        },

        Authenticated() {
            return this.$store.getters["user/authenticated"];
        },

        PricesDataGrid() {
            return this.$refs.PricesDataGridRef.instance
        },

        Customer() {
            return this.$store.getters['customers/customer']
        },

        BidTemplate() {
            return this.$store.getters['bidtemplates/bidtemplate']
        },

        Impersonating() {
            return this.$store.getters['impersonate/impersonating'];
        },

        ImpersonatedUser() {
            return this.$store.getters['impersonate/user'];
        },

        HasCustomerBuyerRole() {
            return this.$gates.hasRole('Customer Buyer')
        },

        HasCustomerBuyerPermissions() {
            return this.$gates.hasPermission(this.permissions.pricing.SHOW_SELLING_PRICE)
        },

        HasCustomerDriverRole() {
            return this.$gates.hasRole('Customer Driver')
        },

        HasCustomerDriverPermissions() {
            return this.$gates.hasPermission(this.permissions.pricing.SHOW_LOAD_NUMBERS)
        },

        HasCustomerAdministratorRole() {
            return this.$gates.hasRole('Customer Administrator')
        },

        LocationsDropDown() {
            return this.$refs.LocationsDropDownRef.instance
        },

        LocationsGridRef() {
            return this.$refs.LocationsGridRef.instance
        },

        ProductsDropDown() {
            return this.$refs.ProductsDropDownRef.instance
        },

        IsDevEnvironment() {
            return process.env.NODE_ENV === 'development'
        }
    }
}

</script>

<style scoped>
    ::v-deep #date input[type=text] {
        text-align: center !important;
    }

    ::v-deep #date input[type=date] {
        text-align: center !important;
    }

    ::v-deep .dx-command-select {
        width: 45px!important;
        min-width: 45px!important;
        max-width: 45px!important;
    }
</style>
