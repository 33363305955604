import Vue from 'vue'
import Vuex from 'vuex'

import userModule from "./modules/user";
import bidtemplatesModule from "@/stores/modules/bidtemplates";
import customersModule from "./modules/customers";
import impersonateModule from "./modules/impersonate";
import loadingModule from './modules/loading'
import lockdownModule from './modules/lockdown'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		user: userModule,
		bidtemplates: bidtemplatesModule,
		customers: customersModule,
		impersonate: impersonateModule,
		loading: loadingModule,
		lockdown: lockdownModule
	}
})
