/* eslint-disable */
import store from "@/stores/store";
import {API} from "@/API";

const LOCKDOWN_MESSAGE = 'Application in Lock Down Mode'

const lockdown = {
	namespaced: true,
	
	state: {
		islockeddown: JSON.parse(localStorage.getItem('lockeddown')) || false,
		message: LOCKDOWN_MESSAGE
	},
	
	mutations: {
		SET_LOCKDOWNMODE(state, islockeddown) {
			state.islockeddown = islockeddown
		},
	},
	
	actions: {
		CHECK({commit}) {
			store.dispatch('loading/message', "Checking Lockdown Status...")
			
			return new Promise((resolve, reject) => {
				API.get('/admin/server/utilities/lockdown/status')
					.then(response => {
						const lockdownstatus = response.data.data.LockDownMode
						
						localStorage.setItem('lockeddown', lockdownstatus)
						
						commit('SET_LOCKDOWNMODE', lockdownstatus)
					})
			})
		},
		
		ACTIVATE({commit}) {
			store.dispatch('loading/message', "Activating Lockdown Mode...")
			
			return new Promise((resolve, reject) => {
				API.post('/admin/server/utilities/lockdown/activate')
					.then(response => {
						const lockdownstatus = response.data.data.LockDownMode
						
						localStorage.setItem('lockeddown', lockdownstatus)
						
						commit('SET_LOCKDOWNMODE', lockdownstatus)
					})
			})
		},
		
		RELEASE({commit}) {
			store.dispatch('loading/message', "Releasing Lockdown Mode...")
			
			return new Promise((resolve, reject) => {
				API.post('/admin/server/utilities/lockdown/release')
					.then(response => {
						const lockdownstatus = response.data.data.LockDownMode
						
						localStorage.setItem('lockeddown', lockdownstatus)
						
						commit('SET_LOCKDOWNMODE', lockdownstatus)
					})
			})
		}
	},
	
	getters: {
		islockeddown: state => state.islockeddown,
		message: state => state.message
	}
}

export default lockdown
