<template>
    <single-page :requires-auth="true">
        <b-container fluid>
            <b-row class="p-2 d-flex">
                <b-col xs="auto" sm="auto" md="10" lg="8" xl="6" class="px-0">
                    <b-card-group deck>
                        <b-card bg-variant="light" header="User Activity" class="text-center shadow-sm font-weight-bold">
                            <b-card-text>
                                <div class="px-2 pt-2">
                                    <DxToolbar>
                                        <DxItem
                                            location="before"
                                            locate-in-menu="auto"
                                        >
                                            <template #default>
                                                <DxDropDownBox
                                                        :width="250"
                                                        :defer-rendering="false"
                                                        :value.sync="userid"
                                                        display-expr="FullName"
                                                        :show-clear-button="true"
                                                        :data-source="users"
                                                        value-expr="ID"
                                                        placeholder="Select a User..."
                                                        :drop-down-options="{ width: 'auto' }"
                                                        @value-changed="onUserValueChanged"
                                                >
                                                    <template #default>
                                                        <DxDataGrid
                                                                :data-source="users"
                                                                :hover-state-enabled="true"
                                                                :selected-row-keys.sync="userid"
                                                                :show-borders="true"
                                                                :show-row-lines="true"
                                                                :column-auto-width="true"
                                                                key-expr="ID"
                                                                height="100%"
                                                        >
                                                            <DxSelection mode="single"/>
                                                            <DxPaging :enabled="true" :page-size="10"/>
                                                            <DxFilterRow :visible="true"/>
                                                            <DxScrolling mode="infinite"/>
                                                            <DxColumn
                                                                    data-field="LastName"
                                                                    caption="Last"
                                                                    :filter-operations="['contains']"
                                                                    :selected-operation="['contains']"
                                                                    :sort-index="0"
                                                                    sort-order="asc"
                                                            />
                                                            <DxColumn
                                                                    data-field="FirstName"
                                                                    caption="First"
                                                                    :filter-operations="['contains']"
                                                                    :selected-operation="['contains']"
                                                            />
                                                            <DxColumn
                                                                    data-field="Email"
                                                                    :filter-operations="['contains']"
                                                                    :selected-operation="['contains']"
                                                            />
                                                        </DxDataGrid>
                                                    </template>
                                                </DxDropDownBox>
                                            </template>
                                        </DxItem>
                                        <DxItem
                                            :options="refreshButtonOptions"
                                            location="before"
                                            locate-in-menu="auto"
                                            widget="dxButton"
                                        />
                                    </DxToolbar>
                                </div>
                                <DxDataGrid
                                    id="gridContainer"
                                    ref="UserActivityGridRef"
                                    no-data-text="No User Activity Records..."
                                    :data-source="activities"
                                    :show-borders="true"
                                    :show-row-lines="true"
                                    :column-auto-width="true"
                                    :repaint-changes-only="false"
                                    :two-way-binding-enabled="false"
                                    class="p-2"
                                    height="80vh"
                                >
                                    <DxHeaderFilter :visible="true"/>
                                    <DxGrouping :allow-grouping="true" :auto-expand-all="true"/>
                                    <DxGroupPanel :visible="false"/>
                                    <DxSorting show-sort-indexes />
                                    <DxPaging :page-size="15"/>
                                    <DxScrolling mode="infinite"/>

                                    <DxColumn
                                        data-field="causer.Email"
                                        caption="User"
                                    />
                                    <DxColumn
                                        data-field="causer.FullName"
                                        caption="Name"
                                        :group-index="0"
                                    />
                                    <DxColumn
                                        data-field="log_name"
                                        caption="Type"
                                        :group-index="1"
                                    />
                                    <DxColumn
                                        data-field="description"
                                        caption="Event"
                                        :allow-header-filtering="false"
                                    />
                                    <DxColumn
                                        data-field="created_at"
                                        caption="Created"
                                        data-type="datetime"
                                        format="MM/dd/yyyy hh:mm:ss a"
                                        sort-order="desc"
                                        :sort-index="0"
                                        :allow-header-filtering="false"
                                    />
                                    <DxMasterDetail :enabled="true" template="master-detail"/>
                                    <template #master-detail="{ data: activity }">
                                        <b-card class="border-0 bg-transparent">
                                            <b-card-title>
                                                <h4 class="m-0">Activity Properties</h4>
                                                <hr class="p-0 m-0"/>
                                            </b-card-title>
                                            <b-card-body class="p-0">
                                                <b-table-simple responsive borderless small class="m-0 p-0">
                                                    <b-tbody class="p-0 m-0">
                                                        <b-tr class="flex-column p-0 m-0">
                                                            <b-td class="fitwidth p-0 m-0 font-weight-bold">IP:</b-td>
                                                            <b-td class="p-0 pl-2 m-0 text-left">{{ activity.data.properties.IP }}</b-td>
                                                        </b-tr>
                                                        <b-tr class="p-0 m-0">
                                                            <b-td class="fitwidth p-0 m-0 font-weight-bold">Host:</b-td>
                                                            <b-td class="p-0 pl-2 m-0 text-left">{{ activity.data.properties.Host }}</b-td>
                                                        </b-tr>
                                                        <b-tr class="p-0 m-0">
                                                            <b-td class="fitwidth p-0 m-0 font-weight-bold">User-Agent:</b-td>
                                                            <b-td class="p-0 pl-2 m-0 text-left word-wrap">{{ activity.data.properties.UserAgent }}</b-td>
                                                        </b-tr>
                                                        <b-tr><b-td/><b-td/></b-tr>
                                                        <b-tr class="p-0 m-0" v-if="activity.data.properties.MenuName">
                                                            <b-td class="fitwidth p-0 m-0 font-weight-bold">Menu Action:</b-td>
                                                            <b-td class="p-0 pl-2 m-0 text-left word-wrap">{{ activity.data.properties.MenuName }}</b-td>
                                                        </b-tr>
                                                    </b-tbody>
                                                </b-table-simple>
                                            </b-card-body>
                                        </b-card>
                                    </template>
                                </DxDataGrid>
                            </b-card-text>
                        </b-card>
                    </b-card-group>
                </b-col>
            </b-row>
        </b-container>
    </single-page>
</template>

<script>

import {API} from "@/API";

import DxToolbar, { DxItem } from 'devextreme-vue/toolbar';
import { DxDropDownBox } from "devextreme-vue";
import {DxDataGrid, DxColumn, DxPaging, DxHeaderFilter, DxSorting, DxGrouping, DxScrolling, DxMasterDetail, DxGroupPanel, DxSelection, DxFilterRow } from 'devextreme-vue/data-grid';

import DataSource from 'devextreme/data/data_source';

import SinglePage from "@/components/SinglePage";

export default {
    name: "user-activity-view",

    components: {
        SinglePage,
        DxDataGrid,
        DxPaging,
        DxHeaderFilter,
        DxSorting,
        DxColumn,
        DxGrouping,
        DxScrolling,
        DxMasterDetail,
        DxGroupPanel,
        DxToolbar,
        DxItem,
        DxDropDownBox,
        DxSelection,
        DxFilterRow
    },

    data() {
        return {
            users: [],
            userid: null,

            activities: [],

            refreshButtonOptions: {
                icon: 'refresh',
                hint: 'Refresh Users',
                onClick: (e) => { this.Refresh() }
            }
        }
    },

    beforeRouteEnter(to, from, next) {
        next(vm => {
            let roles = to.meta.requiredRoles.join("|")
            let permissions = to.meta.requiredPermissions.join("|")

            if (vm.$gates.hasAnyRole(roles) || vm.$gates.hasAnyPermission(permissions))
                next()
            else
                next('/home')
        })
    },

    mounted() {
        this.FetchUsers()
    },

    methods: {
        Refresh() {
            if(this.userid)
                this.FetchActivities()
        },

        FetchActivities() {
            if (this.Authenticated) {
                API.get(`/admin/users/activity/${this.userid}`)
                        .then(response => {
                            this.activities = response.data
                        })
                        .catch(error => {
                            this.$toast.open({
                                message: error.response.data.message,
                                type: 'error',
                            });
                        })
            } else {
                this.$router.push('/login')
            }
        },

        FetchUsers() {
            if (this.Authenticated) {
                API.get('/admin/users')
                        .then(response => {
                            this.users = response.data
                        })
                        .catch(error => {
                            this.$toast.open({
                                message: error.response.data.message,
                                type: 'error',
                            });
                        })
            } else {
                this.$router.push('/login')
            }
        },

        onUserValueChanged(e) {
            if (this.userid && this.userid[0]) {
                this.FetchActivities()
            }

            e.component.close();
        }
    },

    computed: {
        Authenticated() {
            return this.$store.getters["user/authenticated"];
        },

        UserActivityGrid() {
            return this.$refs.UserActivityGridRef
        }
    }
}
</script>

<style scoped>
    ::v-deep .word-wrap {
        white-space: normal;
        word-wrap: break-word;
    }

    ::v-deep .dx-datagrid-rowsview .dx-row > .dx-master-detail-cell {
        padding: 0.5rem;
    }

    ::v-deep .message {
        line-height: 1.15rem;
        font-size: 0.80rem;
    }
    ::v-deep hr {
        border: 1px dotted black;
        border-style: none none dotted;
        color: #fff;
        background-color: #fff;
    }

    td.fitwidth {
        width: 1px;
        white-space: nowrap;
    }

    ::v-deep .dx-toolbar {
        background-color: transparent !important;
    }
</style>
