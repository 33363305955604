<template>
    <div class="side-nav-outer-toolbar">
        <header-toolbar
                class="layout-header"
                :menu-toggle-enabled="false"
        />
        <dx-scroll-view height="100%" width="100%" class="with-footer single-card">
            <div class="dx-card shadow-sm">
                <slot/>
            </div>
        </dx-scroll-view>
    </div>
</template>

<script>
import DxScrollView from "devextreme-vue/scroll-view";

import HeaderToolbar from "../components/HeaderToolbar";

export default {
    name: 'title-bar-layout',

    components: {
        DxScrollView,
        HeaderToolbar,
    }
};
</script>

<style lang="scss" scoped>
.single-card {

    .dx-card {
        width: 85%;
        margin: auto auto;
        padding: 40px;

        .screen-x-small & {
            width: 100%;
            height: 100%;
            border-radius: 0;
            box-shadow: none;
            margin: 0;
            border: 0;
            flex-grow: 1;
        }
    }
}

.side-nav-outer-toolbar {
    flex-direction: column;
    display: flex;
    height: 100%;
    width: 100%;
}

.layout-header {
    z-index: 1501;
}

.layout-body {
    flex: 1;
    min-height: 0;
}

.content {
    flex-grow: 1;
}

</style>
