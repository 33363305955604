<template>
    <div id="root">
        <div :class="cssClasses">
            <router-view
                    name="layout"
                    :title="title"
                    :is-x-small="screen.isXSmall"
                    :is-large="screen.isLarge"
            >
                <div class="content">
                    <router-view name="content"/>
                </div>
                <template #footer>
                    <Footer/>
                </template>
            </router-view>
        </div>

        <DxLoadPanel
                :visible.sync="loading"
                :message.sync="message"
                :max-width="500"
                :min-width="250"
                indicator-src="rolling.svg"
                shading-color="rgba(0,0,0,0.4)"
        />
    </div>
</template>

<script>
import Footer from "./components/Footer";
import {sizes, subscribe, unsubscribe} from "./utils/media-query";
import {DxLoadPanel} from 'devextreme-vue/load-panel';

import {createNamespacedHelpers} from 'vuex'

const { mapState } = createNamespacedHelpers('loading')

function getScreenSizeInfo() {
    const screenSizes = sizes();

    return {
        isXSmall: screenSizes["screen-x-small"],
        isLarge: screenSizes["screen-large"],
        cssClasses: Object.keys(screenSizes).filter(cl => screenSizes[cl])
    };
}

export default {
    name: "app",

    components: {
        Footer,
        DxLoadPanel
    },

    data() {
        return {
            title: this.$appInfo.title,
            screen: getScreenSizeInfo()
        };
    },

    created() {
        document.title = this.title

        this.$gates.setPermissions(this.$store.getters["user/permissions"])
        this.$gates.setRoles(this.$store.getters["user/roles"])
    },

    mounted() {
        subscribe(this.screenSizeChanged);
    },

    beforeDestroy() {
        unsubscribe(this.screenSizeChanged);
    },

    methods: {
        screenSizeChanged() {
            this.screen = getScreenSizeInfo();
        }
    },

    computed: {
        cssClasses() {
            return ["app"].concat(this.screen.cssClasses);
        },

        ...mapState({
            loading: 'loading',
            message: 'message',
        }),

        Authenticated() {
            return this.$store.getters['user/authenticated']
        }
    }
};
</script>

<style lang="scss">
@import 'custom-bootstrap.scss';

html,
body {
    margin: 0px;
    min-height: 100%;
    height: 100%;
}

#root {
    height: 100%;
}

* {
    box-sizing: border-box;
}

.app {
    @import "./themes/generated/variables.base.scss";
    @import "fm-styles";
    background-color: $FuelMastersWhite; //darken($base-bg, 5);
    display: flex;
    height: 100%;
    width: 100%;
}
</style>
