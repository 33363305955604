export default {
	initials(s) {
		let names = s.split(' '),
			initials = names[0].substring(0, 1).toUpperCase()
		
		if (names.length > 1)
			initials += names[names.length - 1].substring(0, 1).toUpperCase()

		return initials
	},
	
	capitalize() {
		return this.toLowerCase().replace(/\b\w/g, (m) => { return m.toUpperCase() })
	},
	
	namify(parts) {
		return parts.join(' ')
	},

	phonify(phoneNumberString) {
		let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
		const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
		
		if (match) {
			const intlCode = (match[1] ? '1 ' : '');
			return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
		}
		return null;
	},
	
	isEmailValid(email) {
		if(this.isEmptyOrWhitespace(email)) return false
		
		const regexEmail = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/;
		return !!email.match(regexEmail);
	},
	
	isEmptyOrWhitespace(str) { return (!str || str.length === 0 || /^\s*$/.test(str)) }
}
