<template>
    <div>
        <b-container fluid class="px-2 pb-0 bg-light">
            <b-row no-gutters class="d-flex">
                <b-col xs="auto" sm="auto" md="12" lg="12" xl="12" class="py-2 w-100">
                    <DxDataGrid
                            id="gridContainer"
                            ref="UserAlertsGridRef"
                            no-data-text="No Alert History..."
                            :data-source.sync="userMarketAlerts"
                            :show-borders="true"
                            :show-row-lines="true"
                            :column-auto-width="true"
                            :two-way-binding-enabled="false"
                            :column-hiding-enabled="false"
                            :selection="{ mode: 'single' }"
                            key-expr="ID"
                            width="auto"
                            class="p-0"
                            @selection-changed="onSelectionChanged"
                        >
                        <DxHeaderFilter :visible="true"/>

                        <DxSorting show-sort-indexes />
                        <DxScrolling mode="infinite"/>
                        <DxPaging :page-size="1"/>
                        <DxPager :show-info="true" :show-navigation-buttons="true"/>

                        <DxColumn
                                data-field="Created"
                                data-type="datetime"
                                format="MM/dd/yyyy hh:mm a"
                                caption="Sent"
                                sort-order="asc"
                                :sort-index="0"
                                :allow-header-filtering="false"
                                :width="140"
                        />
                        <DxColumn
                                data-field="NotificationType"
                                :visible="true"
                                :allow-header-filtering="true"
                                :allow-sorting="false"
                        />
                        <DxColumn
                                alignment="center"
                                data-field="Type"
                                caption="Move Direction"
                                cell-template="changeCellTemplate"
                                :allow-header-filtering="false"
                                :allow-sorting="false"
                        />
                        <DxColumn
                                alignment="center"
                                data-field="ChangeOrientation"
                                :allow-header-filtering="false"
                                :allow-sorting="false"
                        />
                        <DxColumn
                                data-field="Alert.Market.Name"
                                caption="Market"
                                :visible="true"
                                :allow-header-filtering="true"
                                :allow-sorting="true"
                        />
                        <DxColumn
                                data-field="Alert.Product.Name"
                                caption="Ticker"
                                :sort-index="1"
                                sort-order="asc"
                                :allow-header-filtering="true"
                                :allow-sorting="true"
                        />
                        <DxColumn
                                alignment="right"
                                data-field="Alert.Threshold"
                                caption="Initial Trigger"
                                data-type="number"
                                :format="{ type: 'fixedPoint', precision: 4 }"
                                :allow-header-filtering="false"
                                :allow-sorting="false"
                        />
                        <DxColumn
                                alignment="right"
                                data-field="Alert.Frequency"
                                caption="Secondary Trigger"
                                data-type="number"
                                :format="{ type: 'fixedPoint', precision: 4 }"
                                :allow-header-filtering="false"
                                :allow-sorting="false"
                        />
                        <DxColumn
                                alignment="center"
                                data-field="Channel"
                                caption="Send Alert To"
                                cell-template="channelCellTemplate"
                                :allow-header-filtering="false"
                                :allow-sorting="false"
                        />
                        <DxColumn
                                data-field="HighIncrement"
                                caption="High"
                                alignment="right"
                                data-type="number"
                                :hiding-priority="2"
                                :format="{ type: 'fixedPoint', precision: 4 }"
                                :allow-header-filtering="false"
                                :allow-sorting="false"
                        />
                        <DxColumn
                                data-field="LowIncrement"
                                caption="Low"
                                alignment="right"
                                data-type="number"
                                :hiding-priority="2"
                                :format="{ type: 'fixedPoint', precision: 4 }"
                                :allow-header-filtering="false"
                                :allow-sorting="false"
                        />
                        <DxColumn
                                data-field="Message"
                                :hiding-priority="0"
                                :allow-header-filtering="false"
                                :allow-sorting="false"
                        />
                        <DxToolbar>
                            <DxItem location="after" template="refreshTemplate"/>
                        </DxToolbar>

                        <template #channelCellTemplate="{ data: cellData }">
                            <div v-switch="cellData.data.Alert.Type">
                                <div v-case="0">
                                    <span class="dx-icon-todo"/> ALL
                                </div>
                                <div v-case="1">
                                    <span class="fas fa-solid fa-bell"/> NOTIFICATIONS
                                </div>

                                <div v-case="2">
                                    <span class="far fa-envelope"/> E-Mail
                                </div>
                            </div>
                        </template>

                        <template #changeCellTemplate="{ data: cellData }">
                            <div v-switch="cellData.data.Alert.Channel">
                                <div v-case="1" class="inc">
                                    <span class="arrow"/> Above
                                </div>
                                <div v-case="0">
                                    <span class="arrow"/> Both
                                </div>
                                <div v-case="-1" class="dec">
                                    <span class="arrow"/> Below
                                </div>
                            </div>
                        </template>

                        <template #refreshTemplate>
                            <DxButton icon="refresh" @click="FetchHistory"/>
                        </template>
                    </DxDataGrid>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import {
    DxDataGrid,
    DxColumn,
    DxHeaderFilter,
    DxSorting,
    DxScrolling,
    DxToolbar,
    DxItem,
    DxPaging,
    DxPager,
} from 'devextreme-vue/data-grid';

import { DxButton } from 'devextreme-vue/button';

import {API} from "@/API";

const AlertTypes = [
    {
        id: 1,
        name: 'Above',
        icon: 'arrowup'
    },
    {
        id: 0,
        name: 'Both',
        icon: 'expand'
    },
    {
        id: -1,
        name: 'Below',
        icon: 'arrowdown'
    }
]

const AlertChannels = [
    {
        id: 0,
        name: 'All',
        icon: 'todo',
        visible: true,
    },
    {
        id: 1,
        name: 'Notification',
        icon: 'fas fa-solid fa-bell',
        visible: true,
    },
    {
        id: 2,
        name: 'E-Mail',
        icon: 'far fa-envelope',
        visible: true,
    }
]

export default {
    name: "user-market-alert-history",

    components: {
        DxDataGrid,
        DxColumn,
        DxHeaderFilter,
        DxSorting,
        DxScrolling,
        DxPaging,
        DxPager,
        DxToolbar,
        DxItem,
        DxButton,
    },

    data() {
        return {
            AlertTypes,
            AlertChannels,
            userMarketAlerts: [],
            selectedUserMarketAlert: null
        }
    },

    async beforeMount() {
        this.$root.$on('user-impersonated', await this.Fetch)
        this.$root.$on('user-unpersonated', await this.Fetch)

        await this.FetchHistory()
    },

    beforeDestroy() {
        this.$root.$off('user-impersonated')
        this.$root.$off('user-unpersonated')
    },

    methods: {
        FetchHistory() {
            this.$store.dispatch('loading/message', "Retrieving User Market Alerts...")

            API.get('/alerts/markets/history')
                    .then(response => {
                        this.userMarketAlerts = response.data

                        this.selectedUserMarketAlert = null
                        this.UserAlertsGrid.deselectAll()
                    })
                    .catch(error => {
                        this.$toast.open({
                            message: error.response.data.message,
                            type: 'error',
                        });
                    })
        },

        onSelectionChanged({ selectedRowsData }) {
            const data = selectedRowsData[0];

            this.selectedUserMarketAlert = data ? data : null
        },

        getSizeQualifier(width) {
            if (width < 768) return "xs";
            if (width < 992) return "sm";
            if (width < 1200) return "md";
            return "lg";
        },
    },

    computed: {
        User() {
            return this.$store.getters['user/user']
        },

        Profile() {
            return this.$store.getters['user/profile']
        },

        Authenticated() {
            return this.$store.getters["user/authenticated"];
        },

        MarketAlerts() {
            return this.$store.getters['user/market_alerts']
        },

        UserAlertsGrid() {
            return this.$refs.UserAlertsGridRef.instance
        },

        AlertChannelsFiltered() {
            let channels = [...this.AlertChannels]

            channels[0].visible = this.User.Phone && this.User.Email
            channels[1].visible = this.User.Phone
            channels[2].visible = this.User.Email

            return channels
        }
    }
}
</script>

<style scoped>

::v-deep #gridContainer span.current-value {
    display: inline-block;
    margin-right: 5px;
}

::v-deep #gridContainer span.Diff {
    width: 50px;
    display: inline-block;
}

::v-deep #gridContainer .inc .arrow,
::v-deep #gridContainer .dec .arrow {
    display: inline-block;
    height: 10px;
    width: 10px;
    background-repeat: no-repeat;
    background-size: 10px 10px;
}

::v-deep #gridContainer .inc .arrow {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAADKSURBVHjaYtTaLs1ABEiG0nPRJa56PEHhsxBhmCUQT4OyrwHxcXyKmQgYJgHE64CYDYrXQcXIMhCbAcgWkGzgNKh38QUB0QamIUUErkhKI9ZAGyCeTERkTYaqxWsgKA2txhdG6GGsvUNGGpeBRMUiGhCFGsqGzUBQQJsxkA5AemaiG5hDIBIIgQSgK0FmMDACs549kN5FZLjhA7+A2A2U9YSAOBeLAk4gnoBDczoOcSFGPIUDPxB/wCHHiKtwYGKgMhg1cBAaCBBgAJTUIL3ToPZfAAAAAElFTkSuQmCC');
}

::v-deep #gridContainer .dec .arrow {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAADJSURBVHjaYvzPgBfgkhYA4o8QFahKmBioDEYNHIQGsgBxIBCLkqgvAYi/g1mMjMjir0EJzR6If/6HpChKMMgMe3DKBeIcKhiY8x/MYoDj+RQYNgdkGLqBbEB8kgzDToL1YjEQhKWB+BUJhj0H64Eahs1AELYhMpJ+gtUiGYbLQBBOI8LANLBaIg1kAAc0vkiAqSPBQFAkHcNi2DGoHMkGgrAENOCRI0ECRQ2JBoKwJTQCfkLZDPgMZPxPXN5NhtJzMSsJVBMAAgwAyWSY2svfmrwAAAAASUVORK5CYII=');
}

::v-deep .dx-toolbar {
    background-color: #f8f9fa !important;
}

::v-deep .dx-datagrid-header-panel {
    background-color: #f8f9fa !important;
}

.inline-form {
    float: left;
    overflow: hidden;
    white-space: nowrap;
    cursor: default;
}

::v-deep .dx-selection td{
    background-color: #5990be !important;
    color: white !important;
}

</style>
