<template>
    <single-page :requires-auth="true">
        <b-container fluid>
            <b-row class="p-2 d-flex">
                <b-col cols="2">
                    <DashboardPanel title="Lockdown Mode">
                        <template #toolbar>
                            <DxButton icon="refresh" class="float-right" @click="$store.dispatch('lockdown/CHECK')"/>
                        </template>
                        <template #content>
                            <b-container fluid class="p-2">
                                <b-row>
                                    <b-col cols="auto" align-self="center" class="d-flex">
                                        <status-indicator :status="$store.getters['lockdown/islockeddown'] ? 'positive' : 'negative'" />
                                        <span class="px-2 align-content-center">{{ $store.getters['lockdown/islockeddown'] ? 'ACTIVE' : 'IN-ACTIVE' }}</span>
                                    </b-col>
                                    <b-col cols="auto" align-self="right">
                                        <DxButton
                                            :text="$store.getters['lockdown/islockeddown'] ? 'Release' : 'Activate'"
                                            type="default"
                                            @click="(e) => { LockDownStatus ? $store.dispatch('lockdown/RELEASE') : $store.dispatch('lockdown/ACTIVATE') }"
                                        />
                                    </b-col>
                                </b-row>
                            </b-container>
                        </template>
                    </DashboardPanel>
                </b-col>
            </b-row>
        </b-container>
    </single-page>
</template>

<script>
import SinglePage from "@/components/SinglePage";
import { API } from "@/API";
import { StatusIndicator } from 'vue-status-indicator';
import DashboardPanel from "@/components/DashboardPanel";
import DxButton from "devextreme-vue/button";

export default {
    name: "server-utilities-view",

    components: {
        SinglePage,
        StatusIndicator,
        DashboardPanel,
        DxButton
    },

    beforeMount() {
        this.$store.dispatch('lockdown/CHECK')
    },

    computed: {
        LockDownStatus() {
            return this.$store.getters['lockdown/islockeddown']
        }
    }
}
</script>

<style scoped>
.status-indicator{
    padding: 10px;
}
</style>
