<template>
    <div>
        <b-container fluid class="px-2 pb-0 bg-light">
            <b-row no-gutters class="d-flex">
                <b-col xs="auto" sm="auto" md="12" lg="12" xl="12" class="py-2 w-100">
                    <DxDataGrid
                            id="gridContainer"
                            ref="MessagesGridRef"
                            no-data-text="No Messages..."
                            :data-source.sync="messages"
                            :show-borders="true"
                            :show-row-lines="true"
                            :column-auto-width="true"
                            :two-way-binding-enabled="false"
                            :column-hiding-enabled="false"
                            :selection="{ mode: 'single' }"
                            :word-wrap-enabled="true"
                            key-expr="ID"
                            width="auto"
                            class="p-0"
                    >
                        <DxHeaderFilter :visible="true"/>
                        <DxFilterRow :visible="false"/>
                        <DxSorting show-sort-indexes />
                        <DxScrolling row-rendering-mode="virtual"/>
                        <DxPaging :page-size="20"/>
                        <DxPager :show-info="true" :show-navigation-buttons="true" display-mode="full"/>

                        <DxColumn
                                data-field="Received"
                                data-type="datetime"
                                format="MM/dd/yyyy hh:mm a"
                                caption="Received"
                                sort-order="desc"
                                :sort-index="0"
                                :allow-header-filtering="false"
                                :width="140"
                        />
                        <DxColumn
                                data-field="From.FullName"
                                caption="From"
                                :hiding-priority="0"
                                :width="175"
                        />
                        <DxColumn
                                data-field="To.FullName"
                                caption="To"
                                :hiding-priority="0"
                                :width="175"
                        />
                        <DxColumn
                                data-field="From.Customer.CustomerName"
                                caption="Customer"
                                :hiding-priority="0"
                                :width="300"
                        />
                        <DxColumn
                                data-field="From.Message"
                                caption="Message"
                                :hiding-priority="0"
                                :allow-header-filtering="false"
                                :allow-sorting="false"
                        />
                        <DxToolbar>
                            <DxItem location="before" template="todayTemplate"/>
                            <DxItem location="before" template="allTemplate"/>
                        </DxToolbar>


                        <template #todayTemplate>
                            <DxButton text="Today" hint="Show Today's Messages" :width="75" @click="FetchToday"/>
                        </template>

                        <template #allTemplate>
                            <DxButton text="All" hint="Show All Messages" :width="75" @click="FetchAll"/>
                        </template>
                    </DxDataGrid>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import {
    DxDataGrid,
    DxColumn,
    DxHeaderFilter,
    DxSorting,
    DxScrolling,
    DxToolbar,
    DxItem,
    DxPaging,
    DxPager,
    DxFilterRow
} from 'devextreme-vue/data-grid';

import { DxButton } from 'devextreme-vue/button';

import {API} from "@/API";

export default {
    name: "messages-grid",

    components: {
        DxDataGrid,
        DxColumn,
        DxHeaderFilter,
        DxSorting,
        DxScrolling,
        DxPaging,
        DxPager,
        DxFilterRow,
        DxToolbar,
        DxItem,
        DxButton,
    },

    data() {
        return {
            messages: [],
            filters: [],
        }
    },

    async beforeMount() {
        this.$root.$on('user-impersonated', await this.FetchToday)
        this.$root.$on('user-unpersonated', await this.FetchToday)

        await this.FetchToday()
    },

    beforeDestroy() {
        this.$root.$off('user-impersonated')
        this.$root.$off('user-unpersonated')
    },

    methods: {
        async FetchToday() {
            await this.$store.dispatch('loading/message', "Retrieving Messages...")

            API.get('/messages/today')
                    .then(response => {
                        this.messages = response.data

                        this.MessagesGrid.deselectAll()
                    })
                    .catch(error => {
                        this.$toast.open({
                            message: error.response.data.message,
                            type: 'error',
                        });
                    })
        },

        async FetchHistory(days = 3) {
            await this.$store.dispatch('loading/message', "Retrieving Messages...")

            API.get('/messages/history', { params: { days: days } })
                    .then(response => {
                        this.messages = response.data

                        this.MessagesGrid.deselectAll()
                    })
                    .catch(error => {
                        this.$toast.open({
                            message: error.response.data.message,
                            type: 'error',
                        });
                    })
        },

        async FetchAll() {
            await this.$store.dispatch('loading/message', "Retrieving Messages...")

            API.get('/messages/all')
                    .then(response => {
                        this.messages = response.data

                        this.MessagesGrid.deselectAll()
                    })
                    .catch(error => {
                        this.$toast.open({
                            message: error.response.data.message,
                            type: 'error',
                        });
                    })
        },

        getSizeQualifier(width) {
            if (width < 768) return "xs";
            if (width < 992) return "sm";
            if (width < 1200) return "md";
            return "lg";
        },
    },

    computed: {
        User() {
            return this.$store.getters['user/user']
        },

        Authenticated() {
            return this.$store.getters["user/authenticated"];
        },

        MessagesGrid() {
            return this.$refs.MessagesGridRef.instance
        }
    }
}
</script>

<style scoped>

</style>
