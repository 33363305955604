<template>
    <single-page :requires-auth="true">
        <b-container fluid class="px-2">
            <b-row class="py-1">
                <b-col>
                    <h3 v-if="Customer" class="my-auto">Welcome, {{ UserName }}</h3>
                </b-col>
            </b-row>
            <b-row class="py-2">
                <b-container fluid>
                    <b-row>
                        <b-col>
                            <b-tabs lazy :value="CanUseMarkets ? 0 : 1">
                                <b-tab title="Markets" :title-item-class="CanUseMarkets ? '' : 'd-none'">
                                    <b-container fluid>
                                        <b-row no-gutters class="d-flex">
                                            <b-col xs="auto" sm="auto" md="7" lg="6" xl="5" class="py-2">
                                                <DashboardPanel title="Current Market Prices" :footer="CurrentMarketPricesFooter">
                                                    <template #toolbar>
                                                        <DxButton icon="refresh" class="float-right" @click="RefreshDTNSnapshots"/>
                                                        <DxButton icon="far fa-bell" class="float-right" :disabled="!Profile.MarketPreferences.length" @click="ShowUserMarketAlerts"/>
                                                    </template>
                                                    <template #content>
                                                        <DTNSnapshotsGrid ref="DTNSnapshotsGridRef"/>
                                                    </template>
                                                </DashboardPanel>
                                            </b-col>
                                        </b-row>
                                    </b-container>
                                </b-tab>
                                <b-tab title="Outages">
                                    <b-container fluid>
                                        <b-row no-gutters class="d-flex">
                                            <b-col xs="auto" sm="auto" md="auto" lg="auto" xl="auto" class="py-2">
                                                <DashboardPanel title="Outages">
                                                    <template #toolbar>
                                                        <DxButton icon="refresh" class="float-right" @click="RefreshOutages"/>
                                                    </template>
                                                    <template #content>
                                                        <OutagesGrid ref="OutagesGridRef"/>
                                                    </template>
                                                </DashboardPanel>
                                            </b-col>
                                        </b-row>
                                    </b-container>
                                </b-tab>
                            </b-tabs>
                        </b-col>
                    </b-row>
                </b-container>
            </b-row>
        </b-container>

        <DxPopup
            :visible="showUserMarketAlerts"
            :drag-enabled="false"
            :close-on-outside-click="false"
            :show-close-button="false"
            :show-title="true"
            :width="400"
            :animation="{
                    show: { type: 'pop', duration: 100 },
                    hide: { type: 'pop', duration: 0 }
            }"
            shading-color="rgba(0,0,0,0.4)"
            height="auto"
            container=".dx-viewport"
            title="Current Alert Rules"
        >
            <DxToolbarItem widget="dxButton" toolbar="bottom" location="center" :options="{ text: 'Close', onClick: () => { this.showUserMarketAlerts = false } }"/>

            <template #content>
                <UserMarketAlerts/>
            </template>
        </DxPopup>
    </single-page>
</template>

<script>
/* eslint-disable */

import DTNSnapshotsGrid from "@/components/DTNSnapshotsGrid";
import DtnSnapshotChart from "../components/DTNSnapshotChart";
import SinglePage from "../components/SinglePage";
import OutagesGrid from "@/components/OutagesGrid";
import DxButton from 'devextreme-vue/button'
import DashboardPanel from "@/components/DashboardPanel";
import DxTooltip from 'devextreme-vue/tooltip';
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';

import strings from '@/utils/string-helpers'
import moment from "moment";
import UserMarketAlerts from "@/components/UserMarketAlerts";

export default {
    name: 'home-view',

    components: {
        UserMarketAlerts,
        DashboardPanel,
        DxButton,
        OutagesGrid,
        SinglePage,
        DtnSnapshotChart,
        DTNSnapshotsGrid,
        DxTooltip,
        DxPopup,
        DxToolbarItem
    },

    data() {
        return {
            lastRefresh: moment(),
            showUserMarketAlerts: false
        }
    },

    mounted() {
        this.$root.$on('dtn-snapshots-refreshed', () => { this.lastRefresh = moment() })
    },

    beforeDestroy() {
        this.$root.$off('dtn-snapshots-refreshed')
    },

    methods: {
        RefreshOutages(e) {
            this.OutagesGrid.FetchCustomer()
        },

        RefreshDTNSnapshots(e) {
            this.lastRefresh = moment()
            this.DTNSnapshotsGrid.Fetch()
        },

        ShowUserMarketAlerts(e) {
            this.showUserMarketAlerts = true
        }
    },

    computed: {
        User() {
            return this.$store.getters['user/user']
        },

        Profile() {
            return this.$store.getters['user/profile']
        },

        UserName() {
            return this.IsImpersonating ? this.ImpersonationUser.FullName : this.$store.getters['user/name']
        },

        OutagesGrid() {
            return this.$refs.OutagesGridRef
        },

        DTNSnapshotsGrid() {
            return this.$refs.DTNSnapshotsGridRef
        },

        IsImpersonating() {
            return this.$store.getters["impersonate/impersonating"]
        },

        ImpersonationUser() {
            return this.$store.getters['impersonate/user']
        },

        Customer() {
            return this.$store.getters['customers/customer']
        },

        CurrentMarketPricesFooter() {
            return `Last Refreshed: ${ this.lastRefresh.format('MM/DD/YYYY hh:mm A') }`
        },

        CanUseMarkets() {
            return this.$gates.hasAnyRole('Super Administrator|Administrator|Account Manager')
        }
    }
}
</script>

<style scoped>
    span {
        display: inline-block;
        word-wrap:break-word;
        white-space: normal
    }

    ::v-deep .tab-content {
        border-left: 1px solid #dee2e6;
        border-bottom: 1px solid #dee2e6;
        border-right: 1px solid #dee2e6;
    }

    ::v-deep .nav-tabs .nav-link {
        margin-bottom: -1px;
        border-left: 1px solid #dee2e6;
        border-right: 1px solid #dee2e6;
        border-top: 1px solid #dee2e6;
        border-bottom: 0 !important;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
        color: black;
        font-size: 14px;
    }

    ::v-deep .nav-tabs .nav-link.active {
        font-weight: bolder;
        text-decoration: underline;
        background-color: #eeeeee;
        border-bottom: 0 !important;
    }
</style>
