<template>
    <single-page :requires-auth="true">
        <b-container fluid>
            <b-row class="p-2 d-flex">
                <b-col xs="auto" sm="auto" md="12" lg="12" xl="12" class="px-0">
                    <DashboardPanel title="User Management">
                        <template #content>
                                <UserManagementGrid/>
                        </template>
                    </DashboardPanel>
                </b-col>
            </b-row>
        </b-container>
    </single-page>
</template>

<script>
import SinglePage from "@/components/SinglePage";
import UserManagementGrid from "@/components/Admin/UserManagementGrid";
import DashboardPanel from "@/components/DashboardPanel.vue";

export default {
    name: "user-management-view",

    components: {
        UserManagementGrid,
        SinglePage,
        DashboardPanel,
    },

    data() {
        return {
            users: []
        }
    },

    beforeRouteEnter(to, from, next) {
        next(vm => {
            let roles = to.meta.requiredRoles.join("|")
            let permissions = to.meta.requiredPermissions.join("|")

            if (vm.$gates.hasAnyRole(roles) || vm.$gates.hasAnyPermission(permissions))
                next()
            else
                next('/home')
        })
    },

    mounted() {

    },

    methods: {

    },

    computed: {

    }
}
</script>

<style scoped>

</style>
