<template>
    <b-card-group deck>
        <b-card no-body bg-variant="light" class="shadow-sm font-weight-bold" :style="{ maxWidth: size + 'px' }">
            <template v-slot:header>
                {{ title }}
                <slot name="toolbar"/>
            </template>
            <b-card-text>
                <slot name="content" />
            </b-card-text>
            <template v-if="footer" v-slot:footer>
                {{ footer }}
            </template>
        </b-card>
    </b-card-group>
</template>

<script>
export default {
    name: "dashboard-panel",

    props: {
        title: String,
        size: Number,
        footer: String,
    }
}
</script>

<style scoped>
    ::v-deep .card-footer {
        padding: 5px !important;
        text-align: right;
        font-variant: small-caps !important;
        font-size: 0.9em !important;
        text-transform: uppercase !important;
        font-weight: bold !important;
    }
</style>
