<template>
    <single-page :cols="12">
        <div class="flex-center">
            <div class="code">
                {{ $route.params.code ? $route.params.code : code}}
            </div>

            <div class="message" style="padding: 10px;">
                {{ $route.params.message ? $route.params.message : message }}
            </div>
        </div>
    </single-page>
</template>

<script>
	import SinglePage from "@/components/SinglePage";

    export default {
        components: {
            SinglePage
        },

        props: {
			code: {type: Number, default: 404},
			message: {type: String, default: 'Page not found'}
		},

		title: 'Error',
	}
</script>

<style scoped>
    .full-height {
        height: 100vh;
    }

    .flex-center {
        align-items: center;
        display: flex;
        justify-content: center;
    }

    .position-ref {
        position: relative;
    }

    .code {
        border-right: 2px solid;
        font-size: 26px;
        padding: 0 15px 0 15px;
        text-align: center;
    }

    .message {
        font-size: 18px;
        text-align: center;
    }
</style>
