<template>
    <single-page :requires-auth="true">
        <b-container fluid>
            <b-row class="p-2 d-flex">
                <b-col xs="auto" sm="auto" md="3" lg="2" xl="2" class="px-0">
                    <b-card-group deck>
                        <b-card bg-variant="light" header="User Profile Templates" class="text-center shadow-sm font-weight-bold">
                            <b-card-text>
                                <div class="px-2 py-2">
                                    <DxDataGrid
                                            id="gridContainer"
                                            ref="UserProfileTemplatesGridRef"
                                            no-data-text="No User Profile Templates..."
                                            :data-source.sync="templates"
                                            :show-borders="true"
                                            :show-row-lines="true"
                                            :column-auto-width="true"
                                            :two-way-binding-enabled="false"
                                            :column-hiding-enabled="false"
                                            :selection="{ mode: 'single' }"
                                            :show-column-headers="false"
                                            width="auto"
                                            class="p-0"
                                            @selection-changed="onSelectionChanged"
                                    >
                                        <DxHeaderFilter :visible="true"/>

                                        <DxSorting show-sort-indexes />
                                        <DxScrolling mode="infinite"/>

                                        <DxColumn
                                                data-field="Name"
                                                :visible="true"
                                                :sort-index="0"
                                                sort-order="asc"
                                                :allow-header-filtering="false"
                                                :allow-sorting="false"
                                        />

                                        <DxToolbar>
                                            <DxItem location="before" template="addTemplate"/>
                                            <DxItem location="before" template="editTemplate"/>
                                            <DxItem location="before" template="removeTemplate"/>
                                            <DxItem location="after" template="refreshTemplate"/>
                                        </DxToolbar>

                                        <template #addTemplate>
                                            <DxButton icon="add" hint="Add New User Profile Tempalte" @click="ShowAddTemplate"/>
                                        </template>

                                        <template #editTemplate>
                                            <DxButton icon="edit" hint="Edit Selected User Profile Template" :disabled="!selectedTemplate || !templates.length" @click="ShowEditTemplate"/>
                                        </template>

                                        <template #removeTemplate>
                                            <DxButton icon="minus" hint="Remove Selected User Profile Template" :disabled="!selectedTemplate || !templates.length" @click="DeleteUserTemplate"/>
                                        </template>

                                        <template #refreshTemplate>
                                            <DxButton icon="refresh" hint="Refresh User Profile Templates" @click="RefreshTemplates"/>
                                        </template>
                                    </DxDataGrid>
                                </div>
                            </b-card-text>
                        </b-card>
                    </b-card-group>
                </b-col>
            </b-row>
        </b-container>

        <DxPopup
            :visible="showAddUserProfileTemplatePopup"
            :drag-enabled="false"
            :close-on-outside-click="false"
            :show-close-button="false"
            :show-title="true"
            :width="400"
            :animation="{
                show: { type: 'pop', duration: 100 },
                hide: { type: 'pop', duration: 0 }
            }"
            shading-color="rgba(0,0,0,0.4)"
            height="auto"
            container=".dx-viewport"
            title="Add User Profile Template"
            @shown="(e) => { this.$refs.AddTextBoxName.instance.focus() }"
            @hidden="(e) => { this.$refs.AddUserProfileTemplatesGridRef.instance.clearFilter() }"
        >
            <DxToolbarItem widget="dxButton" toolbar="bottom" location="after" :options="{ text: 'Add', onClick: CreateUserProfileTemplate}"/>
            <DxToolbarItem widget="dxButton" toolbar="bottom" location="after" :options="{ text: 'Close', onClick: () => { this.showAddUserProfileTemplatePopup = false } }"/>

            <template #content class="py-0">
                <div>
                    <div class="py-2 pt-0">
                        <span class="py-2">Name:</span>
                        <DxTextBox ref="AddTextBoxName" :max-length="15" :value.sync="addUserProfileTemplate.Name" width="auto"/>
                    </div>
                    <div class="py-2 pb-0">
                        <span class="py-2">Products/Markets:</span>
                        <DxDataGrid
                            id="AddUserProfileTemplatesGrid"
                            ref="AddUserProfileTemplatesGridRef"
                            no-data-text="No Market/Product Combinations Enabled..."
                            :data-source.sync="markets_products"
                            :show-borders="true"
                            :show-row-lines="true"
                            :column-auto-width="true"
                            :column-hiding-enabled="false"
                            :selected-row-keys.sync="addUserProfileTemplate.MarketsProducts"
                            key-expr="ID"
                            class="p-0"
                        >
                            <DxHeaderFilter :visible="true"/>
                            <DxSelection mode="multiple" select-all-mode="allPages" show-check-boxes-mode="always" />
                            <DxSorting show-sort-indexes />
                            <DxScrolling row-rendering-mode="infinite"/>

                            <DxColumn
                                    data-field="Market.Name"
                                    caption="Market"
                                    sort-order="asc"
                                    :sort-index="0"
                                    :allow-sorting="false"
                            />
                            <DxColumn
                                    data-field="Product.Name"
                                    caption="Ticker"
                                    sort-order="asc"
                                    :sort-index="1"
                                    :allow-sorting="false"
                            />
                        </DxDataGrid>
                    </div>
                </div>
            </template>
        </DxPopup>

        <DxPopup
                :visible="showEditUserProfileTemplatePopup"
                :drag-enabled="false"
                :close-on-outside-click="false"
                :show-close-button="false"
                :show-title="true"
                :width="400"
                :animation="{
                    show: { type: 'pop', duration: 100 },
                    hide: { type: 'pop', duration: 0 }
                }"
                shading-color="rgba(0,0,0,0.4)"
                height="auto"
                container=".dx-viewport"
                title="Edit User Profile Template"
                @hidden="(e) => { this.$refs.EditUserProfileTemplatesGridRef.instance.clearFilter() }"
        >
            <DxToolbarItem widget="dxButton" toolbar="bottom" location="after" :options="{ text: 'Save', onClick: EditUserProfileTemplate }"/>
            <DxToolbarItem widget="dxButton" toolbar="bottom" location="after" :options="{ text: 'Close', onClick: CloseEditTemplate }"/>

            <template #content class="py-0">
                <div>
                    <div class="py-2 pt-0">
                        <span class="py-2">Name:</span>
                        <DxTextBox ref="EditTextBoxName" :value.sync="editUserProfileTemplate.Name" width="auto" :disabled="true"/>
                    </div>
                    <div class="py-2 pb-0">
                        <span class="py-2">Products/Markets:</span>
                        <DxDataGrid
                                id="EditUserProfileTemplatesGrid"
                                ref="EditUserProfileTemplatesGridRef"
                                no-data-text="No Market/Product Combinations Enabled..."
                                :data-source.sync="markets_products"
                                :show-borders="true"
                                :show-row-lines="true"
                                :column-auto-width="true"
                                :column-hiding-enabled="false"
                                :selected-row-keys.sync="editUserProfileTemplate.MarketsProducts"
                                key-expr="ID"
                                class="p-0"
                        >
                            <DxHeaderFilter :visible="true"/>
                            <DxSelection :deferred="false" mode="multiple" select-all-mode="allPages" show-check-boxes-mode="always" />
                            <DxSorting show-sort-indexes />
                            <DxScrolling row-rendering-mode="infinite"/>

                            <DxColumn
                                    data-field="Market.Name"
                                    caption="Market"
                                    sort-order="asc"
                                    :sort-index="0"
                                    :allow-sorting="false"
                            />
                            <DxColumn
                                    data-field="Product.Name"
                                    caption="Ticker"
                                    sort-order="asc"
                                    :sort-index="1"
                                    :allow-sorting="false"
                            />
                        </DxDataGrid>
                    </div>
                </div>
            </template>
        </DxPopup>
    </single-page>
</template>

<script>
/* eslint-disable */

import {
    DxDataGrid,
    DxColumn,
    DxHeaderFilter,
    DxSorting,
    DxScrolling,
    DxToolbar,
    DxItem,
    DxPaging,
    DxPager,
    DxSelection
} from 'devextreme-vue/data-grid';

import DxSelectBox from 'devextreme-vue/select-box';
import { DxTextBox } from "devextreme-vue/text-box";

import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import { DxButton } from 'devextreme-vue/button';

import { confirm } from "devextreme/ui/dialog";

import { API } from "@/API";
import SinglePage from "@/components/SinglePage";

export default {
    name: "user-profile-templates-view",

    components: {
        SinglePage,
        DxDataGrid,
        DxColumn,
        DxHeaderFilter,
        DxSorting,
        DxScrolling,
        DxPaging,
        DxPager,
        DxToolbar,
        DxItem,
        DxButton,
        DxPopup,
        DxToolbarItem,
        DxSelection,
        DxTextBox,
    },

    async beforeMount() {
        await this.Fetch()
    },

    data() {
        return {
            templates: [],
            markets_products: [],
            selectedTemplate: null,
            showAddUserProfileTemplatePopup: false,
            showEditUserProfileTemplatePopup: false,
            addUserProfileTemplate: {
                Name: null,
                MarketsProducts: []
            },
            editUserProfileTemplate: {
                Name: null,
                MarketsProducts: []
            },
        }
    },

    methods: {
        async Fetch() {
            await this.FetchTemplates();
            await this.FetchActiveMarketsProducts();
        },

        async FetchTemplates() {
            await this.$store.dispatch('loading/message', "Retrieving User Profile Templates...")

            if (this.Authenticated) {
                API.get('/admin/users/profile/templates')
                    .then(response => {
                        this.templates = response.data
                    })
                    .catch(error => {
                        this.$toast.open({
                            message: error.response.data.message,
                            type: 'error',
                        });
                    })
            } else {
                await this.$router.push('/login')
            }
        },

        async FetchActiveMarketsProducts() {
            if (this.Authenticated) {
                API.get('/admin/markets-products/active')
                        .then(response => {
                            this.markets_products = response.data
                        })
                        .catch(error => {
                            this.$toast.open({
                                message: error.response.data.message,
                                type: 'error',
                            });
                        })
            } else {
                await this.$router.push('/login')
            }
        },

        async RefreshTemplates(e) {
            await this.Fetch()
        },

        ShowAddTemplate(e) {
            this.addUserProfileTemplate = this.NewUserProfileTemplate()
            this.showAddUserProfileTemplatePopup = true
        },

        ShowEditTemplate(e) {
            this.editUserProfileTemplate = {...this.selectedTemplate}

            if (this.Authenticated) {
                API.get(`/admin/users/profile/templates/${this.selectedTemplate.Name}`)
                        .then(response => {
                            this.editUserProfileTemplate.MarketsProducts = response.data
                            this.showEditUserProfileTemplatePopup = true
                        })
                        .catch(error => {
                            this.$toast.open({
                                message: error.response.data.message,
                                type: 'error',
                            });
                        })
            } else {
                this.$router.push('/login')
            }
        },

        CloseEditTemplate(e) {
            this.showEditUserProfileTemplatePopup = false
            this.editUserProfileTemplate = this.NewUserProfileTemplate()
        },

        CreateUserProfileTemplate(e) {
            if (this.addUserProfileTemplate.Name && this.addUserProfileTemplate.MarketsProducts.length > 0) {
                this.$store.dispatch('loading/message', "Creating New User Profile Template...")

                API.post('/admin/users/profile/templates', this.addUserProfileTemplate)
                        .then(response => {
                            this.templates = response.data

                            this.selectedTemplate = null
                            this.UserProfileTemplatesGrid.deselectAll()
                        })
                        .catch(error => {
                            this.$toast.open({
                                message: error.response.data.message,
                                type: 'error',
                            });
                        })
                        .finally(() => {
                            this.showAddUserProfileTemplatePopup = false
                        })
            } else {
                this.$toast.open({
                    message: 'Name and Selections are required.',
                    type: 'error',
                });

                this.$refs.AddTextBoxName.instance.focus()
            }
        },

        EditUserProfileTemplate(e) {
            if (this.editUserProfileTemplate.MarketsProducts.length > 0) {
                this.$store.dispatch('loading/message', "Updating User Profile Template...")

                API.put(`/admin/users/profile/templates/${this.selectedTemplate.Name}`, this.editUserProfileTemplate)
                        .then(response => {
                            this.templates = response.data

                            this.selectedTemplate = null
                            this.UserProfileTemplatesGrid.deselectAll()
                        })
                        .catch(error => {
                            this.$toast.open({
                                message: error.response.data.message,
                                type: 'error',
                            });
                        })
                        .finally(() => {
                            this.showEditUserProfileTemplatePopup = false
                            this.editUserProfileTemplate = this.NewUserProfileTemplate()
                        })
            } else {
                this.$toast.open({
                    message: 'Selections are required.',
                    type: 'error',
                });
            }
        },

        DeleteUserTemplate(e) {
            let result = confirm("Are you sure?", `Confirm Deletion`);

            result.then((dialogResult) => {
                if (dialogResult) {
                    this.$store.dispatch('loading/message', "Deleting User Profile Template...")

                    API.delete(`/admin/users/profile/templates/${this.selectedTemplate.Name}`)
                            .then(response => {
                                this.templates = response.data

                                this.selectedTemplate = null
                                this.UserProfileTemplatesGrid.deselectAll()
                            })
                            .catch(error => {
                                this.$toast.open({
                                    message: error.response.data.message,
                                    type: 'error',
                                });
                            })
                }
            })
        },

        NewUserProfileTemplate() {
            return {
                Name: null,
                MarketsProducts: []
            }
        },

        onSelectionChanged({ selectedRowsData }) {
            const data = selectedRowsData[0];

            this.selectedTemplate = data ? data : null
        },
    },

    computed: {
        User() {
            return this.$store.getters['user/user']
        },

        Profile() {
            return this.$store.getters['user/profile']
        },

        Authenticated() {
            return this.$store.getters["user/authenticated"];
        },

        UserProfileTemplatesGrid() {
            return this.$refs.UserProfileTemplatesGridRef.instance
        }
    }
}
</script>

<style scoped>
    ::v-deep .dx-selection td{
        background-color: #5990be !important;
        color: white !important;
    }

    ::v-deep #AddUserProfileTemplatesGrid .dx-command-select {
        width: 35px;
        min-width: 35px;
    }

    ::v-deep #EditUserProfileTemplatesGrid .dx-command-select {
        width: 35px;
        min-width: 35px;
    }

    ::v-deep .dx-invalid-message > .dx-overlay-content {
        display: none !important;
    }
</style>
