<template>
    <div>
        <DxDataGrid
                id="gridContainer"
                ref="DTNSnapshotsGridRef"
                no-data-text="No DTN Snapshot Records..."
                :data-source="snapshots"
                :show-borders="true"
                :show-row-lines="true"
                :column-auto-width="true"
                :repaint-changes-only="false"
                :two-way-binding-enabled="false"
                :column-hiding-enabled="true"
                @initialized="onInitialized"
                class="p-2"
                :height="300"
        >
            <DxHeaderFilter :visible="true"/>

            <DxSorting show-sort-indexes />
            <DxPaging :page-size="15"/>
            <DxColumnFixing :enabled="true"/>
            <DxScrolling mode="infinite"/>

            <DxColumn
                    data-field="MarketName"
                    caption="Market"
                    :width="110"
                    :visible="true"
                    :sort-index="0"
                    :fixed="true"
                    sort-order="asc"
                    :header-filter="{height: 'auto', position: { my: 'center', at: 'center' } }"
            />
            <DxColumn
                    data-field="ProductName"
                    caption="Ticker"
                    :sort-index="1"
                    :fixed="true"
                    sort-order="asc"
                    :header-filter="{height: 'auto', position: { my: 'center', at: 'center'} }"
            />
            <DxColumn
                    data-field="UpdatedOn"
                    data-type="datetime"
                    format="MM/dd/yyyy hh:mm a"
                    caption="Updated"
                    :hiding-priority="0"
                    :allow-header-filtering="false"
                    :width="140"
            />
            <DxColumn
                    alignment="right"
                    data-field="Difference"
                    data-type="float"
                    format="0#.0000"
                    caption="Diff"
                    :allow-header-filtering="false"
                    :width="75"
            />
            <DxColumn
                    alignment="right"
                    data-field="Spot"
                    caption="Spot"
                    data-type="float"
                    format="0#.0000"
                    :allow-header-filtering="false"
                    :width="75"
            />
            <DxColumn
                    alignment="right"
                    data-field="Change"
                    caption="Change"
                    data-type="float"
                    format="0#.0000"
                    cell-template="changeCellTemplate"
                    :allow-header-filtering="false"
                    :width="80"
            />
            <DxColumn
                    caption="History"
                    type="buttons"
                    :fixed="false"
                    :allow-sorting="false"
                    :buttons="buttons"
                    :width="50"
            />

            <template #changeCellTemplate="{ data: cellData }">
                <PriceCell :cell-data="cellData.data"/>
            </template>
        </DxDataGrid>
    </div>
</template>

<script>
/* eslint-disable */

import {DxDataGrid, DxColumn, DxButton, DxPaging, DxHeaderFilter, DxSorting, DxColumnFixing, DxScrolling} from 'devextreme-vue/data-grid';

import {API} from "@/API";
import PriceCell from "@/components/PriceCell";
import DTNSnapshotChart from "./DTNSnapshotChart";

export default {
    name: "dtn-snapshot-grid",

    components: {
        DTNSnapshotChart,
        PriceCell,
        DxDataGrid,
        DxPaging,
        DxHeaderFilter,
        DxSorting,
        DxColumn,
        DxButton,
        DxColumnFixing,
        DxScrolling
    },

    data() {
        return {
            interval: null,
            snapshots: [],

            popupVisible: false,
            selectedSnapshot: {},

            buttons: [
                {
                    icon: 'chart',
                    visible: true,
                    onClick: this.ShowGraph.bind(this)
                }
            ],
        }
    },

    mounted() {
        this.$root.$on('user-impersonated', this.Fetch)
        this.$root.$on('user-unpersonated', this.Fetch)

        this.Fetch()
    },

    beforeDestroy() {
        this.$root.$off('user-impersonated')
        this.$root.$off('user-unpersonated')
    },

    methods: {
        Fetch() {
            if (this.Authenticated) {
                API.get('/dtn/snapshots/latest')
                        .then(response => {
                            this.snapshots = response.data
                            this.$root.$emit('dtn-snapshots-refreshed')
                        })
                        .catch(error => {
                            this.$toast.open({
                                message: error.response.data.message,
                                type: 'error',
                            });
                        })
            } else {
                this.$router.push('/login')
            }
        },

        FetchAll() {
            if (this.Authenticated) {
                API.get('/dtn/snapshots/latest/all')
                        .then(response => {
                            this.snapshots = response.data
                            this.$root.$emit('dtn-snapshots-refreshed')
                        })
                        .catch(error => {
                            this.$toast.open({
                                message: error.response.data.message,
                                type: 'error',
                            });
                        })
            } else {
                this.$router.push('/login')
            }
        },

        ShowGraph(e) {
            this.selectedSnapshot = e.row.data

            this.popupVisible = true

            this.$router.push({ name: 'dtn-chart', params: { snapshot: this.selectedSnapshot } })
        },

        onChartHidden(e) {
            this.productSnapshots = []
        },

        // Used to hide the adaptive column for grid option column-hiding-enabled='true'
        onInitialized(e) {
            e.component.columnOption('command:adaptive', 'visible', false)
        }
},

    computed: {
        User() {
            return this.$store.getters['user/user']
        },

        Authenticated() {
            return this.$store.getters["user/authenticated"];
        },

        DTNSnapshotsGrid() {
            return this.$refs.DTNSnapshotsGridRef
        }
    }
}
</script>

<style scoped>
::v-deep #gridContainer span.current-value {
    display: inline-block;
    margin-right: 5px;
}

::v-deep #gridContainer span.Diff {
    width: 50px;
    display: inline-block;
}

::v-deep #gridContainer .inc {
    color: #2ab71b;
}

::v-deep #gridContainer .dec {
    color: #f00;
}

::v-deep #gridContainer .inc .arrow,
::v-deep #gridContainer .dec .arrow {
    display: inline-block;
    height: 10px;
    width: 10px;
    background-repeat: no-repeat;
    background-size: 10px 10px;
}

::v-deep #gridContainer .inc .arrow {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAADKSURBVHjaYtTaLs1ABEiG0nPRJa56PEHhsxBhmCUQT4OyrwHxcXyKmQgYJgHE64CYDYrXQcXIMhCbAcgWkGzgNKh38QUB0QamIUUErkhKI9ZAGyCeTERkTYaqxWsgKA2txhdG6GGsvUNGGpeBRMUiGhCFGsqGzUBQQJsxkA5AemaiG5hDIBIIgQSgK0FmMDACs549kN5FZLjhA7+A2A2U9YSAOBeLAk4gnoBDczoOcSFGPIUDPxB/wCHHiKtwYGKgMhg1cBAaCBBgAJTUIL3ToPZfAAAAAElFTkSuQmCC');
}

::v-deep #gridContainer .dec .arrow {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAADJSURBVHjaYvzPgBfgkhYA4o8QFahKmBioDEYNHIQGsgBxIBCLkqgvAYi/g1mMjMjir0EJzR6If/6HpChKMMgMe3DKBeIcKhiY8x/MYoDj+RQYNgdkGLqBbEB8kgzDToL1YjEQhKWB+BUJhj0H64Eahs1AELYhMpJ+gtUiGYbLQBBOI8LANLBaIg1kAAc0vkiAqSPBQFAkHcNi2DGoHMkGgrAENOCRI0ECRQ2JBoKwJTQCfkLZDPgMZPxPXN5NhtJzMSsJVBMAAgwAyWSY2svfmrwAAAAASUVORK5CYII=');
}

::v-deep #gridContainer {
    background-color: white !important;
}

.column-max-width {
    max-width: 150px;
}
</style>
