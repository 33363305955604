<template>
    <div>
        <DxDataGrid
                id="gridContainer"
                ref="OutagesDataGridRef"
                no-data-text="No Outage Records..."
                :data-source="outages"
                :show-borders="true"
                :show-row-lines="true"
                :column-auto-width="true"
                :repaint-changes-only="false"
                :two-way-binding-enabled="false"
                :column-hiding-enabled="false"
                key-expr="id"
                @row-click="onRowClick"
                class="p-2"
                width="100%"
                :height="height"
        >
            <DxHeaderFilter :visible="true"/>

            <DxSorting show-sort-indexes />
            <DxPaging :page-size="10"/>
            <DxColumnFixing :enabled="true"/>
            <DxPager :show-info="true"/>
            <DxSelection mode="single"/>
            <DxScrolling mode="infinite"/>

            <DxColumn
                    cell-template="status-cell-template"
                    :width="35"
                    :header-filter="{height: 'auto', allowSearch: true, position: { my: 'center', at: 'center' } }"
            />
            <DxColumn
                    data-field="status"
                    caption="Status"
                    :header-filter="{height: 'auto', allowSearch: true, position: { my: 'center', at: 'center' } }"
            />
            <DxColumn
                    data-field="terminal_num"
                    caption="Terminal No."
                    :header-filter="{allowSearch: true, position: { my: 'center', at: 'center'} }"
                    :width="115"
            />
            <DxColumn
                    data-field="terminal_name"
                    caption="Terminal Name"
                    :header-filter="{width: 375, allowSearch: true, position: { my: 'center', at: 'center'} }"
            />
            <DxColumn
                    data-field="city"
                    caption="City"
                    :header-filter="{allowSearch: true, position: { my: 'center', at: 'center'} }"
            />
            <DxColumn
                    data-field="state"
                    caption="State"
                    css-class="text-center"
                    :width="75"
                    :header-filter="{allowSearch: true, position: { my: 'center', at: 'center'} }"
            />
            <DxColumn
                    data-field="return_date"
                    caption="Return Date"
                    data-type="datetime"
                    format="MM/dd/yyyy"
                    :allow-header-filtering="false"
            />
            <DxColumn
                    data-field="effective_date"
                    caption="Effective Date"
                    data-type="datetime"
                    format="MM/dd/yyyy"
                    :sort-index="0"
                    sort-order="desc"
                    :allow-header-filtering="false"
                    :width="115"
            />

            <DxColumn
                    data-field="products"
                    caption="Products"
                    :allow-header-filtering="true"
                    :calculate-display-value="CalculateProductsCellValue"
                    :calculate-cell-value="CalculateProductsCellValue"
                    :calculate-filter-expression="(filterValue, selectedFilterOperation) => { return [this.CalculateProductsCellValue, 'startswith', filterValue] }"
                    :header-filter="{height: 'auto', allowSearch: true, position: { my: 'center', at: 'center'} }"
                    :lookup="{dataSource: products}"
            />

            <DxMasterDetail :enabled="true" template="master-detail"/>
            <template #master-detail="{ data: outage }">
                <b-container fluid class="border bg-light p-2">
                    <b-row>
                        <b-col cols="6">
                            <div class="word-wrap">
                                <div class="pb-2">
                                    <h4 class="py-0 my-0"><b>Message:</b></h4>
                                    <hr class="py-0 m-0"/>
                                </div>
                                <div class="message">{{ outage.data.message }}</div>
                            </div>
                        </b-col>
                        <b-col cols="6">
                                <b-container fluid>
                                    <b-row no-gutters class="d-flex">
                                        <b-col xs="auto" sm="auto" md="auto" lg="auto" xl="auto" class="py-2">
                                            <DashboardPanel title="Affected Customers">
                                                <template #content>
                                                        <b-container fluid class="p-0">
                                                            <b-row no-gutters class="d-flex">
                                                                <b-col xs="auto" sm="auto" md="auto" lg="auto" xl="auto" class="p-0 m-auto align-middle">
                                                                    <DxDataGrid
                                                                            :data-source="outage.data.Customers"
                                                                            :show-row-lines="true"
                                                                            :column-auto-width="true"
                                                                            :repaint-changes-only="false"
                                                                            :two-way-binding-enabled="false"
                                                                            :column-hiding-enabled="false"
                                                                            no-data-text="No Affected Customers"
                                                                            width="100%"
                                                                    >
                                                                        <DxHeaderFilter :visible="true"/>
                                                                        <DxFilterRow :visible="true"/>
                                                                        <DxSorting show-sort-indexes />
                                                                        <DxPaging :page-size="10"/>
                                                                        <DxColumnFixing :enabled="true"/>
                                                                        <DxPager :show-info="true"/>
                                                                        <DxSelection mode="none"/>
                                                                        <DxScrolling mode="infinite"/>

                                                                        <DxColumn
                                                                                data-field="CustomerNo"
                                                                                caption="No."
                                                                                :width="125"
                                                                        />
                                                                        <DxColumn
                                                                                data-field="CustomerName"
                                                                                caption="Name"
                                                                                sort-order="asc"
                                                                        />
                                                                    </DxDataGrid>
                                                                </b-col>
                                                            </b-row>
                                                        </b-container>
                                                </template>
                                            </DashboardPanel>
                                        </b-col>
                                    </b-row>
                                </b-container>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <template #status-cell-template="{ data }">
                <div v-switch="data.data.status.toLowerCase()">
                    <status-indicator v-case="'normal'" status="positive" />
                    <status-indicator v-case="'limited availability'" status="intermediary" />
                    <status-indicator v-case="'outage'" status="negative" />
                    <status-indicator v-case="''" />

                    <status-indicator v-default status="active" />
                </div>
            </template>
        </DxDataGrid>
    </div>
</template>

<script>
/* eslint-disable */

import {DxDataGrid, DxColumn, DxButton, DxPaging, DxPager, DxHeaderFilter, DxFilterRow, DxSorting, DxColumnFixing, DxMasterDetail, DxSelection, DxScrolling} from 'devextreme-vue/data-grid';
import { StatusIndicator } from 'vue-status-indicator';

import {API} from "@/API";
import DashboardPanel from "@/components/DashboardPanel.vue";

export default {
    name: "outages-grid",

    components: {
        DashboardPanel,
        DxDataGrid,
        DxPaging,
        DxPager,
        DxHeaderFilter,
        DxFilterRow,
        DxSorting,
        DxColumn,
        DxButton,
        DxColumnFixing,
        DxMasterDetail,
        DxSelection,
        DxScrolling,
        StatusIndicator
    },

    props: {
        height: {
            type: Number,
            default: 300
        }
    },

    data() {
        return {
            outages: [],
            products: [],
            selectedOutage: -1
        }
    },

    mounted() {
        this.$root.$on('customer-changed', this.FetchCustomer)
        this.$root.$on('user-impersonated', this.FetchCustomer)
        this.$root.$on('user-unpersonated', this.FetchCustomer)
    },

    beforeDestroy() {
        this.$root.$off('customer-changed')
        this.$root.$off('user-impersonated')
        this.$root.$off('user-unpersonated')
    },

    methods: {
        CalculateProductsCellValue: (rowData) => { return rowData.products.join(', ') },

        Fetch() {
            if (this.Authenticated) {
                API.get(`/outages`)
                        .then(response => {
                            this.outages = response.data.Outages
                            this.products = response.data.Products
                        })
                        .catch(error => {
                            this.$toast.open(error.response.data.message)
                        })
            } else {
                this.$router.push('/login')
            }
        },

        FetchCustomer() {
            if (this.Authenticated) {
                API.get(`/outages/${this.Customer.CustomerNo}`)
                        .then(response => {
                            this.outages = response.data.Outages
                            this.products = response.data.Products
                        })
                        .catch(error => {
                            this.$toast.open(error.response.data.message)
                        })
            } else {
                this.$router.push('/login')
            }
        },

        onRowClick(e) {
            let component = e.component, prevClickTime = component.lastClickTime;

            component.lastClickTime = new Date();

            if (prevClickTime && (component.lastClickTime - prevClickTime < 300)) {
                    this.selectedOutage = e.component.getKeyByRowIndex(e.rowIndex)

                    if (!e.component.isRowExpanded(this.selectedOutage)) {
                        e.component.collapseAll(-1);
                        e.component.expandRow(this.selectedOutage);
                    } else {
                        e.component.collapseRow(this.selectedOutage);
                    }
            }
        }
    },

    computed: {
        Authenticated() {
            return this.$store.getters["user/authenticated"];
        },

        Customer() {
            return this.$store.getters['customers/customer']
        },

        OutagesDataGrid() {
            return this.$refs.OutagesDataGridRef
        }
    }
}
</script>

<style scoped>
    ::v-deep .word-wrap {
        white-space: normal;
        word-wrap: break-word;
    }

    ::v-deep .message {
        line-height: 1.15rem;
        font-size: 0.80rem;
    }

    ::v-deep #gridContainer {
        background-color: white !important;
    }
    ::v-deep #gridContainer .dx-data-row {
        line-height: 0.75rem !important;
    }
    ::v-deep .dx-datagrid-rowsview .dx-master-detail-row .dx-master-detail-cell {
        background-color: #FEFEFE !important;
    }

    ::v-deep hr {
        border: 1px dotted black;
        border-style: none none dotted;
        color: #fff;
        background-color: #fff;
    }

    ::v-deep .dx-datagrid-rowsview .dx-row > .dx-master-detail-cell {
        padding: 8px;
    }
</style>
