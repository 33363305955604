<template>
    <div>
        <b-container v-show="iterative && !loading && selectedReport" :fluid="false" class="pb-3 px-0">
            <b-row cols-sm="auto" cols-md="auto" cols-lg="auto" cols-xl="auto">
                <b-col class="d-inline-flex">
                    <div class="my-auto">Report Date: &nbsp;</div>
                    <DxDropDownBox
                            ref="SentDateTimesDropDownRef"
                            :value="selectedReport && selectedReport.ReportID"
                            :defer-rendering="false"
                            :display-expr="(item) => { return item && moment(item.SentDateTime).format('MM/DD/YYYY hh:mm A') }"
                            :data-source="reports"
                            value-expr="ReportID"
                            placeholder="Select Report..."
                            :width="200"
                    >
                        <template #content>
                            <DxDataGrid
                                    ref="SentDateTimesDataGridRef"
                                    :data-source="reports"
                                    :defer-rendering="true"
                                    :hover-state-enabled="true"
                                    :selected-row-keys="[selectedReport && selectedReport.ReportID]"
                                    :show-borders="true"
                                    :show-column-headers="false"
                                    :show-column-lines="false"
                                    :show-row-lines="true"
                                    :height="200"
                                    key-expr="ReportID"
                                    @selection-changed="FetchReport"
                                    @row-prepared="onRowPrepared"
                            >
                                <DxGroupPanel :visible="false"/>
                                <DxGrouping :auto-expand-all="false"/>
                                <DxSelection mode="single"/>
                                <DxPaging :enabled="true" :page-size="10"/>
                                <DxFilterRow :visible="false"/>
                                <DxScrolling mode="virtual"/>

                                <DxColumn data-field="SentDate"
                                          format="MM/dd/yyyy"
                                          group-cell-template="groupCellTemplate"
                                          :visible="false"
                                          :group-index="0"
                                          sort-order="desc"

                                />

                                <DxColumn data-field="SentTime"
                                          :calculate-display-value="CalculateSentTimeDisplayValue"
                                />

                                <template #groupCellTemplate="{ data }">
                                    <div>{{ moment(data.value).format('MM/DD/yyyy')}}</div>
                                </template>
                            </DxDataGrid>
                        </template>
                    </DxDropDownBox>
                </b-col>
            </b-row>
        </b-container>

        <b-container v-if="selectedReport" :fluid="false" class="border border-dark rounded-sm py-3 shadow shadow-sm">
            <b-row cols-sm="auto" cols-md="auto" cols-lg="auto" cols-xl="auto">
                <b-col class="pb-0">
                    <h3><b class="text-uppercase">Welcome: </b> {{ Customer.CustomerName }}</h3>
                    <hr class="border-dark"/>
                </b-col>
            </b-row>
            <b-row cols-sm="auto" cols-md="auto" cols-lg="auto" cols-xl="auto">
                <b-col>
                    <div class="text-center">
                        <h3 class="font-weight-bold text-capitalize my-auto">Prices for: {{ moment(selectedReport.SentDateTime).format('MM/DD/YYYY hh:mm A') }}</h3>
                    </div>
                </b-col>
            </b-row>
            <b-row cols-sm="auto" cols-md="auto" cols-lg="auto" cols-xl="auto" class="my-1"></b-row>
            <b-row cols-sm="auto" cols-md="auto" cols-lg="auto" cols-xl="auto" class="p-0">
                <b-col>
                    <b-table-simple
                            :outlined="true"
                            :responsive="true"
                            :bordered="true"
                            :small="true"
                            class="w-auto border-dark shadow-sm rounded-sm m-0 p-0"
                    >
                        <b-thead head-variant="dark">
                            <b-tr>
                                <b-th class="border-dark text-nowrap text-uppercase">Term. Location</b-th>
                                <b-th class="border-dark text-nowrap text-uppercase">Product</b-th>
                                <b-th class="border-dark text-nowrap text-uppercase text-center">Rank</b-th>
                                <b-th class="border-dark text-nowrap text-uppercase">Supplier</b-th>
                                <b-th class="border-dark text-nowrap text-uppercase">Terminal</b-th>
                                <b-th class="border-dark d-none d-md-table-cell text-nowrap text-uppercase text-center">
                                    Effective Time
                                </b-th>
                                <b-th v-if="(HasCustomerBuyerPermissions || HasCustomerBuyerRole || HasCustomerAdministratorRole)"
                                      class="border-dark text-nowrap text-uppercase text-right">Price
                                </b-th>
                                <b-th v-if="(HasCustomerDriverPermissions || HasCustomerDriverRole || HasCustomerAdministratorRole)"
                                      class="border-dark text-nowrap text-uppercase text-center">Load#
                                </b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <tr v-for="price in normalizedPrices" :key="price.ID">
                                <td v-if="price.StartLocation" class="border-dark text-nowrap text-left font-weight-bold"
                                    :rowspan="price.locationRowSpan">{{ price.DisplayLocation }}
                                </td>
                                <td v-if="price.StartProduct" class="border-dark text-nowrap text-left font-weight-bold" :style="{'color': price.Product.ColorHex ? 'white' : 'black', 'background-color': price.Product.ColorHex }"
                                    :rowspan="price.productRowSpan">{{ price.ItemCodeDesc }}
                                </td>
                                <td class="border-dark text-nowrap text-center">{{ price.Rank }}</td>
                                <td class="border-dark text-nowrap text-left">{{ price.SupplierName }}</td>
                                <td class="border-dark text-nowrap text-left">{{ price.Terminal.Name }}</td>
                                <td class="border-dark d-none d-md-table-cell text-nowrap text-center">
                                    {{ moment(price.EffectiveDateTime).format('MM/DD/YYYY hh:mm A') }}
                                </td>
                                <td v-if="(HasCustomerBuyerPermissions || HasCustomerBuyerRole || HasCustomerAdministratorRole)" class="border-dark text-nowrap text-right">
                                    {{ numeral(price.SellingPrice).format('$ 0,0.0000') }}
                                </td>
                                <td v-if="(HasCustomerDriverPermissions || HasCustomerDriverRole || HasCustomerAdministratorRole)" class="border-dark text-nowrap text-center">
                                    {{ price.LoadNumber }}
                                </td>
                            </tr>
                        </b-tbody>

                    </b-table-simple>
                </b-col>
            </b-row>
        </b-container>

        <b-container>
            <b-row v-show="loading" cols-sm="auto" cols-md="auto" cols-lg="auto" cols-xl="auto" class="p-0">
                <b-col>
                    <div class="d-flex justify-content-center mb-3">
                        <b-spinner/>
                    </div>
                    <h4 class="text-uppercase text-center text-success pt-3">Rendering Report...</h4>
                </b-col>
            </b-row>

            <b-row cols-sm="auto" cols-md="auto" cols-lg="auto" cols-xl="auto" class="p-0">
                <b-col>
                    <h4 v-if="!loading && !selectedReport" class="text-uppercase text-center text-danger pt-3">No Prices Found for Customer: {{ Customer.CustomerName }}</h4>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>

import {API} from "@/API";
import {_} from 'vue-underscore';
import moment from "moment";
import DxDropDownBox from 'devextreme-vue/drop-down-box';
import { DxDataGrid, DxSelection, DxPaging, DxFilterRow, DxScrolling, DxColumn, DxGroupPanel, DxGrouping } from 'devextreme-vue/data-grid';

import permissions from "@/permissions";

export default {
    name: "customer-pricing-table",
    props: {
        iterative: {
            type: Boolean,
            default: false
        }
    },
    components: {
        DxDropDownBox,
        DxDataGrid,
        DxSelection,
        DxPaging,
        DxFilterRow,
        DxScrolling,
        DxColumn,
        DxGroupPanel,
        DxGrouping
    },

    data() {
        return {
            permissions,
            loading: false,
            reports: [],
            selectedReport: null,
        }
    },

    async mounted() {
        this.$root.$on('user-impersonated', this.FetchPrices)
        this.$root.$on('user-unpersonated', this.FetchPrices)

        if(this.iterative)
            await this.FetchReports()
        else
            await this.FetchPrices()

        this.$root.$on('customer-changed', async (customer) => {
            if(this.iterative)
                await this.FetchReports()
            else
                await this.FetchPrices()
        })

        this.$root.$on('bid-template-changed', async (bidtemplate) => {
            if(this.iterative)
                await this.FetchReports()
            else
                await this.FetchPrices()
        })
    },

    beforeRouteEnter(to, from, next) {
        next(vm => {
            let roles = to.meta.requiredRoles.join("|")
            let permissions = to.meta.requiredPermissions.join("|")

            if (vm.$gates.hasAnyRole(roles) || vm.$gates.hasAnyPermission(permissions))
                next()
            else
                next('/home')
        })
    },

    beforeDestroy() {
        this.$root.$off('user-impersonated')
        this.$root.$off('user-unpersonated')

        this.$root.$off('customer-changed')
        this.$root.$off('bid-template-changed')
    },

    methods: {
        async FetchPrices() {
            if (this.Authenticated) {
                this.loading = true

                const bidtemplate = this.$store.state.bidtemplates.bidtemplate

                if(bidtemplate)
                    API.get(`/customers/reports/pricing/current/${bidtemplate.CustomerNo}/${bidtemplate.TemplateID}`)
                            .then(response => {
                                this.selectedReport = response.data
                            })
                            .finally(() => {
                                this.loading = false
                            })
                else {
                    this.loading = false
                    this.reports = []
                    this.selectedReport = null
                }
            } else {
                this.loading = false
                await this.$router.push('/login')
            }
        },

        async FetchReports() {
            if (this.Authenticated) {
                this.loading = true

                const bidtemplate = this.$store.state.bidtemplates.bidtemplate

                if(bidtemplate)
                    API.get(`/customers/reports/pricing/history/${bidtemplate.CustomerNo}/${bidtemplate.TemplateID}`)
                            .then(response => {
                                this.reports = response.data

                                if(this.reports) this.selectedReport = this.reports[0]
                            })
                            .finally(() => {
                                this.loading = false
                            })
                else {
                    this.loading = false
                    this.reports = []
                    this.selectedReport = null
                }
            } else {
                this.loading = false
                await this.$router.push('/login')
            }
        },

        async FetchReport({selectedRowKeys}) {
            let key = selectedRowKeys[0]

            if(!key) return

            if (this.Authenticated) {
                this.SentDateTimesDropDown.close()

                API.get(`/customers/reports/${key}`)
                        .then(response => {
                            this.selectedReport = response.data
                        })
            } else {
                await this.$router.push('/login')
            }
        },

        CalculateSentTimeDisplayValue(item) {
            return `Report for ${moment(item.SentDateTime).format('hh:mm A')}`
        },

        onRowPrepared(e) {
            if (e.rowType === "data") {
                if (!e.data.FromMidday) {
                    e.rowElement.style.backgroundColor = "yellow";
                }
            }
        }
    },

    computed: {
        SentDateTimesDropDown() {
            return this.$refs.SentDateTimesDropDownRef.instance
        },

        SentDateTimesDataGrid() {
            return this.$refs.SentDateTimesDataGridRef.instance
        },

        User() {
            return this.$store.getters['user/user']
        },

        Roles() {
            return this.$store.getters["user/roles"]
        },

        Authenticated() {
            return this.$store.getters["user/authenticated"];
        },

        normalizedPrices() {
            if (!this.selectedReport.Prices) return [];

            let NormalizedPrices = [];
            let currentLocationGroupRef = null; // pointer to product obj
            let currentLocationGroup = null;

            let currProductRef = null; // pointer to product obj
            let currProduct = null;

            for (let price of this.selectedReport.Prices) {
                let PriceCopy = {};
                // make copy of data structure
                Object.assign(PriceCopy, price);

                // start of new Location gets StartLocation flag set
                if (PriceCopy.LocationGroup !== currentLocationGroup) {
                    PriceCopy.StartLocation = true;
                    currentLocationGroup = PriceCopy.LocationGroup;
                    currentLocationGroupRef = PriceCopy;
                    currentLocationGroupRef.locationRowSpan = 1;

                    currProduct = null; // reset product when loc. changes
                } else {
                    PriceCopy.StartLocation = false;
                    currentLocationGroupRef.locationRowSpan += 1;
                }

                // start of new Product gets StartProduct flag set
                if (PriceCopy.ItemCodeDesc !== currProduct) {
                    PriceCopy.StartProduct = true;
                    PriceCopy.productRowSpan = 1;
                    currProduct = PriceCopy.ItemCodeDesc;
                    currProductRef = PriceCopy;
                    currProductRef.productRowSpan = 1;
                } else {
                    PriceCopy.StartProduct = false;
                    currProductRef.productRowSpan += 1;
                }

                NormalizedPrices.push(PriceCopy);
            }

            return NormalizedPrices;
        },

        PriceCount() {
            return this.normalizedPrices.length
        },

        Customer() {
            return this.$store.getters['customers/customer']
        },

        BidTemplate() {
            return this.$store.getters['bidtemplates/bidtemplate']
        },

        Impersonating() {
            return this.$store.getters['impersonate/impersonating'];
        },

        ImpersonatedUser() {
            return this.$store.getters['impersonate/user'];
        },

        HasCustomerBuyerRole() {
            return this.$gates.hasRole('Customer Buyer')
        },

        HasCustomerBuyerPermissions() {
            return this.$gates.hasPermission(this.permissions.pricing.SHOW_SELLING_PRICE)
        },

        HasCustomerDriverRole() {
            return this.$gates.hasRole('Customer Driver')
        },

        HasCustomerAdministratorRole() {
            return this.$gates.hasRole('Customer Administrator')
        },

        HasCustomerDriverPermissions() {
            return this.$gates.hasPermission(this.permissions.pricing.SHOW_LOAD_NUMBERS)
        },
    }
}
</script>

<style scoped lang="scss">
@import "../../fm-styles";

a {
    text-decoration: none;
    display: inline-block;
    padding: 8px 16px;
}

::v-deep a:hover {
    background-color: $FuelMastersBlue;
    color: white;
}

::v-deep .previous {
    background-color: $FuelMastersBlue;
    color: white;
}

::v-deep .next {
    background-color: $FuelMastersBlue;
    color: white;
}

::v-deep .table {
    color: black;
    background-color: #F8F8F8;
}

::v-deep .navbutton {
    width: 120px !important;
}

::v-deep .table-sm th, .table-sm td {
    padding-top: 0;
    padding-bottom: 0;
}

@media (max-width: 480px) {

}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 575px) {
    ::v-deep .table-sm, .table-sm th {
        font-size: calc(0.5rem + 0.15vw + 0.15vh);
    }

    ::v-deep .table-sm, .table-sm td {
        font-size: calc(0.4rem + 0.095vw + 0.095vh);
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    ::v-deep .table-sm, .table-sm th {
        font-size: calc(0.5rem + 0.15vw + 0.15vh);
    }

    ::v-deep .table-sm, .table-sm td {
        font-size: calc(0.4rem + 0.095vw + 0.095vh);
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    ::v-deep .table-sm, .table-sm th {
        font-size: calc(0.25rem + 0.25vw + 0.25vh);
    }

    ::v-deep .table-sm, .table-sm td {
        font-size: calc(0.25rem + 0.25vw + 0.25vh);
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    ::v-deep .table-sm, .table-sm th {
        font-size: calc(0.25rem + 0.25vw + 0.25vh);
    }

    ::v-deep .table-sm, .table-sm td {
        font-size: calc(0.25rem + 0.25vw + 0.25vh);
    }
}
</style>
